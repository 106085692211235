import { ISector } from '@avid/common';

import { clientFirebase } from '../firebase';
import { ISectorCsv } from 'typings/constants-uploading.typing';

import { VersionCsvUploader } from './version-csv-uploader';

export class SectorsCsvUploader extends VersionCsvUploader {
  parseRows(parsedRows: ISectorCsv[]): ISector[] {
    return parsedRows.map((row) => {
      const valueLabel = row.label === '' ? { value: row.value } : row;
      const isFull = row.isFull === 'true';
      const isNoWork = row.isNoWork === 'true' ? true : undefined;

      return { ...valueLabel, isFull, isNoWork };
    });
  }

  upload(sectors: ISector[]) {
    return clientFirebase.firestore.versions.updateVersion(this.version, {
      sectors,
    });
  }
}
