import { useState } from 'react';

import { logIn } from 'services/firebase.api';

import { ROUTES } from 'constants/routes';
import { useHistory } from 'react-router-dom';

interface ILogInState {
  password: string;
  email: string;
  isError: boolean;
}

const INITIAL_STATE: ILogInState = {
  password: '',
  email: '',
  isError: false,
};

export const usePasswordLogin = () => {
  const [state, setState] = useState(INITIAL_STATE);

  const { push } = useHistory();

  const userLogIn = async () => {
    try {
      await logIn(state.email, state.password);

      push(ROUTES.START);
    } catch (error) {
      setState((prev) => ({ ...prev, isError: true }));
      console.error(error);
    }
  };

  const setEmailPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    if (e.target.type === 'password') {
      return setState((prev) => ({
        ...prev,
        password: e.target.value,
      }));
    }

    setState((prev) => ({ ...prev, email: e.target.value }));
  };

  return {
    ...state,
    userLogIn,
    setEmailPassword,
  };
};
