import { ISkillInfo, entriesObject } from '@avid/common';

import { clientFirebase } from '../firebase';
import { ISectorSkillCsv } from 'typings/constants-uploading.typing';

import { VersionCsvUploader } from './version-csv-uploader';

export class SectorsSkillsCsvUploader extends VersionCsvUploader {
  async beforeParseRows() {
    const allSkillsData = await clientFirebase.firestore.versions.getAllSkills(
      this.version
    );

    return allSkillsData?.skills;
  }

  parseRows(
    parsedRows: ISectorSkillCsv[],
    allSkills: ISkillInfo[]
  ): Record<string, ISkillInfo[]> {
    const sectorSkills = parsedRows.reduce(
      (acc, row) => {
        const { sector, name } = row;

        if (!acc[sector]) {
          acc[sector] = [];
        }

        const skillInfo = allSkills.find((skill) => skill.name === name);

        if (skillInfo) {
          acc[sector].push(skillInfo);
        }

        return acc;
      },
      {} as Record<string, ISkillInfo[]>
    );

    return sectorSkills;
  }

  upload(sectorsSkills: Record<string, ISkillInfo[]>) {
    return Promise.all(
      entriesObject(sectorsSkills).map(([sector, skills]) => {
        return clientFirebase.firestore.versions.sectors.setSkills(
          this.version,
          sector,
          { skills }
        );
      })
    );
  }
}
