import { memo, useEffect, useMemo, useState } from 'react';
import { IRound, TRoundStatus } from '@avid/common';

import { InactiveLink, RoundMemo } from 'screens/active-game/components';

import { ScaleStyles } from './progress-bar.styles';

interface IGameProgressBarCompnentProps {
  roundInfos: [TRoundStatus, number | null][];
  roundsNumber: number;
}

interface IProps {
  activeRounds: IRound[];
  roundsNumber: number;
}

const GameProgressBarComponent = memo(
  (props: IGameProgressBarCompnentProps) => {
    const { roundInfos, roundsNumber } = props;

    const [linksCount, setLinksCount] = useState(0);

    const setLinks = () => {
      const activeLinks = roundInfos.length + 1;

      const activeLinksNotEqual =
        activeLinks % 6 > 0 && (Math.floor(activeLinks / 6) + 1) * 6;
      const activeLinksEqual = Math.floor(activeLinks / 6) * 6;
      const nextChainsQuantity = activeLinksNotEqual || activeLinksEqual; // set new links equal to 6

      if (activeLinks >= linksCount && nextChainsQuantity <= roundsNumber) {
        setLinksCount(nextChainsQuantity);
      } else if (
        roundInfos.length >= linksCount &&
        nextChainsQuantity >= roundsNumber
      ) {
        setLinksCount(+roundsNumber);
      }
    };

    useEffect(setLinks, [roundInfos, linksCount]);

    const linksArray = Array.apply(0, Array(linksCount));

    return (
      <ScaleStyles.Container>
        {linksArray.map((_round: unknown, num: number) => {
          return roundInfos[num] ? (
            <RoundMemo
              key={num}
              round={num + 1}
              roundStatus={roundInfos[num][0]}
              roundDuration={roundInfos[num][1]}
            />
          ) : (
            <InactiveLink
              key={num}
              index={num}
              lastRoundStatus={roundInfos[roundInfos.length - 1]?.[0]}
              activeRounds={roundInfos.length}
              lastRoundIndex={roundInfos.length}
            />
          );
        })}
      </ScaleStyles.Container>
    );
  }
);

export const GameProgressBar = (props: IProps) => {
  const { activeRounds, roundsNumber } = props;

  const lastRoundStatus = activeRounds[activeRounds.length - 1]?.status;

  const roundInfos = useMemo(
    () =>
      activeRounds.map((round) => [round.status, round.duration]) as [
        TRoundStatus,
        number,
      ][],
    [activeRounds.length, lastRoundStatus]
  );

  return (
    <GameProgressBarComponent
      roundsNumber={roundsNumber}
      roundInfos={roundInfos}
    />
  );
};
