import { ROUTES } from 'constants/routes';
import { INavigationItem } from 'typings/navigation.typing';

import { NavigationLabel } from './navigation-label';

const HOME_ITEM = {
  label: 'Home',
  to: ROUTES.START,
} as const satisfies INavigationItem;

export const HomeLink = () => <NavigationLabel {...HOME_ITEM} />;
