
import { ITrainerLimits } from '@avid/common';

import { formatDuration } from 'services/utils';

import { LimitsListStyles as Styles } from './limits-list.styles';

interface IItemProps {
  label: string;
  value: string | number;
  isExceed: boolean;
}

interface IListProp {
  limits: ITrainerLimits;
}

const LimitItem = (props: IItemProps) => {
  const { label, value, isExceed } = props;

  return (
    <Styles.Item>
      <dt>{label}</dt>
      <Styles.Value isExceed={isExceed}>{value}</Styles.Value>
    </Styles.Item>
  );
};

export const LimitsList = (props: IListProp) => {
  const { limits } = props;

  const {
    expirationDate,
    gameDuration,
    gamesUsed,
    numberOfGames,
    playersPerGame,
  } = limits;

  const totalGames =
    numberOfGames === 'unlimited' ? 'Unlimited' : numberOfGames;
  const gamesLeft =
    numberOfGames === 'unlimited' ? 'Unlimited' : +numberOfGames - +gamesUsed;
  const gameDurationFormat =
    gameDuration === 'unlimited' ? 'Unlimited' : formatDuration(+gameDuration);
  const maxPlayers =
    playersPerGame === 'unlimited' ? 'Unlimited' : playersPerGame;

  const expirationDateObj = new Date(expirationDate);

  const displayDateObj = new Date(expirationDate);
  displayDateObj.setDate(expirationDateObj.getDate() + 1);

  const displayExpirationDate =
    expirationDate === 'unlimited'
      ? 'Unlimited'
      : expirationDateObj.toLocaleDateString();
  const isExceedExpirationDate =
    expirationDate === 'unlimited' ? false : displayDateObj < new Date();

  return (
    <Styles.Root>
      <LimitItem
        label="Total No. of Games:"
        value={totalGames}
        isExceed={+numberOfGames <= 0}
      />
      <LimitItem
        label="Max. No. of Players per Game:"
        value={maxPlayers}
        isExceed={+playersPerGame <= 0}
      />
      <LimitItem
        label="No. of Games Used:"
        value={gamesUsed}
        isExceed={false}
      />
      <LimitItem
        label="Max. Duration per Game:"
        value={gameDurationFormat}
        isExceed={+gameDuration <= 0}
      />
      <LimitItem
        label="No. of Games Remaining:"
        value={gamesLeft}
        isExceed={!isNaN(+gamesLeft) && +gamesLeft <= 0}
      />
      <LimitItem
        label="Subscription Expiration:"
        value={displayExpirationDate}
        isExceed={isExceedExpirationDate}
      />
    </Styles.Root>
  );
};
