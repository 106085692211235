import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Span } from 'components/typography';
import { moveToRoute } from 'services/history';
import { COLORS } from 'theme';
import { INavigationItem } from 'typings/navigation.typing';

const Styles = {
  Link: styled(Span)`
    text-decoration: none;
    color: ${COLORS.blackGray};
    cursor: pointer;
  `,
};

export const NavigationLabel = (props: INavigationItem) => {
  const { label, to } = props;

  const location = useLocation();

  const onClick = useCallback(() => {
    if (location.pathname !== to) {
      moveToRoute(to);
    }
  }, [location.pathname, to]);

  return <Styles.Link onClick={onClick}>{label}</Styles.Link>;
};
