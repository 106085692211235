import styled from 'styled-components';

import { COLORS } from 'theme';

const Styled = styled.input`
  display: inline-block;
  margin: 0;
  padding: 8px 16px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  border: none;
  background-color: ${COLORS.white};
  font: inherit;
`;

export const Input = (props: React.InputHTMLAttributes<HTMLInputElement>) => (
  <Styled {...props} />
);
