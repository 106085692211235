import { ICompanyValue } from '@avid/common';

import { clientFirebase } from '../firebase';
import { ICompanyValueCsv } from 'typings/constants-uploading.typing';

import { VersionCsvUploader } from './version-csv-uploader';

export class CompanyValuesCsvUploader extends VersionCsvUploader {
  parseRows(csvRows: ICompanyValueCsv[]) {
    return csvRows.reduce(
      (companyValuesObject, row) => {
        companyValuesObject[row.name] = {
          positive: row.positive,
          negative: row.negative,
        };

        return companyValuesObject;
      },
      {} as Record<string, ICompanyValue>
    );
  }

  upload(companyValues: Record<string, ICompanyValue>) {
    return clientFirebase.firestore.versions.setCompanyValues(this.version, {
      companyValues,
    });
  }
}
