export const NEW_GAME_STRINGS = {
  title: 'Creating New Game',
  titleGame: 'Game Title  (shown in the player report email)',
  code: 'Game code',
  descriptionGame: 'Description',
  descriptionNotification: 'Game Title and Description will not be made public',
  numberRounds: 'Total Number of Rounds',
  energyGame: 'Energy per Round',
  durationRound: 'Duration per Round (dd:hh:mm)',
  gameAccount: 'Starting Bank Account',
  buttonSave: 'Save',
  errorMessage: 'wrong field value',
  errorVersion: 'select game version',
  errorTime: 'value should match to mm:ss format and filled',
  errorMinMoney: 'Max Loan Amount should be lower than -$1000',
  MINIMAL_MONEY: 'Max Loan Amount',
} as const;
