import { IGame } from '@avid/common';

import { ROUTES } from 'constants/routes';

const isFinished = (game: IGame): boolean => {
  const { config, rounds } = game;
  const { roundsNumber } = config;

  if (!rounds) {
    return false;
  }

  return !!+roundsNumber && rounds[+roundsNumber - 1]?.status === 'ended';
};

export const checkActiveGame = (game: IGame) => {
  const { status } = game;

  if (isFinished(game)) {
    return false;
  }

  return status && status !== 'finished';
};

export const getShareRoute = (encryptedCode: string, path: string) =>
  `${ROUTES.SHARE}${path}?k=${encryptedCode}`;

export const sortGamesByDate = (games: IGame[]) =>
  [...games].sort((first, second) => second.config.date - first.config.date);

export const sortGamesByDateAndStatus = (games: IGame[]) =>
  [...games].sort((first, second) => {
    if (first.status !== 'finished' && second.status === 'finished') {
      return -1;
    }

    return second.config.date - first.config.date;
  });
