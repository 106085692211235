import { RouteComponentProps, StaticContext } from 'react-router';
import styled from 'styled-components';

import { TabLayout } from 'components/tab-layout';
import { Table } from 'components/table';
import { PlayersListStyles } from 'components/players-list-components';
import { RefreshButton } from 'components/refresh-button';
import { StatisticMenu } from 'components/statistic-menu';
import { STRINGS } from 'constants/string';
import { TABLES_TYPES } from 'constants/table';
import { Theme } from 'theme';

import { usePlayersList } from './players-list.state';

const { gameStatistics } = STRINGS.ACTIVE_GAME;
const { title } = STRINGS.PLAYERS_LIST;

type TProps = RouteComponentProps<null, StaticContext, string>;

const Styles = {
  NoGames: styled(Theme.Title)`
    margin-top: 128px;
    width: 100%;
    text-align: center;
  `,
};

export const PlayersList = (props: TProps) => {
  const {
    activeGame,
    isLoading,
    players,
    isGamesExist,

    watchStatistic,
    onSelectGame,
    onRefresh,
  } = usePlayersList(props.location.state);

  const RenderPlayers = isGamesExist ? (
    activeGame && (
      <Table
        isLoading={isLoading}
        tableData={players}
        isLimitedList={true}
        maxWidth="100%"
        type={TABLES_TYPES.players}
      />
    )
  ) : (
    <Styles.NoGames>You have no games yet</Styles.NoGames>
  );

  return (
    <TabLayout>
      <PlayersListStyles.Block>
        <PlayersListStyles.Title>{title}</PlayersListStyles.Title>

        <Theme.ButtonMedium
          alignSelf="center"
          text={gameStatistics}
          onClick={watchStatistic}
          isLightTheme
        />
      </PlayersListStyles.Block>

      <PlayersListStyles.Block>
        <StatisticMenu
          currentGameCode={activeGame}
          selectGame={onSelectGame}
          hideRounds={true}
        />

        {RenderPlayers}
      </PlayersListStyles.Block>

      {activeGame && <RefreshButton onRefresh={onRefresh} />}
    </TabLayout>
  );
};
