import { useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { keyofObject } from 'services/object';

import { NavigationLink } from './navigation-link';
import { GameLink } from './game-link';

const ROUTE_LABELS_MAP: Record<string, string> = {
  [ROUTES.STATISTIC]: 'Statistics',
  [ROUTES.PLAYERS]: 'Players',
  [ROUTES.PLAYERS_HISTORY]: 'Players History',
  [ROUTES.NEW_GAME]: 'New Game',
};

const getRouteLabel = (route: string) => {
  const exactMatch = ROUTE_LABELS_MAP[route];

  if (exactMatch) {
    return exactMatch;
  }

  const key = keyofObject(ROUTE_LABELS_MAP).find((path) =>
    route.startsWith(path)
  );

  return key ? ROUTE_LABELS_MAP[key] : null;
};

export const CurrentLink = () => {
  const location = useLocation();

  const currentLabel = getRouteLabel(location.pathname);

  if (!currentLabel) {
    return null;
  }

  const RenderActiveGame =
    typeof location.state === 'string' ? (
      <GameLink gameCode={location.state} />
    ) : null;

  return (
    <>
      {RenderActiveGame}

      <NavigationLink to={location.pathname} label={currentLabel} />
    </>
  );
};
