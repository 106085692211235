import { FC, ReactNode } from 'react';

import { TextAreaStyle } from './text-area.style';

interface ITextInput {
  title?: string;
  errorMessage?: string;
  placeholder?: string;
  children?: ReactNode;
  className?: string;
  height?: string;
  notification?: string;
  name?: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea: FC<ITextInput> = (props) => {
  const {
    title,
    errorMessage,
    placeholder,
    className,
    onChange,
    notification,
    name,
    value,
  } = props;

  return (
    <TextAreaStyle.Label className={className}>
      {title && <TextAreaStyle.Title>{title}</TextAreaStyle.Title>}
      <TextAreaStyle.Input
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {errorMessage && (
        <TextAreaStyle.ErrorMessage>{errorMessage}</TextAreaStyle.ErrorMessage>
      )}
      {notification && (
        <TextAreaStyle.Notification>{notification}</TextAreaStyle.Notification>
      )}
    </TextAreaStyle.Label>
  );
};
