import { IEducationConstants } from '@avid/common';

import {
  ICsvUploader,
  IConstantsValueCsv,
} from 'typings/constants-uploading.typing';

import { clientFirebase } from '../firebase';

export class EducationConstantsCsvUploader implements ICsvUploader {
  parseRows(csvRows: IConstantsValueCsv[]) {
    return csvRows.reduce((acc, current) => {
      acc[current.name as keyof IEducationConstants] = +current.value;

      return acc;
    }, {} as IEducationConstants);
  }

  upload(educationConstants: IEducationConstants) {
    return clientFirebase.firestore.constants.updateEducationConstants(
      educationConstants
    );
  }
}
