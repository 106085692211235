import styled from 'styled-components';

import { Icon } from 'components/icons';

import { COLORS, FONTS, Theme } from 'theme';

interface IBoxText {
  color?: string;
  fontSize?: string;
}

interface ITableBox {
  width?: string;
  height?: string;
  isLimitedList?: boolean;
  maxWidth?: string;
}

const { Title, SubTitle } = Theme;

export const TableStyle = {
  Title: styled(Title)`
    margin-top: 32px;
    margin-bottom: 32px;
    flex: 1;
  `,
  EmptyTitle: styled(SubTitle)`
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 20px;
    color: ${COLORS.gray};
  `,
  BoxText: styled.p<IBoxText>`
    font-family: ${FONTS.quicksandBold};
    font-size: ${(props) => props.fontSize || '18px'};
    color: ${(props) => props.color};
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 15px;
  `,
  TableBox: styled.div<ITableBox>`
    display: flex;
    flex-direction: column;
    padding: 35px;
    width: ${({ width, maxWidth }) => maxWidth || width || '350px'};
    height: ${({ height, isLimitedList }) =>
      isLimitedList ? '557px' : height};
    background: ${COLORS.blue_gray};
    border: 1px solid ${COLORS.light_gray};
    box-sizing: border-box;
    border-radius: 20px;
  `,
  ScrollWrapper: styled.div`
    overflow: hidden;
    overflow-y: auto;
  `,
  Table: styled.table`
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    tr:nth-child(even) {
      background-color: ${COLORS.table_gray};
    }
  `,
  TableRow: styled.tr`
    &:first-of-type {
      font-family: ${FONTS.bold};
      font-weight: bold;
      border-bottom: 2px solid black;
    }
    height: 42px;
  `,
  TableHead: styled.td`
    padding-left: 17px;
    text-align: left;
    font-family: ${FONTS.regular};
    > * + * {
      padding-left: 4px;
    }
  `,
  TableItem: styled.td<{ isBold?: boolean }>`
    padding-left: 17px;
    text-align: left;
    font-family: ${FONTS.regular};
    overflow: hidden;
    text-overflow: ellipsis;

    ${({ isBold }) => isBold && `font-family: ${FONTS.semiBold}}`}
  `,
  Flex: styled.div`
    display: flex;
    align-items: center;
  `,
  HeadText: styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  UserIcon: styled(Icon)`
    padding-right: 8px;
    box-sizing: revert;
  `,
};
