import { useCallback, useEffect } from 'react';

import { useReduxSelector } from 'services/hooks/redux';
import { useUpdateState } from 'services/hooks';
import {
  playersSnapshotToTableItems,
  PLAYERS_TABLE_TITLE,
} from 'services/utils';
import { history } from 'services/history';
import { ROUTES } from 'constants/routes';
import { ISelectData } from 'screens/statistics/statistics.types';
import { getGameDocuments } from 'services/firebase.api';

interface IState {
  players: IPlayersListItem[];
  isLoading: boolean;
  activeGame?: string;
}

const INIT_STATE: IState = {
  players: [],
  isLoading: true,
};

const getPlayersData = (gameCode: string) =>
  getGameDocuments(gameCode, 'users');

export const usePlayersList = (gameCode?: string) => {
  const { state, updateState } = useUpdateState({
    ...INIT_STATE,
    activeGame: gameCode,
  });

  const games = useReduxSelector((redux) => redux.main.games);

  const changeGame = useCallback(
    async (code: string) => {
      updateState({ isLoading: true, activeGame: code });

      try {
        const playersSnapshot = await getPlayersData(code);

        const playersList = playersSnapshotToTableItems(playersSnapshot);

        updateState({ players: playersList, isLoading: false });
      } catch (error) {
        updateState({ isLoading: false });
        console.error(error);
      }
    },
    [updateState]
  );

  const onSelectGame = (game: ISelectData) => changeGame(game.value);

  const onRefresh = () => {
    if (state.activeGame) {
      return changeGame(state.activeGame);
    }
  };

  const watchStatistic = () => history.push(ROUTES.STATISTIC);

  useEffect(() => {
    if (!gameCode) {
      return;
    }

    changeGame(gameCode);
  }, [changeGame, games]);

  return {
    ...state,
    isGamesExist: games.length > 0,
    players: [PLAYERS_TABLE_TITLE, ...state.players],
    watchStatistic,
    onSelectGame,
    onRefresh,
  };
};
