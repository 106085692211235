import { HTMLAttributes } from 'react';

import { Icon, TIconNames } from '../icons';
import { Grid } from './grid';
import styled, { css } from 'styled-components';
import { COLORS } from 'theme';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  icon: TIconNames;
  size?: string;
}

const Styles = {
  Root: styled(Grid)<{ size?: string }>`
    ${({ size = '32px' }) => css`
      width: ${size};
      height: ${size};
    `}
    align-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
    background-color: ${COLORS.white};
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  `,
};

export const IconButton = (props: IProps) => {
  const { icon, size, ...other } = props;

  return (
    <Styles.Root size={size} {...other}>
      <Icon type={icon} />
    </Styles.Root>
  );
};
