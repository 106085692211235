import { LogoWrapper } from 'components/logo-wrapper';


import { useAuth } from './auth.state';
import { AuthStyles as Styles } from './auth.styles';

export const Auth = () => {
  const { isError } = useAuth();

  const Render = isError ? (
    <Styles.ErrorMessage>
      Something went wrong. Pls try again
    </Styles.ErrorMessage>
  ) : (
    <Styles.Label color="white">Signing In...</Styles.Label>
  );

  return <LogoWrapper>{Render}</LogoWrapper>;
};
