import { memo } from 'react';
import styled from 'styled-components';

import { Button } from 'components/button';
import { TWorkLimitParam } from 'typings/sectors-settings';

interface IProps {
  isDisabledLimits: boolean;
  isDisabledSalaries: boolean;
  onApplyItems: (param: TWorkLimitParam) => void;
}

const Styles = {
  Root: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3px;
  `,

  Cell: styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 8px;
  `,

  Button: styled(Button)`
    width: 120px;
    height: 25px;
  `,
};

export const SectorsSettingsApply = memo((props: IProps) => {
  const { isDisabledLimits, isDisabledSalaries, onApplyItems } = props;

  const onApplyLimits = () => onApplyItems('limit');

  const onApplySalaries = () => onApplyItems('salary');

  return (
    <Styles.Root>
      <div />
      <div />

      <Styles.Cell onClick={onApplyLimits}>
        <Styles.Button isDisabled={isDisabledLimits}>Apply</Styles.Button>
      </Styles.Cell>

      <Styles.Cell onClick={onApplySalaries}>
        <Styles.Button isDisabled={isDisabledSalaries}>Apply</Styles.Button>
      </Styles.Cell>
    </Styles.Root>
  );
});
