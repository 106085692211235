import { IStudy } from '@avid/common';

import { IStudyCsv } from 'typings/constants-uploading.typing';

import { clientFirebase } from '../firebase';
import { parseBoolean } from '../utils';

import { VersionCsvUploader } from './version-csv-uploader';
import { MapStudyService } from './map-study.service';

export class EducationLevelsCsvUploader extends VersionCsvUploader {
  parseRows(csvRows: IStudyCsv[]): Record<string, IStudy> {
    return csvRows.reduce(
      (acc, row) => {
        const { achievement, skill, isSector, order, isUniversity, image } =
          row;

        const parse = new MapStudyService(row);

        const isSectorParsed = parseBoolean(isSector);

        const transformedLevel: IStudy = {
          order: +order,
          achievement,
          isSector: parseBoolean(isSector),
          isUniversity: parseBoolean(isUniversity),
          preRequisites: parse.parsePrerequisites(),
          price: parse.parseCost(),
        };

        if (image) {
          transformedLevel.image = +image;
        }

        if (skill) {
          transformedLevel.skill = parse.parseSkillUpgrade(isSectorParsed);
        }

        acc[row.level] = transformedLevel;

        return acc;
      },
      {} as Record<string, IStudy>
    );
  }

  upload(levels: Record<string, IStudy>) {
    return clientFirebase.firestore.versions.updateEducationLevels(this.version, {
      levels,
    });
  }
}
