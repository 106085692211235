import { IGameMods } from '@avid/common';

interface IProps {
  name: keyof IGameMods;
  title: string;
  isEnabled: boolean;
  onCheck: (
    name: keyof IGameMods,
    opts?: { enable?: (keyof IGameMods)[]; disable?: (keyof IGameMods)[] }
  ) => void;
  modsEnable?: (keyof IGameMods)[];
  modsDisable?: (keyof IGameMods)[];
}

export const GameMod = (props: IProps) => {
  const { name, title, isEnabled, modsEnable, modsDisable, onCheck } = props;

  const onChange = () =>
    onCheck(name, { enable: modsEnable, disable: modsDisable });

  return (
    <div>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={isEnabled}
        onChange={onChange}
      />
      <label htmlFor={name}>{title}</label>
    </div>
  );
};
