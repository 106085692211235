import styled from 'styled-components';

import { FONTS, FONT_SIZE } from 'theme';
import { TextInputStyle } from 'components/text-input/text-input.styles';
import { TableButton } from '../table-button';

export const TablePaginationStyles = {
  Root: styled.div`
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  PaginationInfo: styled.span`
    display: flex;
    align-items: center;
    font-family: ${FONTS.regular};
    font-size: ${FONT_SIZE.label};
  `,
  PageInput: styled(TextInputStyle.Input)`
    width: 30px;
    margin: 0 8px;
    padding: 2px;
    text-align: center;
  `,
  ButtonsContainer: styled.div`
    margin-left: 32px;
  `,
  SwitchButton: styled(TableButton)`
    margin-right: 16px;
  `,
};
