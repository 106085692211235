import {useEffect, useState } from 'react';
import { TGameVersion } from '@avid/common';

import { versionsAPI } from 'services/api';
import { valuesOfObject } from 'services/object';

import { GameSettingsItem } from './game-settings-item';

interface IProps {
  version: TGameVersion;
  startEducationOrder?: number;
}

export const GameSettingsEducation = (props: IProps) => {
  const { version, startEducationOrder } = props;

  const [label, setLabel] = useState('...');

  useEffect(() => {
    if (startEducationOrder === 0) {
      setLabel('Players start with no education');
      return;
    }

    const request = async () => {
      try {
        const levels = (await versionsAPI.getEducationLevels(version))?.levels;

        if (!levels) {
          throw new Error('No education levels');
        }

        const settingLabel = valuesOfObject(levels).find(
          (levelProps) => levelProps.order === startEducationOrder
        )?.achievement;

        setLabel(`Players start with ${settingLabel}` || 'Unknown');
      } catch (error) {
        setLabel('Not Found');
        console.error(error);
      }
    };

    request();
  }, [startEducationOrder, version]);

  return <GameSettingsItem param="Education Settings" value={label} />;
};
