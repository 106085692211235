

import { TABLES_TYPES } from 'constants/table';

import { TableStyle as Styles } from './table.style';
import { ILifeGoalsStatisticsItem } from 'typings/games';

interface ITableItemProps {
  value: string | number;
  isBold?: boolean;
}

const TableData = (props: ITableItemProps) => {
  const { value, isBold } = props;

  return (
    <Styles.TableItem title={String(value)} isBold={isBold}>
      {value}
    </Styles.TableItem>
  );
};

const TextRow = (row: IStatisticsItem) => (
  <Styles.TableRow>
    <TableData value={row.name} isBold />
    <TableData value={row.value} />
  </Styles.TableRow>
);

const PlayerInfoRow = (row: IPlayersListItem) => (
  <Styles.TableRow>
    <TableData value={row.name} />
    <TableData value={row.characterName} />
    <TableData value={row.email} />
  </Styles.TableRow>
);

const IconRow = (row: IStatisticsUserItem) => (
  <Styles.TableRow>
    <Styles.TableHead>
      <Styles.Flex>
        {row.icon && <Styles.UserIcon type={row.icon as any} size="20px" />}
        <Styles.HeadText title={row.name}>{row.name}</Styles.HeadText>
      </Styles.Flex>
    </Styles.TableHead>
    <Styles.TableItem>{row.value}</Styles.TableItem>
  </Styles.TableRow>
);

const LifeGoalRow = (row: ILifeGoalsStatisticsItem) => (
  <Styles.TableRow>
    <Styles.TableHead>
      <Styles.Flex>
        {row.icon && <Styles.UserIcon type={row.icon as any} size="20px" />}
        <Styles.HeadText title={row.name}>{row.name}</Styles.HeadText>
      </Styles.Flex>
    </Styles.TableHead>
    <Styles.TableItem>{row.value}</Styles.TableItem>
    <Styles.TableItem>{row.priority}</Styles.TableItem>
  </Styles.TableRow>
);

export const tableRow = (item: TItem | any, type: string, index: number) => {
  let Element;

  switch (type) {
    case TABLES_TYPES.default: {
      Element = TextRow;
      break;
    }
    case TABLES_TYPES.icon: {
      Element = IconRow;
      break;
    }
    case TABLES_TYPES.players: {
      Element = PlayerInfoRow;
      break;
    }
    case TABLES_TYPES.lifeGoal: {
      Element = LifeGoalRow;
      break;
    }
    default:
      Element = TextRow;
      break;
  }

  return <Element {...item} key={index} />;
};
