import styled from 'styled-components';

import { COLORS } from 'theme';
import { FilterContainerCss } from '../../filters.styles';

export type TLabelVariant = 'default' | 'short';

export const FilterStyles = {
  Label: styled.label<{ variant?: TLabelVariant }>`
    ${FilterContainerCss}
    vertical-align: top;
    ${({ variant }) => variant && variant === 'short' && 'width: 135px'};
  `,
  ControlContainer: styled.div`
    margin-top: 8px;
  `,
  Input: styled.input`
    display: block;
    width: 100%;
    min-height: 38px;
    padding: 0 16px;
    box-sizing: border-box;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    background-color: ${COLORS.white};
    outline: none;
    transition: all 0.1s;

    &:hover {
      border-color: hsl(0, 0%, 70%);
    }

    &:focus {
      border-color: ${COLORS.input_blue};
      box-shadow: 0 0 0 1px ${COLORS.input_blue};
    }
  `,
};
