"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logsUtils = void 0;
var utils_1 = require("../../../utils");
var SHOP_START = "bought";
var getSignedNumber = function (value) { return (value > 0 ? "+".concat(value) : value); };
var buildPriceString = function (stats) {
    var result = "";
    var isSlashAdded = false;
    Object.entries(stats).forEach(function (stat, index, array) {
        if (stat[0] === "gauges") {
            return;
        }
        if (!isSlashAdded) {
            result += " |";
        }
        var dot = index !== array.length - 1 && array[index + 1][1] ? "." : "";
        result +=
            stat[1] !== undefined
                ? " ".concat((0, utils_1.capitalize)(stat[0]), ": ").concat(getSignedNumber(stat[1])).concat(dot)
                : "";
        isSlashAdded = true;
    });
    return result;
};
var buildSkillsString = function (skills) {
    return skills.join(" and ");
};
var getMiniGameTimedOut = function (isTimedOut) {
    return isTimedOut ? "due to timeout" : "";
};
var getLogInfo = function (log) {
    var description = "", formatString = "";
    switch (log.type) {
        case "round": {
            switch (log.action) {
                case "start": {
                    (description = log.params.round.toString()),
                        (formatString = "Round ".concat(description, " started"));
                    break;
                }
            }
            break;
        }
        case "education": {
            var sector = log.params.sector;
            var sectorString = sector ? " in ".concat(sector) : "";
            switch (log.action) {
                case "end": {
                    var _a = log.params, level = _a.level, result = _a.result;
                    var resultString = result === "complete" ? "graduated" : "failed education";
                    description = "".concat(resultString, " | ").concat(level).concat(sectorString);
                    formatString = "You ".concat(description);
                    break;
                }
                case "start": {
                    var _b = log.params, level = _b.level, energy = _b.energy, money = _b.money;
                    var priceString = buildPriceString({ energy: energy, money: money });
                    description = "".concat(level).concat(sectorString);
                    formatString = "You started education | ".concat(description).concat(priceString);
                    break;
                }
            }
            break;
        }
        case "skills": {
            switch (log.action) {
                case "increase": {
                    var _c = log.params, sector = _c.sector, energy = _c.energy, money = _c.money, skills = _c.skills;
                    var skillsString = buildSkillsString(skills);
                    var priceString = buildPriceString({ energy: energy, money: money });
                    description = "".concat(skillsString, " in ").concat(sector);
                    formatString = "You upskilled ".concat(description).concat(priceString);
                    break;
                }
            }
            break;
        }
        case "reward": {
            switch (log.action) {
                case "receive": {
                    var _d = log.params, title = _d.title, badge = _d.badge, energy = _d.energy, money = _d.money, satisfaction = _d.satisfaction;
                    var badgeString = badge ? " ".concat(badge) : "", bonusesString = buildPriceString({ energy: energy, money: money, satisfaction: satisfaction });
                    description = "".concat(title).concat(badgeString, " Badge");
                    formatString = "You have received ".concat(description).concat(bonusesString);
                    break;
                }
            }
            break;
        }
        case "work": {
            switch (log.action) {
                case "apply": {
                    var _e = log.params, energy = _e.energy, money = _e.money, position = _e.position;
                    var priceString = buildPriceString({ money: money, energy: energy });
                    description = position;
                    formatString = "You applied for the job as ".concat(description).concat(priceString);
                    break;
                }
                case "tryOther": {
                    var _f = log.params, energy = _f.energy, money = _f.money, position = _f.position;
                    var priceString = buildPriceString({ money: money, energy: energy });
                    description = position;
                    formatString = "You tried other companies ".concat(priceString);
                    break;
                }
                case "newjob": {
                    description = log.params.position;
                    formatString = "You got a job as ".concat(log.params.position);
                    break;
                }
                case "promoted": {
                    description = log.params.position;
                    formatString = "You have been promoted to ".concat(log.params.position);
                    break;
                }
                case "cycle": {
                    var _g = log.params, money = _g.money, satisfaction = _g.satisfaction, energy = _g.energy, position = _g.position, gauges = _g.gauges;
                    var moneyString = money ? " Money: +".concat(money) : "";
                    var moodString = satisfaction
                        ? ". Satisfaction: ".concat(satisfaction > 0 ? "+".concat(satisfaction) : satisfaction)
                        : "";
                    var workGaugeString = (gauges === null || gauges === void 0 ? void 0 : gauges.work)
                        ? "| Work: ".concat(gauges.work > 0 ? "+".concat(gauges.work) : gauges.work)
                        : "";
                    var enegryString = energy
                        ? ". Energy: ".concat(+energy > 0 ? "+" : "").concat(energy)
                        : "";
                    description = position + workGaugeString;
                    formatString = "You have worked 1 cycle as ".concat(position).concat(moneyString).concat(moodString).concat(enegryString);
                    break;
                }
                case "quit": {
                    var reason = log.params.reason;
                    if (reason === "fail" || reason === "limits") {
                        var reasonString = reason === "fail"
                            ? "because of a job failure"
                            : "due to economic conditions";
                        description = "got fired ".concat(reasonString);
                    }
                    else
                        description = "quit the job";
                    description;
                    formatString = "You ".concat(description);
                    break;
                }
            }
            break;
        }
        case "shop": {
            var _h = log.params, money = _h.money, energy = _h.energy;
            var priceString = buildPriceString({ money: money, energy: energy });
            switch (log.action) {
                case "buy": {
                    var _j = log.params, item = _j.item, level = _j.level;
                    description = "".concat(level, " ").concat(item);
                    formatString = "You ".concat(SHOP_START, " ").concat(description).concat(priceString);
                    break;
                }
                case "travel": {
                    var _k = log.params, level = _k.level, country = _k.country;
                    description = "".concat(level, " travel to ").concat(country);
                    formatString = "You ".concat(SHOP_START, " ").concat(description).concat(priceString);
                    break;
                }
                case "outfit": {
                    var name_1 = log.params.name;
                    description = name_1;
                    formatString = "You ".concat(SHOP_START, " ").concat(name_1, " skin").concat(priceString);
                    break;
                }
            }
            break;
        }
        case "lifeCard": {
            switch (log.action) {
                case "complete": {
                    var _l = log.params, title = _l.title, choose = _l.choose, lifestyle = _l.lifestyle, energy = _l.energy, money = _l.money, skills = _l.skills;
                    var choseDesc = choose === "Yes" ? ' You chose "Yes"' : "";
                    var choseAdm = choose === "Yes" ? ' Chose "Yes"' : "";
                    var energyDesc = energy
                        ? " | Energy: ".concat(+energy > 0 ? "+" : "").concat(energy)
                        : "";
                    var bankDesc = money
                        ? " | Money: ".concat(+money > 0 ? "+" : "").concat(money)
                        : "";
                    var lifestyleDesc = lifestyle ? " | New status: ".concat(lifestyle, ".") : "";
                    var skillsDesc = skills ? " | Skills improved: ".concat(skills) : "";
                    description = "".concat(title).concat(choseAdm).concat(lifestyleDesc).concat(skillsDesc);
                    formatString = "Life card: ".concat(title).concat(choseDesc).concat(energyDesc).concat(bankDesc).concat(lifestyleDesc).concat(skillsDesc);
                    break;
                }
                case "disagree": {
                    var title = log.params.title;
                    description = "".concat(title, " Chose \"No\"");
                    formatString = "Life card: ".concat(title, " You chose \"No\"");
                    break;
                }
            }
            break;
        }
        case "expenses": {
            switch (log.action) {
                case "deduct": {
                    var money = log.params.money;
                    if (!money) {
                        break;
                    }
                    formatString = "$".concat(Math.abs(+money), " was deducted as living expenses");
                    break;
                }
            }
            break;
        }
        case "bonus": {
            switch (log.action) {
                case "get": {
                    var _m = log.params, energy = _m.energy, money = _m.money, satisfaction = _m.satisfaction, performance_1 = _m.performance;
                    var bonusString = buildPriceString({ energy: energy, money: money, satisfaction: satisfaction });
                    description = performance_1;
                    formatString = "You got performance bonus for your ".concat(performance_1).concat(bonusString);
                }
            }
            break;
        }
        case "bonusCodes": {
            switch (log.action) {
                case "cheatCode": {
                    var _o = log.params, cheatCode = _o.cheatCode, energy = _o.energy, money = _o.money, possession = _o.possession, skills = _o.skills, status_1 = _o.status, gauges = _o.gauges;
                    var skillsString = skills
                        ? " | Skills improved: ".concat(skills.join(", "))
                        : "";
                    var statusString = status_1 ? " | New status: ".concat(status_1) : "";
                    var priceString = energy || money ? buildPriceString({ energy: energy, money: money }) : "";
                    var possessionString = possession
                        ? " | New possession: ".concat(possession)
                        : "";
                    var gaugesString_1 = "";
                    if (gauges) {
                        gaugesString_1 = " |";
                        (0, utils_1.entriesObject)(gauges).forEach(function (_a, index, array) {
                            var gaugeName = _a[0], gaugeAdd = _a[1];
                            var gaugeLabel = (0, utils_1.capitalize)(gaugeName);
                            var dot = index + 1 >= array.length ? "" : ".";
                            gaugesString_1 += " ".concat(gaugeLabel, ": +").concat(gaugeAdd).concat(dot);
                        });
                    }
                    var bonusString = "".concat(priceString).concat(skillsString).concat(statusString).concat(possessionString);
                    description = "Code: ".concat(cheatCode).concat(bonusString).concat(gaugesString_1);
                    formatString = "You applied a cheat code".concat(bonusString);
                    break;
                }
                case "referralCode": {
                    var _p = log.params, money = _p.money, referralCode = _p.referralCode;
                    var priceString = buildPriceString({ money: money });
                    description = referralCode;
                    formatString = "You applied a referral code".concat(priceString);
                    break;
                }
                case "referralCodeUsed":
                    {
                        var _q = log.params, money = _q.money, applierName = _q.applierName, referralCode = _q.referralCode;
                        var priceString = buildPriceString({ money: money });
                        description = "".concat(referralCode, ". Player name(s): ").concat(applierName);
                        formatString = "You received a reward for using your referral code".concat(priceString);
                    }
                    break;
            }
            break;
        }
        case "gauges": {
            switch (log.action) {
                case "startMiniGame": {
                    formatString = "You attempted to play a mini game";
                    break;
                }
                case "failMiniGame": {
                    var _r = log.params, energy = _r.energy, isTimedOut = _r.isTimedOut;
                    var priceString = buildPriceString({ energy: energy });
                    if (isTimedOut) {
                        description = (0, utils_1.capitalize)(getMiniGameTimedOut(isTimedOut));
                        formatString =
                            "Your 2 minutes are up and have received a reward for your efforts";
                    }
                    else {
                        formatString = "You failed the mini game";
                    }
                    formatString += priceString;
                    break;
                }
                case "passMiniGame": {
                    var _s = log.params, energy = _s.energy, isTimedOut = _s.isTimedOut;
                    var priceString = buildPriceString({ energy: energy });
                    if (isTimedOut) {
                        description = (0, utils_1.capitalize)(getMiniGameTimedOut(isTimedOut));
                    }
                    formatString = "You have passed the mini game. ".concat(priceString);
                    break;
                }
                case "deduct": {
                    var money = log.params.money;
                    formatString = "Gauges deducted. Living expenses: ".concat(money || 0);
                    description = "Gauges and living expenses deducted";
                }
            }
            break;
        }
        case "lifeGoals": {
            switch (log.action) {
                case "change": {
                    var goals = log.params.goals;
                    var goalsString = goals
                        .map(function (_a, index) {
                        var goal = _a.goal;
                        var text = goal;
                        if (index === 0) {
                            text += " (Priority)";
                        }
                        return text;
                    })
                        .join(", ");
                    description = goalsString;
                    formatString = "You have chosen your life goals: ".concat(goalsString);
                    break;
                }
                case "confirm": {
                    var _t = log.params, goal = _t.goal, money = _t.money, satisfaction = _t.satisfaction, energy = _t.energy;
                    var statsString = buildPriceString({ energy: energy, money: money, satisfaction: satisfaction });
                    description = goal;
                    formatString = "You have achieved your life goal: ".concat(goal).concat(statsString);
                    break;
                }
            }
            break;
        }
        case "charity":
        case "hobby": {
            var itemString = log.type === "charity"
                ? "You have done some charity:"
                : "You have participated in a hobby:";
            switch (log.action) {
                case "do": {
                    var _u = log.params, item = _u.item, option = _u.option, optionSettings = _u.optionSettings, energy = _u.energy, money = _u.money, satisfaction = _u.satisfaction;
                    var countryString = (optionSettings === null || optionSettings === void 0 ? void 0 : optionSettings.country)
                        ? " in ".concat(optionSettings.country)
                        : "";
                    var bonusString = buildPriceString({
                        energy: energy,
                        money: money,
                        satisfaction: satisfaction,
                    });
                    description = "".concat(item.item, " - ").concat(option.option).concat(countryString);
                    formatString = "".concat(itemString, " ").concat(description).concat(bonusString);
                    break;
                }
            }
            break;
        }
        case "specialJob": {
            var job = log.params.job;
            description = "Special Job: ".concat(job);
            var articleJob = (0, utils_1.insertArticle)(job);
            switch (log.action) {
                case "reject": {
                    formatString = "You have rejected a special job offer as  ".concat(articleJob);
                    break;
                }
                case "accept":
                case "outome": {
                    var _v = log.params, satisfaction = _v.satisfaction, energy = _v.energy, money = _v.money;
                    var statsString = buildPriceString({
                        energy: energy,
                        money: money,
                        satisfaction: satisfaction,
                    });
                    if (log.action === "accept") {
                        formatString = "You have accepted a special job offer as ".concat(articleJob).concat(statsString);
                    }
                    if (log.action === "outome") {
                        formatString = "You have worked in your special job as an ".concat(articleJob).concat(statsString);
                    }
                    break;
                }
            }
            break;
        }
        case "marriage":
        case "children": {
            switch (log.action) {
                case "yes":
                case "outcome": {
                    var _w = log.params, doorId = _w.doorId, energy = _w.energy, money = _w.money, satisfaction = _w.satisfaction;
                    var statsString = buildPriceString({
                        energy: energy,
                        money: money,
                        satisfaction: satisfaction,
                    });
                    var doorNumber = +doorId.replace(log.type, "");
                    if (log.action === "yes") {
                        var doorDescription = log.params.description;
                        formatString = "You have chosen door #".concat(doorNumber, " for ").concat(log.type).concat(statsString);
                        description = "Door #".concat(doorNumber, " - ").concat(doorDescription);
                    }
                    if (log.action === "outcome") {
                        formatString = "You have contributed to your ".concat(log.type, " and it has benefitted your life").concat(statsString);
                        description = "Door #".concat(doorNumber);
                    }
                    break;
                }
                case "no": {
                    formatString = "You have said no to ".concat(log.type);
                    break;
                }
            }
        }
    }
    return { description: description, formatString: formatString };
};
var logToString = function (log) {
    var gauges = "";
    if (log.params.gauges) {
        var gaugeChanges = (0, utils_1.entriesObject)(log.params.gauges)
            .map(function (_a) {
            var gaugeName = _a[0], gaugeValue = _a[1];
            if (!gaugeValue) {
                return "";
            }
            var plus = gaugeValue > 0 ? "+" : "";
            return "".concat((0, utils_1.capitalize)(gaugeName), ": ").concat(plus).concat(gaugeValue);
        })
            .join(", ");
        gauges = " | Gauges: ".concat(gaugeChanges);
    }
    var bankAccount = log.params.money !== undefined ? " | Bank Account: ".concat(log.playerMoney) : "";
    return "".concat(getLogInfo(log).formatString).concat(gauges).concat(bankAccount);
};
exports.logsUtils = {
    getLogInfo: getLogInfo,
    logToString: logToString,
};
