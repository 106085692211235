import { ColumnInstance } from 'react-table';

import { IStructuredLog } from 'screens/players-history';
import { HidingStyles as Styles } from './hiding.styles';

interface IHidingProps {
  columns: ColumnInstance<IStructuredLog>[];
}

export const Hiding = (props: IHidingProps) => {
  const { columns } = props;

  return (
    <Styles.Root>
      {columns.map((column) => (
        <Styles.Label key={column.id}>
          <input type="checkbox" {...column.getToggleHiddenProps()} />

          {column.Header as any}
        </Styles.Label>
      ))}
    </Styles.Root>
  );
};
