import { ChangeEventHandler, useCallback, useRef, useState } from 'react';

import { CsvUploading } from 'services/constants-uploading';
import { firebaseStorage } from 'services/firebase';
import { fillLinkAndDownloadConstantsFile } from 'services/utils';
import { ICsvUploader } from 'typings/constants-uploading.typing';

import { useCsvUploadingStatus } from './csv-uploading-status';

export const useCsvConstants = (
  createUploader: () => ICsvUploader,
  getStoragePath: () => string
) => {
  const [uploadFile, setUploadFile] = useState<File>();

  const {
    isAvailableStatus,
    isAvailableDownloadStatus,
    statusLabel,
    setStatus,
  } = useCsvUploadingStatus();

  const downloadRef = useRef<HTMLAnchorElement>(null);

  const isDisabled = !uploadFile || !isAvailableStatus;
  const isDownloadDisabled = !isAvailableDownloadStatus;

  const onChangeFile: ChangeEventHandler<HTMLInputElement> = useCallback(
    (ev) => {
      const file = ev.target.files?.[0];

      if (!file) {
        return;
      }

      setUploadFile(file);
      setStatus(undefined);
    },
    [setStatus]
  );

  const onUpload = useCallback(async () => {
    if (isDisabled) {
      return;
    }

    setStatus('loading');

    const uploader = createUploader();
    const storagePath = getStoragePath();

    const csvUploading = new CsvUploading(uploader);

    try {
      const { formattedFile } = await csvUploading.fromFile(uploadFile);

      await firebaseStorage.uploadConstantsFile(storagePath, formattedFile);

      setStatus('success');
    } catch (error) {
      console.error(error);
      setStatus('error');
    }
  }, [createUploader, getStoragePath, isDisabled, setStatus, uploadFile]);

  const onDownload = useCallback(async () => {
    if (isDownloadDisabled) {
      return;
    }

    const storagePath = getStoragePath();

    setStatus('loading');

    const result = await fillLinkAndDownloadConstantsFile(
      storagePath,
      downloadRef
    );

    setStatus(result ? 'downloaded' : 'error');
  }, [getStoragePath, isDownloadDisabled, setStatus]);

  return {
    statusLabel,
    isDisabled,
    isDownloadDisabled,
    fileName: uploadFile?.name,
    downloadRef,
    onUpload,
    onChangeFile,
    onDownload,
  };
};
