import { IQuestionsAmountCsv } from 'typings/constants-uploading.typing';

import { clientFirebase } from '../firebase';

import { VersionCsvUploader } from './version-csv-uploader';

export class QuestionsAmountCsvUploader extends VersionCsvUploader {
  parseRows(csvRows: IQuestionsAmountCsv[]) {
    return csvRows.map(
      ({ level, questions }) => [level, +questions] as [string, number]
    );
  }

  async upload(questionsAmounts: [string, number][]) {
    await clientFirebase.firestore.versions.clearQuestionsAmounts(this.version);

    return Promise.all(
      questionsAmounts.map(([study, questionsAmount]) =>
        clientFirebase.firestore.versions.setQuestionsAmount(this.version, study, {
          questionsAmount,
        })
      )
    );
  }
}
