export const ROUTES = {
  START: '/',
  AUTH: '/auth',
  SIGN_IN: '/sign-in',
  NEW_GAME: '/new-game',
  ACTIVE_GAME: '/active-game',
  STATISTIC: '/statistic',
  PLAYERS: '/players',
  EXPORT_DATA: '/export',
  UPLOAD_GAMES: '/upload-games',
  UPLOAD_CONSTANTS: '/upload-constants',
  PLAYERS_HISTORY: '/players-history',
  LOGIN: '/login',
  SHARE: '/share',
  SHARE_HOME: '/home',
} as const;
