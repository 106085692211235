import { Modal } from 'components/modal';
import { TableButton } from '../table-button';

import { useLogsCSV } from './logs-csv.state';
import { LogsCSVModal } from './components';

interface ILogsCSVProps {
  gameCode: string;
}

export const LogsCSV = (props: ILogsCSVProps) => {
  const { gameCode } = props;

  const { isOpen, onChange, onClose } = useLogsCSV();

  return (
    <>
      <TableButton text="Export CSV" onClick={onChange} />

      <Modal isOpen={isOpen} onCloseModal={onChange}>
        <LogsCSVModal gameCode={gameCode} onClose={onClose} />
      </Modal>
    </>
  );
};
