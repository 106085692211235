import styled from 'styled-components';

import { Theme } from 'theme';

const { ButtonMedium } = Theme;

export const RefreshButtonStyles = {
  ButtonContainer: styled.div`
    display: flex;
    margin-top: 10px;
    margin-left: auto;
  `,
  ButtonMedium: styled(ButtonMedium)``,
};
