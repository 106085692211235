
import styled from 'styled-components';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

import { Span } from 'components/typography';
import { COLORS, FONT_SIZE } from 'theme';

import { IUseRoundTimerProps, useRoundTimer } from './round-timer.state';
import { RoundTimerStyles as Styles } from './round-timer.styles';
import { getNumberPostfix } from 'services/utils';

interface IProps extends IUseRoundTimerProps {
  round: number;
}

const PERCENT_ALERT = 89;

const TimerText = styled(Span)<{
  fontSize: string;
  color: string;
  isOpacity: boolean;
}>`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  ${({ isOpacity }) => isOpacity && 'opacity: 0.3;'}
`;

const getFontSize = (valueLength: number) => {
  if (valueLength > 8) {
    return FONT_SIZE[48];
  }

  if (valueLength > 5) {
    return FONT_SIZE[60];
  }

  return FONT_SIZE[80];
};

const getColor = (percent: number) => {
  if (percent <= PERCENT_ALERT) {
    return { path: COLORS.violet, text: COLORS.black };
  }

  return { path: COLORS.redSeconday, text: COLORS.redSeconday };
};

export const RoundTimer = (props: IProps) => {
  const { round, roundStart, roundDuration, endRound, addTimeElapsed } = props;

  const { timeoutString, percent = 0 } = useRoundTimer({
    roundDuration,
    roundStart,
    endRound,
    addTimeElapsed,
  });

  const fontSize = getFontSize(timeoutString?.length || 0);

  const { path, text } = getColor(percent);

  const isStopped = percent <= 0;

  const roundPostfix = getNumberPostfix(isStopped ? round + 1 : round);

  return (
    <Styles.Root>
      <CircularProgressbarWithChildren
        strokeWidth={5}
        value={percent}
        styles={buildStyles({
          pathColor: path,
          trailColor: COLORS.middle_gray,
          backgroundColor: COLORS.white,
        })}
      >
        <Styles.ProgressChildrenWrapper>
          <Styles.RoundNumber
            typography="QuicksandBold24"
            isOpacity={isStopped}
          >
            {roundPostfix} round
          </Styles.RoundNumber>

          <TimerText
            typography="QuicksandBold80"
            fontSize={fontSize}
            color={text}
            isOpacity={isStopped}
          >
            {timeoutString}
          </TimerText>
        </Styles.ProgressChildrenWrapper>
      </CircularProgressbarWithChildren>
    </Styles.Root>
  );
};
