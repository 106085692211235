import { useMemo } from 'react';

import { useReduxSelector } from './redux';

export const useReduxGames = () => {
  const games = useReduxSelector((redux) => redux.main.games);

  return { games };
};

export const useGameByGameCode = (gameCode?: string) => {
  const { games } = useReduxGames();

  const game = useMemo(
    () => (gameCode ? games.find((g) => g.config.gameCode === gameCode) : null),
    [gameCode]
  );

  return { games, game };
};
