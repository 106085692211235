import styled from 'styled-components';

import { FONTS, COLORS, Theme } from 'theme';

interface IBoxText {
  color?: string;
  fontSize?: string;
}

interface IContentBox {
  padding?: string;
}

export const PanelStyles = {
  TitleContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  MainContainer: styled.div`
    max-width: 1100px;

    @media print {
      width: 100%;
      max-width: 100%;
      transform: scale(0.9);
      margin-top: -100px;
    }
  `,
  Title: styled(Theme.Title)`
    margin-top: 32px;
    margin-bottom: 32px;
    flex: 1;
  `,
  GameCode: styled.span`
    font-family: ${FONTS.semiBold};
    font-size: 18px;
  `,
  BoxText: styled.p<IBoxText>`
    font-family: ${FONTS.quicksandBold};
    font-size: ${(props) => props.fontSize || '18px'};
    color: ${(props) => props.color};
    margin-bottom: 0;
  `,
  BoxContainer: styled.div`
    width: 1111px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    background: ${COLORS.blue_gray};
    border: 1px solid ${COLORS.light_gray};
    box-sizing: border-box;
    border-radius: 20px;
    padding: 0 32px;
  `,
  MarginWrapper: styled.div`
    margin-top: 24px;
  `,
  TopRow: styled.div`
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    column-gap: 48px;
  `,
  SubTitle: styled(Theme.SubTitle)`
    width: inherit;
    margin-bottom: 20px;
  `,
  LeaderboardsWrapper: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
    row-gap: 32px;
  `,
  ContentBox: styled.div<IContentBox>`
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 344px;
    background: ${COLORS.blue_gray};
    border: 1px solid ${COLORS.light_gray};
    padding: ${({ padding }) => padding || '35px'};
    padding-bottom: 0px;
    border-radius: 20px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  `,
};
