
import styled, { css } from 'styled-components';

import { Grid } from 'components/UI';
import { typographyCss } from 'theme';

interface IProps {
  label: string;
  value: string;
  isRow?: boolean;
}

const Styles = {
  Root: styled(Grid)<{ isRow: boolean }>`
    row-gap: 8px;

    ${({ isRow }) =>
      isRow &&
      css`
        grid-template-columns: 95px auto;
        column-gap: 10px;
        row-gap: 0px;
      `}
  `,
  Label: styled.dt`
    ${typographyCss.QuicksandRegular14}
  `,
  Value: styled.dd<{ isRow: boolean }>`
    ${typographyCss.QuicksandMedium16}
    ${({ isRow }) =>
      isRow &&
      css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `}
  `,
};

export const AdminGameValue = (props: IProps) => {
  const { label, value, isRow = true } = props;

  return (
    <Styles.Root isRow={isRow}>
      <Styles.Label>{label}</Styles.Label>
      <Styles.Value isRow={isRow}>{value}</Styles.Value>
    </Styles.Root>
  );
};
