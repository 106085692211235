import { useCallback, useEffect } from 'react';

import { callableFunctionsService } from 'services/api';
import { useReduxSelector, useUpdateState } from 'services/hooks';
import { getGameIdentity } from 'services/utils';
import { checkActiveGame } from 'services/utils/games';
import { IStatisticsData } from 'typings/games';

import { fetchRoundSnapshot } from './statistics.api';
import { ISelectData } from './statistics.types';

interface IState {
  isLoading: boolean;
  round?: number;
  gameCode?: string;
  statistics?: IStatisticsData;
}

const INIT_STATE: IState = {
  isLoading: false,
};

export const useStatistics = (gameCode?: string) => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  const games = useReduxSelector((redux) => redux.main.games);

  const changeGame = useCallback(
    async (code: string) => {
      updateState({ isLoading: true, gameCode: code, round: undefined });

      const statistics = await callableFunctionsService.getStatistics(
        getGameIdentity(code)
      );

      try {
        updateState({ isLoading: false, statistics });
      } catch (exception) {
        console.error(exception);
        updateState({ isLoading: false, statistics });
      }
    },
    [updateState]
  );

  const onChangeRound = useCallback(
    async (round: number) => {
      if (!state.gameCode) {
        return;
      }

      updateState({ isLoading: true });

      try {
        const statistics = await fetchRoundSnapshot(
          state.gameCode,
          round.toString()
        );

        updateState({ isLoading: false, round, statistics });
      } catch (ex) {
        console.error(ex);
        updateState({ isLoading: false });
      }
    },
    [state.gameCode, updateState]
  );

  const onSelectGame = (game: ISelectData) => changeGame(game.value);

  useEffect(() => {
    if (gameCode) {
      changeGame(gameCode);
      return;
    }

    const activeGame = games.find(checkActiveGame);

    if (!activeGame) {
      return;
    }

    changeGame(activeGame.config.gameCode);
  }, [changeGame, gameCode, games]);

  const isSelectedGameFinished =
    games.find((game) => game.config.gameCode === state.gameCode)?.status ===
    'finished';

  return {
    ...state,
    isSelectedGameFinished,
    isGamesExist: games.length > 0,
    onSelectGame,
    onChangeRound,
  };
};
