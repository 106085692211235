

import { Loader } from 'components/loader';
import { LogsCSV } from '../logs-csv';
import { Filters } from '../filters';
import { Hiding } from '../hiding';
import { DataCells } from '../data-cells';
import { TablePagination } from '../pagination';
import { TableStyledComponents as TableStyles } from '../table-styled';
import { ITableProps } from '../../players-history.typing';

import { useLogsTable } from './logs-table.state';
import { LogsTableStyles as Styles } from './logs-table.styles';
import { BUTTONS, STRINGS } from './logs-table.constants';

const { ERROR, UPDATING, SORT } = STRINGS;
const { FILTERS } = BUTTONS;

export const LogsTable = (props: ITableProps) => {
  const { sharedGameCode } = props;

  const {
    isLoading,
    isUpdating,
    isError,
    isShowFilters,
    isNextButtonDisabled,
    page,
    headerGroups,
    canPreviousPage,
    allColumns,
    pageIndex,
    pagesLength,

    getTableBodyProps,
    getTableProps,
    prepareRow,
    previousPage,
    gotoPage,
    onClickFilters,
    loadNextPage,
    onApplyFilters,
  } = useLogsTable(props);

  if (isLoading) {
    return <Loader />;
  }

  const RenderLogsCSV = !sharedGameCode && (
    <LogsCSV gameCode={props.gameCode} />
  );

  return (
    <>
      <Styles.ButtonsContainer>
        {RenderLogsCSV}
        <Styles.Button text={FILTERS} onClick={onClickFilters} />
      </Styles.ButtonsContainer>

      <Filters isShow={isShowFilters} onApply={onApplyFilters} />

      <Hiding columns={allColumns} />

      <Styles.UpdatingParagraph
        font="semiBold"
        isVisible={isUpdating || isError}
      >
        {(isUpdating && UPDATING) || (isError && ERROR) || ' '}
      </Styles.UpdatingParagraph>

      <TableStyles.TableContainer isDataTable>
        <TableStyles.Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <TableStyles.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableStyles.HeadCell {...column.getHeaderProps()}>
                    <span
                      {...column.getSortByToggleProps({
                        title: column.canSort
                          ? `${SORT} ${column.Header}`
                          : undefined,
                      })}
                    >
                      {column.render('Header')}
                      <Styles.SortArrow
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                      />
                    </span>
                  </TableStyles.HeadCell>
                ))}
              </TableStyles.HeadRow>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <TableStyles.DataRow {...row.getRowProps()}>
                  <DataCells cells={row.cells} />
                </TableStyles.DataRow>
              );
            })}
          </tbody>
        </TableStyles.Table>
      </TableStyles.TableContainer>

      <TablePagination
        pageIndex={pageIndex}
        pagesLength={pagesLength}
        canPreviousPage={canPreviousPage}
        canNextPage={!isNextButtonDisabled}
        previousPage={previousPage}
        nextPage={loadNextPage}
        gotoPage={gotoPage}
      />
    </>
  );
};
