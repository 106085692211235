export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getNumberPostfix = (d: number) => {
  if (d > 3 && d < 21) {
    return `${d}th`;
  }
  switch (d % 10) {
    case 1:
      return `${d}st`;
    case 2:
      return `${d}nd`;
    case 3:
      return `${d}rd`;
    default:
      return `${d}th`;
  }
};

export const addCSVExtension = (fileName: string) => {
  return fileName.endsWith('.csv') ? fileName : `${fileName}.csv`;
};
