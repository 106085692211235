import styled from 'styled-components';

import { COLORS, FONTS } from 'theme';

export const TableStyledComponents = {
  TableContainer: styled.div<{ isDataTable?: boolean }>`
    width: 100%;
    overflow-x: auto;
    margin-top: 16px;
    margin-bottom: 32px;
    padding: ${({ isDataTable }) => (isDataTable ? 16 : 35)}px;
    background: ${COLORS.blue_gray};
    border: 1px solid ${COLORS.light_gray};
    box-sizing: border-box;
    border-radius: 20px;
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: collapse;
  `,
  HeadRow: styled.tr`
    font-family: ${FONTS.bold};
    font-weight: bold;
    border-bottom: 2px solid black;
  `,
  HeadCell: styled.th`
    height: 42px;
    padding-left: 17px;
    padding-bottom: 8px;
    text-align: left;
    font-family: ${FONTS.regular};
    > * + * {
      padding-left: 4px;
    }
    white-space: nowrap;
    user-select: none;
  `,
  DataRow: styled.tr`
    height: 42px;
    &:nth-child(even) {
      background-color: ${COLORS.table_gray};
    }
  `,
};
