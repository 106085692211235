import { IGameIdentity } from '@avid/common';

import { firebaseFunctions } from 'services/firebase';
import {
  IStatisticsData,
  IGetPlayersOfPositionParams,
  IGameEmailReview,
} from 'typings/games';

interface IGetGameReviewsData {
  reviews: IGameEmailReview[];
}

export const callableFunctions = {
  roundSnapshot: firebaseFunctions.httpsCallable('roundSnapshot'),
  statistic: firebaseFunctions.httpsCallable('statistic'),
  createGame: firebaseFunctions.httpsCallable('createGame'),
  getGameLogs: firebaseFunctions.httpsCallable('getGameLogs'),
  getPlayersOfPosition: firebaseFunctions.httpsCallable('getPlayersOfPosition'),
  getGameReviews: firebaseFunctions.httpsCallable('getGameReviews'),
};

export class CallableFunctionsService {
  public async getStatistics(gameIdentity: IGameIdentity) {
    const result = await callableFunctions.statistic({ gameIdentity });
    return result.data as IStatisticsData | undefined;
  }

  public async getGameLogs(gameIdentity: IGameIdentity) {
    const result = await callableFunctions.getGameLogs(gameIdentity);
    return result.data as string;
  }

  public async getPlayersOfPosition(params: IGetPlayersOfPositionParams) {
    const result = await callableFunctions.getPlayersOfPosition(params);
    return result.data as number;
  }

  public async getGameReviews(gameIdentity: IGameIdentity) {
    const result = await callableFunctions.getGameReviews({ gameIdentity });
    return result.data as IGetGameReviewsData;
  }
}

export const callableFunctionsService = new CallableFunctionsService();
