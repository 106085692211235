import { ClientFirebase } from '@avid/firebase';

import { firebaseApp } from './app';

export const realtimeDatabase = firebaseApp.database();

export const db = firebaseApp.firestore();

db.settings({
  ignoreUndefinedProperties: true,
});

export const firebaseFunctions = firebaseApp.functions(
  process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION
);

export const auth = firebaseApp.auth();

export const clientFirebase = new ClientFirebase(firebaseApp);
