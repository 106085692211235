import { TGameStatus, IRound, IGame } from '@avid/common';

import { update } from 'services/firebase.api';
import { TAsyncAction } from 'typings/react';

import { MainActions } from './main.actions';
import { IUpdateParams, updateGamesArray } from './main.utils';

export interface IUpdateGameParams extends IUpdateParams {
  gameCode: string;
}

interface IPostGameInfoParams {
  gameCode: string;
  newStatus: TGameStatus;
  rounds: IRound[];
  timeElapsed: number;
}

const postGameInfo = (params: IPostGameInfoParams) => {
  const { gameCode, newStatus, rounds, timeElapsed } = params;

  const gameInfo: Partial<IGame> = {
    timeElapsed,
    rounds,
    status: newStatus,
  };

  return update('games', gameInfo, gameCode);
};

export const MainThunk = {
  updateGameAsync: (params: IUpdateGameParams): TAsyncAction => async (
    dispatch,
    getState
  ) => {
    dispatch(MainActions.startFetchGames());

    const { gameCode, rounds, status, timeElapsed } = params;

    const { games } = getState().main;

    const gameIndex = games.findIndex(
      (game) => game.config.gameCode === gameCode
    );

    if (gameIndex === -1) {
      dispatch(MainActions.failFetchGames());
      return;
    }

    try {
      await postGameInfo({ gameCode, newStatus: status, rounds, timeElapsed });

      const updatedGames = updateGamesArray({
        updateParams: params,
        games,
        gameIndex,
      });
      dispatch(MainActions.setUserGames(updatedGames));
    } catch (ex) {
      console.error(ex);
      dispatch(MainActions.failFetchGames());
    }
  },
};
