import {FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  PossessionsUtils,
  TPossessionItem,
  TPossessionLevel,
} from '@avid/common';

import { CHART_OPTIONS, createSeries } from './possession-histogram.utils';

import { IColumnPossessionData } from 'typings';
import { TGameVersion } from '@avid/common';

interface IPossessionHistogramProps {
  columnPossession: IColumnPossessionData<number>;
  version: TGameVersion;
}

export const PossessionHistogram: FC<IPossessionHistogramProps> = (props) => {
  const { columnPossession, version } = props;

  Highcharts.setOptions({
    ...CHART_OPTIONS,
    tooltip: {
      ...CHART_OPTIONS.tooltip,
      pointFormatter() {
        const title =
          PossessionsUtils.getVersionPossessionLevelLabel({
            version,
            level: this.series.name as TPossessionLevel,
            possession: this.category.toLocaleLowerCase() as TPossessionItem,
          }) || this.series.name;
        const color = (this.series as any).color;

        return (
          `<tr><td style="color:${color};padding:0">${title}: </td>` +
          `<td style="padding:0"><b>${this.y}</b></td></tr>`
        );
      },
    },
  });

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{ series: createSeries(columnPossession) }}
    />
  );
};
