import { useEffect, useState } from 'react';

import { realtimeDatabase } from '../../firebase';

export const useRealTimePlayersCount = (gameCode: string) => {
  const [playersCount, setPlayersCount] = useState(0);

  useEffect(() => {
    const ref = realtimeDatabase.ref(`games/${gameCode}/players`);

    ref.on('value', (val) => {
      setPlayersCount(val.numChildren());
    });

    return () => ref.off();
  }, [gameCode]);

  return { playersCount };
};
