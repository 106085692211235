import { useLocation } from 'react-router-dom';

import { Grid } from 'components/UI';
import { ROUTES } from 'constants/routes';

import { CurrentGameLink, CurrentLink, HomeLink } from './components';

export const Navigation = () => {
  const location = useLocation();

  const RenderCurrent = location.pathname.startsWith(ROUTES.ACTIVE_GAME) ? (
    <CurrentGameLink />
  ) : (
    <CurrentLink />
  );

  return (
    <Grid columns={1} justifyContent="start">
      <HomeLink />

      {RenderCurrent}
    </Grid>
  );
};
