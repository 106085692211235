import { ICheatCode, IPlayerBonus } from '@avid/common';

import {
  ICsvUploader,
  ICheatCodeCsv,
} from 'typings/constants-uploading.typing';
import { clientFirebase } from '../firebase';

export class CheatCodesCSVUploader implements ICsvUploader {
  parseRows(csvRows: ICheatCodeCsv[]) {
    const cheatCodesArray: [string, ICheatCode][] = csvRows.map(
      (csvCheatCode) => {
        const {
          code,
          limit,
          energy,
          money,
          skills,
          lifestyle,
          possession,
          health,
          play,
          love,
        } = csvCheatCode;

        const bonus: IPlayerBonus = {};

        const cheatCode: ICheatCode = { bonus };

        if (limit) {
          cheatCode.limit = Number(limit);
        }

        if (health || play || love) {
          bonus.gauges = {};

          if (health) {
            bonus.gauges.health = Number(health);
          }

          if (play) {
            bonus.gauges.play = Number(play);
          }

          if (love) {
            bonus.gauges.love = Number(love);
          }
        }

        if (lifestyle) {
          bonus.status = lifestyle;
        }

        if (possession) {
          bonus.possession = possession;
        }

        if (energy) {
          bonus.energy = Number(energy);
        }

        if (money) {
          bonus.money = Number(money);
        }

        if (skills) {
          bonus.skills = skills.split(',');
        }

        return [code, cheatCode];
      }
    );

    return cheatCodesArray;
  }

  async upload(cheatCodesArray: [string, ICheatCode][]) {
    await clientFirebase.firestore.cheatCodes.clear();
    await clientFirebase.firestore.cheatCodes.createMany(cheatCodesArray);
  }
}
