import { INavigationItem } from 'typings/navigation.typing';

import { NavigationLabel } from './navigation-label';

import { Grid } from 'components/UI';
import { Icon } from 'components/icons';

export const NavigationLink = (props: INavigationItem) => {
  return (
    <Grid columns={2} alignItems="center">
      <Icon type="thinArrow" />
      <NavigationLabel {...props} />
    </Grid>
  );
};
