import {FC } from 'react';

import { ISelectData } from 'screens/statistics/statistics.types';

import { useStatisticMenuState } from './statistic-menu.state';

import { STRINGS } from 'constants/string';

import { StatisticMenuStyle } from './statistic-menu.styles';
import { StatisticsRounds } from 'components/statistics-rounds';

export interface IStatisticsStateParameters {
  currentRound?: number;
  currentGameCode?: string;
  hideRounds?: boolean;
  isSmall?: boolean;
  getStatisticsData?: (isSearch: boolean) => Promise<void>;
  selectGame: (selectData: ISelectData) => void;
  onSelectSnapshot?: (round: number) => void;
}

const strings = STRINGS.STATISTIC;

export const StatisticMenu: FC<IStatisticsStateParameters> = (props) => {
  const {
    hideRounds,
    isSmall = false,

    selectGame,
  } = props;

  const {
    gameOptions,
    rounds,
    selectedRound,
    currentGameTitle,
    onClickRound,
  } = useStatisticMenuState(props);

  return (
    <StatisticMenuStyle.TopMenuBlock isSmall={isSmall}>
      <StatisticMenuStyle.SelectComponent
        options={gameOptions}
        placeholder={currentGameTitle || strings.selectGame}
        onChange={selectGame}
      />

      {!hideRounds && (
        <StatisticsRounds
          rounds={rounds}
          selectedRound={selectedRound}
          onSelectRound={onClickRound}
        />
      )}
    </StatisticMenuStyle.TopMenuBlock>
  );
};
