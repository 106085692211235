import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TGameStatus, IRound, IGame } from '@avid/common';

import { IActiveGameButton } from 'components/active-game-buttons';
import { history } from 'services/history';
import { useReduxSelector, useUpdateState } from 'services/hooks';
import { getGameIdentity, getPassedGameTime } from 'services/utils';
import {
  GAME_ADMIN_ACTIONS,
  PLATFORM_AXIOS_PATHS,
  callGameAdminFunction,
  postPlatformAuth,
} from 'services/api';
import { TActiveGameModal } from 'typings/games';
import { STRINGS } from 'constants/string';
import { ROUTES } from 'constants/routes';

import { MainActions, MainThunk } from '../main';

import { createGameApi } from './active-game.api';
import { stopLastRoundInArray } from './active-game.utils';
import { IGameStateData } from './active-game.typings';

const { ACTIVE_GAME } = STRINGS;

const INITIAL_STATE: IGameStateData = {
  timeElapsed: 0,
  openModal: 'none',
  isLoading: false,
};

export const useGameState = (gameCode: string) => {
  const isGamesFetching = useReduxSelector(
    (redux) => redux.main.isGamesFetching
  );
  const games = useReduxSelector((redux) => redux.main.games);
  const game = games.find((g) => g.config.gameCode === gameCode) as IGame;

  const { config, rounds = [] } = game;
  const { roundDuration, roundsNumber } = config;

  const [isSettings, setIsSettings] = useState(false);

  const { state, updateState } = useUpdateState(INITIAL_STATE);

  const dispatch = useDispatch();

  const beforeLastRoundTimeElapsed = getPassedGameTime(rounds);

  const currentRound = rounds.length;

  const { makeRoundSnapshot } = useMemo(
    () => createGameApi(gameCode),
    [gameCode]
  );

  const updateGames = useCallback(
    (newStatus: TGameStatus, newRounds: IRound[]) => {
      dispatch(
        MainThunk.updateGameAsync({
          gameCode,
          rounds: newRounds,
          status: newStatus,
          timeElapsed: state.timeElapsed,
        })
      );

      if (newStatus === 'finished' && Boolean(game.config.mods?.gameReport)) {
        postPlatformAuth(PLATFORM_AXIOS_PATHS.SEND_GAME_REPORTS, {
          gameCode,
        });
      }
    },
    [gameCode, state.timeElapsed, dispatch]
  );

  const updateElapsedTime = useCallback(
    (interval: number) =>
      updateState({ timeElapsed: beforeLastRoundTimeElapsed + interval }),
    [beforeLastRoundTimeElapsed, updateState]
  );

  const onCloseModal = useCallback(
    () => updateState({ openModal: 'none' }),
    [updateState]
  );

  const onEndGame = useCallback(async () => {
    try {
      updateGames('finished', rounds);
    } catch (error) {
      console.error(error);
    }

    onCloseModal();
  }, [rounds, onCloseModal, updateGames]);

  const startRoundAsync = useCallback(async () => {
    if (state.isLoading) {
      return;
    }

    try {
      updateState({ isLoading: true });

      const { rounds: updatedRounds, status } = await callGameAdminFunction(
        getGameIdentity(gameCode),
        GAME_ADMIN_ACTIONS.SWITCH_ROUND,
        null
      );

      dispatch(
        MainActions.updateGame(gameCode, { rounds: updatedRounds, status })
      );
    } catch (error) {
      console.error(error);
    } finally {
      updateState({ isLoading: false });
    }
  }, [rounds, updateGames]);

  const endRoundAsync = useCallback(async () => {
    if (!rounds || !rounds.length) {
      return;
    }

    const updatedRounds = stopLastRoundInArray(rounds, roundDuration);
    const newGameStatus =
      rounds.length >= +roundsNumber ? 'finished' : 'paused';

    makeRoundSnapshot(currentRound);
    updateGames(newGameStatus, updatedRounds);
  }, [
    rounds,
    roundDuration,
    roundsNumber,
    currentRound,
    makeRoundSnapshot,
    updateGames,
  ]);

  const onOpenModal = useCallback(
    (modal: TActiveGameModal) => () => updateState({ openModal: modal }),
    [updateState]
  );

  const openStopRoundConfirm = useCallback(
    () => updateState({ openModal: 'end-round' }),
    [updateState]
  );

  const confirmEndRound = useCallback(() => {
    endRoundAsync();
    onCloseModal();
  }, [endRoundAsync, onCloseModal]);

  const buttonProps =
    game.status === 'in-progress'
      ? {
          text: ACTIVE_GAME.stopRound,
          onClick: openStopRoundConfirm,
        }
      : { text: ACTIVE_GAME.startRound, onClick: startRoundAsync };

  const onWatchPlayersList = useCallback(
    () => history.push(ROUTES.PLAYERS, gameCode),
    [gameCode]
  );

  const onWatchStatistic = useCallback(
    () => history.push(ROUTES.STATISTIC, gameCode),
    [gameCode]
  );

  const onWatchPlayerHistory = useCallback(
    () => history.push(ROUTES.PLAYERS_HISTORY, gameCode),
    []
  );

  const onChangeSettingsView = useCallback(
    () => setIsSettings((prev) => !prev),
    []
  );

  const buttons: IActiveGameButton[] = useMemo(
    () => [
      {
        icon: 'playersPurple',
        title: 'Player List',
        onClick: onWatchPlayersList,
      },
      {
        icon: 'pieGraphBig',
        title: 'Game Statistics',
        onClick: onWatchStatistic,
      },
      {
        icon: 'suitcase',
        title: 'Change Job Limits',
        onClick: onOpenModal('limits'),
      },
      {
        icon: 'playersHistoryPurple',
        title: 'Players History',
        onClick: onWatchPlayerHistory,
      },
      {
        icon: isSettings ? 'gameSettingsWhite' : 'gameSettingsPurple',
        title: 'Game Settings',
        isActive: isSettings,
        onClick: onChangeSettingsView,
      },
      { icon: 'share', title: 'Share', onClick: onOpenModal('share') },
    ],
    [
      isSettings,
      onChangeSettingsView,
      onOpenModal,
      onWatchPlayerHistory,
      onWatchPlayersList,
      onWatchStatistic,
    ]
  );

  useEffect(() => {
    updateElapsedTime(0);
  }, [updateElapsedTime]);

  return {
    ...state,
    ...game,
    isDisabled: state.isLoading || isGamesFetching,
    isSettings,
    gameCode,
    buttonProps,
    isGamesFetching,
    buttons,

    startRoundAsync,
    endRoundAsync,
    confirmEndRound,
    updateElapsedTime,
    onEndGame,
    onOpenModal,
    onCloseModal,
  };
};
