import styled from 'styled-components';

import { Icon } from 'components/icons';
import { Span } from 'components/typography';

interface IProps {
  isShow: boolean;
}

const Root = styled.div<{ isShow: boolean }>`
  position: absolute;
  top: 28px;
  right: 92px;
  padding: 8px 20px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 12px;
  align-items: center;
  border-radius: 7px;
  background: #fff;
  box-shadow: 1.8px 5.4px 14.4px 0px rgba(45, 45, 55, 0.1);
  transition:
    transform 0.5s,
    opacity 0.5s;

  transform: translateY(${({ isShow }) => (isShow ? 0 : -100)}%);
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
`;

export const WorkLimitsNotification = (props: IProps) => {
  const { isShow } = props;

  return (
    <Root isShow={isShow}>
      <Icon type="checkGreen" />
      <Span typography="RalewayBold20">Sector settings updated!</Span>
    </Root>
  );
};
