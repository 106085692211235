import { Column } from 'react-table';

import { IPlayersHistoryState, IStructuredLog } from './players-history.typing';

export const COLUMNS: Column<IStructuredLog>[] = [
  { Header: 'Email', accessor: 'playerEmail' },
  { Header: 'P-Name', accessor: 'playerName', disableSortBy: true },
  { Header: 'C-Name', accessor: 'characterName', disableSortBy: true },
  { Header: 'Type', accessor: 'type' },
  { Header: 'Action', accessor: 'action' },
  { Header: 'Description', accessor: 'description', disableSortBy: true },
  { Header: 'Money', accessor: 'money', disableSortBy: true },
  { Header: 'Energy', accessor: 'energy', disableSortBy: true },
  { Header: 'Satisfaction', accessor: 'satisfaction', disableSortBy: true },
  { Header: 'BA', accessor: 'playerMoney' },
  { Header: 'Skills', accessor: 'playerSkills' },
  { Header: 'Round', accessor: 'round' },
  { Header: 'Time', accessor: 'roundSecond' },
  {
    Header: 'S-Time',
    accessor: 'created',
    Cell: ({ value }: { value: string }) => {
      return new Date(value).toLocaleTimeString();
    },
  },
];

export const GAUGES_COLUMNS: Column<IStructuredLog>[] = [
  {
    id: 'work',
    Header: 'Work',
    accessor: (row) => row.gauges?.work,
    disableSortBy: true,
  },
  {
    id: 'health',
    Header: 'Health',
    accessor: (row) => row.gauges?.health,
    disableSortBy: true,
  },
  {
    id: 'play',
    Header: 'Play',
    accessor: (row) => row.gauges?.play,
    disableSortBy: true,
  },
  {
    id: 'love',
    Header: 'Love',
    accessor: (row) => row.gauges?.love,
    disableSortBy: true,
  },
];

export const BUTTONS = {
  EXPORT: 'Freeze to Export Data',
  RT: 'Unfreeze to Real-Time',
} as const;

export const STRINGS = {
  TITLE: 'Players History',
} as const;

export const INIT_STATE: IPlayersHistoryState = {
  isDisplayTable: false,
};
