import {useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/icons';
import { COLORS, FONTS, Theme } from 'theme';

interface IProps {
  url: string;
}

const Styles = {
  Wrapper: styled.div`
    width: 546px;
    height: 66px;
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    box-sizing: border-box;
    background-color: rgba(87, 72, 175, 0.05);
  `,
  LinkWrapper: styled.div`
    display: flex;
    overflow: hidden;
  `,
  Url: styled(Theme.Text)`
    margin-left: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  CopyButton: styled(Theme.Text)<{ isCopied: boolean }>`
    margin-left: 19px;
    display: block;
    flex-shrink: 0;
    width: 80px;
    height: 60px;
    border-radius: 10px;
    line-height: 60px;
    font-family: ${FONTS.bold};
    text-align: center;
    color: ${COLORS.darkPurple};
    cursor: pointer;

    ${({ isCopied }) =>
      isCopied &&
      css`
        background-color: ${COLORS.white};
        color: ${COLORS.blackGray};
      `};
  `,
};

export const ShareLink = (props: IProps) => {
  const { url } = props;

  const [isCopied, setIsCopied] = useState(false);

  const onClick = async () => {
    await navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  const text = isCopied ? 'Copied' : 'Copy';

  return (
    <Styles.Wrapper>
      <Styles.LinkWrapper>
        <Icon type="link" width="20px" height="20px" />
        <Styles.Url title={url}>{url}</Styles.Url>
      </Styles.LinkWrapper>

      <Styles.CopyButton isCopied={isCopied} onClick={onClick}>
        {text}
      </Styles.CopyButton>
    </Styles.Wrapper>
  );
};
