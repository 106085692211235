import { Column } from 'react-table';
import firebase from 'firebase/app';
import { IGameMods, logsUtils, TStoredLog } from '@avid/common';

import { IStructuredLog } from './players-history.typing';
import { COLUMNS, GAUGES_COLUMNS } from './players-history.constants';

const { getLogInfo } = logsUtils;

export const formatRoundSecond = (second: number) =>
  new Date(second * 1000).toISOString().substr(14, 5);

export const logToStructured = (log: TStoredLog): IStructuredLog => {
  const {
    action,
    playerMoney,
    round,
    roundSecond,
    type,
    playerEmail,
    playerName,
    playerSkills,
    playerReferrals,
    characterName,

    params: { energy, money, satisfaction, gauges },
    created,
  } = log;

  return {
    playerEmail,
    playerName,
    characterName,
    type,
    action,
    round,
    roundSecond,
    money,
    energy,
    gauges,
    playerMoney,
    playerSkills,
    playerReferrals,
    satisfaction,
    description: getLogInfo(log).description,
    created: (created as firebase.firestore.Timestamp).toDate().toISOString(),
  };
};

export const mapLogsToStructured = (logs: TStoredLog[]) =>
  logs.map((log) => logToStructured(log));

export const getColumns = (gameMods: IGameMods): Column<IStructuredLog>[] => {
  const columns = [...COLUMNS];

  const skillsIndex = columns.findIndex(
    (column) => column.accessor === 'playerSkills'
  );

  if (gameMods.bonusCodes) {
    columns.splice(skillsIndex, 0, {
      Header: 'Referrals',
      accessor: 'playerReferrals',
    });
  }

  if (gameMods.gauges) {
    columns.splice(skillsIndex, 0, ...GAUGES_COLUMNS);
  }

  return columns;
};
