import styled from 'styled-components';

import { Button } from 'components/button';
import { IconButton } from 'components/icon-button';
import { NativeSelect } from 'components/native-select';
import { typographyCss, COLORS, FONT_SIZE } from 'theme';
import { TextInput } from 'components/text-input';

export const PositionLimitsStyles = {
  Root: styled.li`
    margin: 0;
    margin-top: 24px;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    * + * {
      margin-left: 16px;
    }
  `,
  SectorSelect: styled(NativeSelect)`
    ${typographyCss.QuicksandRegular18};
  `,
  JobSelect: styled(NativeSelect)`
    width: 220px;
    flex-shrink: 0;
    ${typographyCss.QuicksandRegular18};
  `,
  CheckButton: styled(Button)`
    width: 120px;
    height: 48px;
  `,
  AmountInfo: styled.i<{ isShow: boolean }>`
    visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
    width: 60px;
    ${typographyCss.QuicksandBold16};
    font-style: normal;
  `,
  RemoveButton: styled(IconButton)`
    width: 31px;
    height: 31px;
    flex-shrink: 0;
  `,
  Tip: styled.b<{ isValid: boolean }>`
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background-color: ${({ isValid }) => (isValid ? COLORS.green : COLORS.red)};
    color: ${COLORS.white};
    font-size: ${FONT_SIZE.desktopMiddle};
    border-radius: 50%;
    flex-shrink: 0;
    cursor: default;
  `,
  Input: styled(TextInput)`
    width: 95px;

    & .input {
      ${typographyCss.QuicksandRegular18};
    }
  `,
};
