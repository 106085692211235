export enum PossessionLevel {
  Budget = 'Budget',
  Standard = 'Standard',
  Luxury = 'Luxury',
}

export type TPossession<T = string[] | number> = Record<PossessionLevel, T>;

export interface IColumnPossessionData<T extends string[] | number> {
  car: TPossession<T>;
  travel: TPossession<T>;
  home: TPossession<T>;
  mobile: TPossession<T>;
}
