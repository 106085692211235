import {HTMLAttributes } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { typographyCss } from 'theme';

type TTextAlign = 'center' | 'left' | 'right' | 'justify';

interface IPropsBase {
  typography?: keyof typeof typographyCss;
  /**Appliable only for H3 */
  textAlign?: TTextAlign;
}

interface IParagpraphProps
  extends IPropsBase,
    HTMLAttributes<HTMLParagraphElement> {}

interface ISpanProps extends IPropsBase, HTMLAttributes<HTMLSpanElement> {}

interface IHeadingProps
  extends IPropsBase,
    HTMLAttributes<HTMLHeadingElement> {}

const PStyled = styled.p<{
  styles: FlattenSimpleInterpolation;
  textAlign?: TTextAlign;
}>`
  margin: 0;
  padding: 0;
  ${({ styles }) => styles};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
`;

const SpanStyled = styled.span<{
  styles: FlattenSimpleInterpolation;
  textAlign?: TTextAlign;
}>`
  ${({ styles }) => styles};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
`;

const H1Styled = styled.h1<{ styles: FlattenSimpleInterpolation }>`
  margin: 0;
  padding: 0;
  ${({ styles }) => styles};
`;

const H2Styled = styled.h2<{ styles: FlattenSimpleInterpolation }>`
  margin: 0;
  padding: 0;
  ${({ styles }) => styles};
`;

const H3Styled = styled.h3<{
  styles: FlattenSimpleInterpolation;
  textAlign?: TTextAlign;
}>`
  margin: 0;
  padding: 0;
  ${({ styles }) => styles};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
`;

export const Paragraph = (props: IParagpraphProps) => {
  const { typography = 'Regular', ...elementProps } = props;

  const typographyStyles = typographyCss[typography];

  return <PStyled styles={typographyStyles} {...elementProps} />;
};

export const Span = (props: ISpanProps) => {
  const { typography = 'Regular', ...elementProps } = props;

  const typographyStyles = typographyCss[typography];

  return <SpanStyled styles={typographyStyles} {...elementProps} />;
};

export const H1 = (props: IHeadingProps) => {
  const { typography = 'Regular', ...elementProps } = props;

  const typographyStyles = typographyCss[typography];

  return <H1Styled styles={typographyStyles} {...elementProps} />;
};

export const H2 = (props: IHeadingProps) => {
  const { typography = 'Regular', ...elementProps } = props;

  const typographyStyles = typographyCss[typography];

  return <H2Styled styles={typographyStyles} {...elementProps} />;
};

export const H3 = (props: IHeadingProps) => {
  const { typography = 'Regular', textAlign, ...elementProps } = props;

  const typographyStyles = typographyCss[typography];

  return (
    <H3Styled
      styles={typographyStyles}
      textAlign={textAlign}
      {...elementProps}
    />
  );
};
