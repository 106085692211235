import styled from 'styled-components';

import { Theme, FONTS, FONT_SIZE } from 'theme';

const { Button } = Theme;

export const EndGameModalStyles = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.span`
    width: 300px;
    font-family: ${FONTS.quicksandBold};
    font-size: ${FONT_SIZE.normal};
  `,
  ButtonsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  `,
  Button: styled(Button)`
    margin: 0 0 20px 0;

    &:last-of-type {
      margin: 0;
    }
  `,
};
