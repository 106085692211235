import styled from 'styled-components';
import { IGameMods, IGaugesGameMod } from '@avid/common';

import { entriesObject } from 'services/object';
import { Theme } from 'theme';

import { GameSettingsItemWrapper } from './game-settings-item';

interface IProps {
  gameMods: IGameMods;
}

interface IGameSettingsModProps {
  label: string;
  modProps?: JSX.Element;
}

const GameModWrapper = styled.div`
  &:not(:first-child) {
    padding-top: 8px;
  }
`;

const GaugesPropsText = styled(Theme.Text)`
  margin-left: 8px;
`;

const gameModNameLabelMap: Record<keyof IGameMods, string> = {
  bonusCodes: 'Bonus Codes',
  gauges: 'Life Dashboard',
  gaugesHard: 'Life Dashboard: Hard',
  popupTips: 'Popup Tips / Tab Tutorials',
  lifeGoals: 'Life Goals',
  charityHobby: 'Charity and Hobby',
  gameReport: 'Email player report (once the game ends)',
  specialJobs: 'Special Jobs',
  marriage: 'Marriage + Children',
  financial: 'Financial Portfolio',
  limitedEducation: 'Restrict PhD',
  limitedHighestJobs: 'Restrict Highest Jobs',
};

const GameSettingsMod = (props: IGameSettingsModProps) => {
  const { label, modProps } = props;

  return (
    <GameModWrapper>
      {label}
      {modProps}
    </GameModWrapper>
  );
};

export const GameSettingsMods = (props: IProps) => {
  const { gameMods } = props;

  const RenderGameMods = entriesObject(gameMods).map(([modName, modProps]) => (
    <GameSettingsMod
      key={modName}
      label={gameModNameLabelMap[modName]}
      modProps={
        modName === 'gauges' ? (
          <GaugesPropsText>
            Deduct every {(modProps as IGaugesGameMod).intervalSeconds} seconds
          </GaugesPropsText>
        ) : undefined
      }
    />
  ));

  return (
    <GameSettingsItemWrapper>
      <dt>Additional Features</dt>
      <dd>{RenderGameMods}</dd>
    </GameSettingsItemWrapper>
  );
};
