"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.insertArticle = exports.replaceForwardSlashAll = exports.capitalize = void 0;
var WORD_ARTICLE_REGEX = /^([aeiou])/i;
var capitalize = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
exports.capitalize = capitalize;
var replaceForwardSlashAll = function (str) { return str.replace("/", "\\"); };
exports.replaceForwardSlashAll = replaceForwardSlashAll;
var insertArticle = function (word) {
    var article = WORD_ARTICLE_REGEX.test(word) ? "an" : "a";
    return article + " " + word;
};
exports.insertArticle = insertArticle;
