import { useState, useMemo } from 'react';
import { useTable } from 'react-table';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { TStoredLog } from '@avid/common';

import { logsRealTimeQuery } from 'services/api';

import { mapLogsToStructured, ITableProps } from 'screens/players-history';
import { useHistoryColumns } from 'services/hooks';

const INIT_TABLE_STATE = {
  hiddenColumns: [
    'playerName',
    'characterName',
    'work',
    'health',
    'play',
    'love',
    'playerReferrals',
  ],
};

export const useRealTimeTable = (props: ITableProps) => {
  const { gameCode } = props;

  const [showAmount, setShowAmount] = useState(10);

  const { columns, isLoading: isColumnsLoading } = useHistoryColumns(gameCode);

  const [logs, isLoading] = useCollectionData(
    logsRealTimeQuery(gameCode, showAmount),
    { idField: 'id' }
  );

  const data = useMemo(
    () => mapLogsToStructured((logs as unknown as TStoredLog[]) || []),
    [logs]
  );

  const {
    rows,
    headerGroups,
    allColumns,
    getTableProps,
    prepareRow,
    getTableBodyProps,
  } = useTable({
    columns,
    data,
    initialState: INIT_TABLE_STATE,
  });

  const onChangeShowAmount = (amount: number) => setShowAmount(amount);

  return {
    isLoading: isLoading || isColumnsLoading,
    showAmount,
    rows,
    headerGroups,
    allColumns,
    prepareRow,
    getTableProps,
    getTableBodyProps,
    onChangeShowAmount,
  };
};
