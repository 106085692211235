import { realtimeDatabase } from 'services/firebase';
import { TDBSectors } from 'typings/games';

interface ISetSectorParams {
  gameCode: string;
  sector: string;
  salary: number;
  limit: number;
}

const refs = {
  sectors(gameCode: string) {
    return realtimeDatabase.ref(`games/${gameCode}/sectors`);
  },

  sector(gameCode: string, sector: string) {
    return this.sectors(gameCode).child(sector);
  },
};

export const workLimitsDB = {
  async getSectors(gameCode: string) {
    const ref = refs.sectors(gameCode);

    const sectorsSnapshot = await ref.get();

    const sectorsValue = sectorsSnapshot.val() as TDBSectors;

    return sectorsValue;
  },

  updateSectorParams(params: ISetSectorParams) {
    const { gameCode, limit, salary, sector } = params;

    const ref = refs.sector(gameCode, sector);

    return ref.update({ limit, salary });
  },
};
