import { ICompany, entriesObject } from '@avid/common';

import { clientFirebase } from '../firebase';
import { ICompanyCsv } from 'typings/constants-uploading.typing';

import { VersionCsvUploader } from './version-csv-uploader';

export class CompaniesCsvUploader extends VersionCsvUploader {
  parseRows(csvRows: ICompanyCsv[]) {
    return csvRows.reduce(
      (acc, row) => {
        const {
          sector,
          company_name,
          negative_1,
          positive_1,
          positive_2,
          bonus,
        } = row;

        if (!acc[sector]) {
          acc[sector] = [];
        }

        const company: ICompany = {
          company_name,
          negative: [negative_1],
          positive: [positive_1, positive_2],
        };

        if (bonus) {
          company.bonus = +bonus;
        }

        acc[sector].push(company);

        return acc;
      },
      {} as Record<string, ICompany[]>
    );
  }

  upload(sectorCompanies: Record<string, ICompany[]>) {
    return Promise.all(
      entriesObject(sectorCompanies).map(([sector, companies]) =>
        clientFirebase.firestore.versions.sectors.setWorkCompanies(
          this.version,
          sector,
          { companies }
        )
      )
    );
  }
}
