

import { Table } from 'components/table';
import { TABLES_TYPES } from 'constants/table';
import { ILifeGoalsStatisticsItem } from 'typings/games';

interface IProps {
  lifeGoalsStatistics: (IStatisticsItem | ILifeGoalsStatisticsItem)[];
}

export const LifeGoalsTable = (props: IProps) => {
  const { lifeGoalsStatistics } = props;

  const items = lifeGoalsStatistics.map((item, index) => {
    if (index === 0) {
      return item;
    }

    return { ...item, value: `${item.value}%` };
  });

  return (
    <Table
      tableData={items}
      title="Life Goals Leaderboard"
      type={TABLES_TYPES.lifeGoal}
      tableWidth="auto"
    />
  );
};
