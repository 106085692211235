export const FONT_SIZE = {
  title: '30px',
  large: '25px',
  normal: '22px',
  regular: '20px',
  desktopMiddle: '18px',
  label: '16px',
  middle: '15px',
  small: '14px',
  tabletSmall: '13px',
  px16: '16px',
  13: '13px',
  18: '18px',
  24: '24px',
  32: '32px',
  48: '48px',
  60: '60px',
  80: '80px',
} as const;
