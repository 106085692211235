import styled from 'styled-components';

import { IComponent } from 'typings';

export interface ITabLayoutProps extends IComponent {
  isCentered?: boolean;
  isFullHeight?: boolean;
}

const Styles = {
  Root: styled.div<{ isCentered?: boolean; isFullHeight?: boolean }>`
    padding: 0 40px 20px 40px;
    width: 100%;
    display: grid;

    ${({ isCentered }) => isCentered && `justify-content: center;`}
    ${({ isFullHeight }) => !isFullHeight && `align-content: start;`}
  `,
};

export const TabLayout = (props: ITabLayoutProps) => {
  const { children, className, isCentered, isFullHeight } = props;

  return (
    <Styles.Root
      className={className}
      isCentered={isCentered}
      isFullHeight={isFullHeight}
    >
      {children}
    </Styles.Root>
  );
};
