"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Storage = void 0;
var super_admin_folder_service_1 = require("./super-admin-folder.service");
var Storage = /** @class */ (function () {
    function Storage(app) {
        this.root = app.storage().ref();
    }
    Storage.prototype.folders = function () {
        return {
            superAdmin: new super_admin_folder_service_1.SuperAdminFolder(this.root),
        };
    };
    return Storage;
}());
exports.Storage = Storage;
