export const OPTIONS = [
  { label: '10', value: '10' },
  { label: '15', value: '15' },
  { label: '20', value: '20' },
] as const;

export const STRINGS = {
  START: 'Show last',
  END: 'logs',
} as const;
