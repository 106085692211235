import { History } from 'history';

import { DEFAULT_INPUT_WITH, Theme } from 'theme';

import { IDS } from 'screens/home/home.constants';
import { NewGameSelector } from './components/version-selector';
import { EDUCATION_SELECTOR_OPTIONS } from './new-game.constants';
import { Grid } from 'components/UI';
import { Icon } from 'components/icons';
import { Span } from 'components/typography';
import { TabLayout } from 'components/tab-layout';

import { DefaultJobLimits, GameMods } from './components';
import { useGameState } from './new-game.state';
import { TNewGameValidateFields } from './new-game.typing';
import { NEW_GAME_STRINGS } from './new-game.strings';
import { NewGameStyle as Styles } from './new-game.style';

interface IPropsNewGame {
  location: any;
  history: History;
}

const {
  buttonSave,
  descriptionGame,
  descriptionNotification,
  durationRound,
  energyGame,
  errorMessage,
  errorTime,
  errorMinMoney,
  gameAccount,
  numberRounds,
  title,
  titleGame,
  MINIMAL_MONEY,
  errorVersion,
} = NEW_GAME_STRINGS;

const { SAVE_GAME } = IDS.BUTTONS;

export const NewGame = (props: IPropsNewGame) => {
  const {
    jobLimits,
    fieldsWrong,
    educationOptions,
    showError,
    isAdminLimited,
    isDurationExceed,
    isLoading,
    selectedEducation,
    gameCodeEditorState,

    config,

    setValue,
    setJobLimit,
    onChangeEducation,
    onSave,
    onChangeVersion,
    onSwitchMod,
    changeGameModConfigCb,
    onSwitchGameCodeEditor,
    onEditGameCode,
  } = useGameState(props.location.state);

  const {
    gameCode,
    gameTitle,
    roundDuration,
    roundsNumber,
    account,
    minimalMoney,
    description,
    energy,
    version,
    mods,
  } = config;

  const ERROR_MESSAGES = {
    gameCode: 'This game code has been used. Please enter another game code.',
    gameTitle: errorMessage,
    description: errorMessage,
    roundsNumber: errorMessage,
    energy: errorMessage,
    roundDuration: errorTime,
    account: errorMessage,
    minimalMoney: errorMinMoney,
    version: errorVersion,
  };

  const getErrorMessage = (
    name: keyof TNewGameValidateFields,
    isIgnoreShowError?: boolean
  ) => {
    const message = ERROR_MESSAGES[name];
    return (isIgnoreShowError ? true : showError) && fieldsWrong[name]
      ? message
      : '';
  };

  const versionOption = version && {
    label:
      EDUCATION_SELECTOR_OPTIONS.find((option) => option.value === version)
        ?.label || '',
    value: version,
  };

  const RenderGameCode = gameCodeEditorState.isEdit ? (
    <Theme.TextInput
      name="gameCode"
      onChange={onEditGameCode}
      value={gameCodeEditorState.gameCode}
      errorMessage={getErrorMessage('gameCode', true)}
    />
  ) : (
    <Span typography="QuicksandBold16">{gameCode}</Span>
  );

  return (
    <TabLayout isCentered>
      <Styles.HeadContainer>
        <Theme.Title>{title}</Theme.Title>
      </Styles.HeadContainer>

      <Styles.Container>
        <Theme.ContainerColumn>
          <Grid rowGap="12px">
            <Grid columns={2} columnGap="10px" alignItems="center">
              <Span typography="QuicksandRegular14">Game Code</Span>
              <Styles.IconEditWrapper onClick={onSwitchGameCodeEditor}>
                <Icon type="penPurple" />
              </Styles.IconEditWrapper>
            </Grid>

            {RenderGameCode}
          </Grid>

          <Styles.MarginWrapper>
            <NewGameSelector
              options={EDUCATION_SELECTOR_OPTIONS}
              label="Game Version"
              placeholder="Select game version"
              selected={versionOption}
              errorMessage={getErrorMessage('version')}
              onChange={onChangeVersion}
            />

            <Styles.TitleDescriptionContainer>
              <Theme.TextInput
                name={'gameTitle'}
                title={titleGame}
                onChange={setValue}
                value={gameTitle}
                errorMessage={getErrorMessage('gameTitle')}
              />
              <Theme.TextArea
                name={'description'}
                title={descriptionGame}
                onChange={setValue}
                notification={descriptionNotification}
                value={description}
                errorMessage={getErrorMessage('description')}
                width={DEFAULT_INPUT_WITH}
              />
            </Styles.TitleDescriptionContainer>

            <NewGameSelector
              options={educationOptions}
              label="Education Settings"
              placeholder="Select Education Settings"
              selected={selectedEducation}
              errorMessage={getErrorMessage('version')}
              onChange={onChangeEducation}
            />
          </Styles.MarginWrapper>
        </Theme.ContainerColumn>
        <Theme.ContainerColumn>
          <Theme.TextInput
            name={'roundsNumber'}
            title={numberRounds}
            onChange={setValue}
            type={'number'}
            value={roundsNumber}
            isError={isDurationExceed}
            errorMessage={getErrorMessage('roundsNumber')}
          />
          <Theme.TextInput
            name={'energy'}
            title={energyGame}
            onChange={setValue}
            type={'number'}
            value={energy}
            errorMessage={getErrorMessage('energy')}
          />
          <Theme.TextInput
            name={'roundDuration'}
            title={durationRound}
            onChange={setValue}
            mask={'99:99:99'}
            value={roundDuration}
            isError={isDurationExceed}
            errorMessage={getErrorMessage('roundDuration')}
          />
          <Theme.TextInput
            name={'account'}
            title={gameAccount}
            onChange={setValue}
            mask={'$9999999'}
            value={account}
            errorMessage={getErrorMessage('account')}
          />
          <Theme.TextInput
            name={'minimalMoney'}
            title={MINIMAL_MONEY}
            onChange={setValue}
            value={minimalMoney}
            mask={'-$9999999'}
            errorMessage={getErrorMessage('minimalMoney')}
          />

          <GameMods
            gameMods={mods}
            onSwitchMod={onSwitchMod}
            changeGameModConfigCb={changeGameModConfigCb}
          />
        </Theme.ContainerColumn>
      </Styles.Container>

      <Styles.DefaultJobLimitsContainer>
        <DefaultJobLimits jobLimits={jobLimits} onChangeLimit={setJobLimit} />
      </Styles.DefaultJobLimitsContainer>

      <Styles.ButtonContainer>
        {isAdminLimited && (
          <Styles.LimitsTip>
            You are not able to create a game due to limits
          </Styles.LimitsTip>
        )}

        <Styles.Button
          id={SAVE_GAME}
          text={buttonSave}
          onClick={onSave}
          isDisabled={isAdminLimited || isLoading}
          isLoading={isLoading}
        />
      </Styles.ButtonContainer>
    </TabLayout>
  );
};
