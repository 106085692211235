import { IGameMods } from '@avid/common';

import { Theme } from 'theme';

import { GameMod } from './game-mod';
import { GaugesModSettings } from './gauges-mod-settings';
import { RoundModSettings } from './round-mod-settings';
import { LimitedPlayersModSettings } from './limited-education-mod-settings';

interface IProps {
  onSwitchMod: (name: keyof IGameMods) => void;
  changeGameModConfigCb: (
    modName: keyof IGameMods
  ) => (prop: string, value: string | number) => void;
  gameMods?: IGameMods;
}

export const GameMods = (props: IProps) => {
  const { gameMods, onSwitchMod, changeGameModConfigCb } = props;

  const isGaugesEnabled = gameMods?.gauges !== undefined;

  return (
    <div>
      <Theme.ControlTitle>Additional Features</Theme.ControlTitle>

      <ul>
        <div>
          <GameMod
            name="bonusCodes"
            title="Bonus Codes"
            isEnabled={Boolean(gameMods?.bonusCodes)}
            onCheck={onSwitchMod}
          />
        </div>

        <div>
          <GameMod
            name="gauges"
            title="Life Dashboard"
            modsDisable={['gaugesHard']}
            isEnabled={isGaugesEnabled}
            onCheck={onSwitchMod}
          />
          {isGaugesEnabled && gameMods?.gauges && (
            <GaugesModSettings
              interval={gameMods.gauges.intervalSeconds}
              onChangeGameModConfig={changeGameModConfigCb('gauges')}
            />
          )}
        </div>

        <div>
          <GameMod
            name="gaugesHard"
            title="Life Dashboard: Hard"
            modsEnable={['gauges']}
            isEnabled={Boolean(gameMods?.gaugesHard)}
            onCheck={onSwitchMod}
          />
        </div>

        <div>
          <GameMod
            name="popupTips"
            title="Popup Tips / Tab Tutorials"
            isEnabled={Boolean(gameMods?.popupTips)}
            onCheck={onSwitchMod}
          />
        </div>

        <div>
          <GameMod
            name="lifeGoals"
            title="Life Goals"
            isEnabled={Boolean(gameMods?.lifeGoals)}
            onCheck={onSwitchMod}
          />
        </div>

        <div>
          <GameMod
            name="charityHobby"
            title="Charity and Hobby"
            isEnabled={Boolean(gameMods?.charityHobby)}
            onCheck={onSwitchMod}
          />
        </div>

        <div>
          <GameMod
            name="gameReport"
            title="Email player report (once the game ends)"
            isEnabled={Boolean(gameMods?.gameReport)}
            onCheck={onSwitchMod}
          />
        </div>

        <div>
          <GameMod
            name="specialJobs"
            title="Special jobs"
            isEnabled={Boolean(gameMods?.specialJobs)}
            onCheck={onSwitchMod}
          />
          {gameMods?.specialJobs && (
            <RoundModSettings
              round={gameMods.specialJobs.round}
              onChangeGameModConfig={changeGameModConfigCb('specialJobs')}
            />
          )}
        </div>

        <div>
          <GameMod
            name="marriage"
            title="Marriage + Children"
            isEnabled={Boolean(gameMods?.marriage)}
            onCheck={onSwitchMod}
          />
          {gameMods?.marriage && (
            <RoundModSettings
              round={gameMods.marriage.round}
              onChangeGameModConfig={changeGameModConfigCb('marriage')}
            />
          )}
        </div>

        <div>
          <GameMod
            name="financial"
            title="Financial Portfolio"
            isEnabled={Boolean(gameMods?.financial)}
            onCheck={onSwitchMod}
          />
        </div>

        <div>
          <GameMod
            name="limitedEducation"
            title="Restrict PhD"
            isEnabled={Boolean(gameMods?.limitedEducation)}
            onCheck={onSwitchMod}
          />
          {gameMods?.limitedEducation && (
            <LimitedPlayersModSettings
              label="PhD"
              ending="for each sector per round"
              maxPlayers={gameMods.limitedEducation.maxPlayers}
              onChangeGameModConfig={changeGameModConfigCb('limitedEducation')}
            />
          )}
        </div>

        <div>
          <GameMod
            name="limitedHighestJobs"
            title="Restrict Highest Jobs"
            isEnabled={Boolean(gameMods?.limitedHighestJobs)}
            onCheck={onSwitchMod}
          />
          {gameMods?.limitedHighestJobs && (
            <LimitedPlayersModSettings
              label="Highest Jobs"
              maxPlayers={gameMods.limitedHighestJobs.maxPlayers}
              onChangeGameModConfig={changeGameModConfigCb(
                'limitedHighestJobs'
              )}
            />
          )}
        </div>
      </ul>
    </div>
  );
};
