import {ChangeEvent } from 'react';

import { JobLimitStyles } from './job-limit.styles';
import { JOB_LIMITS_STRINGS } from './job-limit.strings';

const { INPUT_LABEL, INPUT_NAME } = JOB_LIMITS_STRINGS;

interface IJobLimitProps {
  title: string;
  sector: string;
  onChangeLimit: (sector: string, value: string) => void;
  value?: string | number;
}

export const JobLimit = (props: IJobLimitProps) => {
  const { title, sector, value, onChangeLimit } = props;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeLimit(sector, event.target.value);
  };

  return (
    <JobLimitStyles.Wrapper>
      <JobLimitStyles.Title>{title}</JobLimitStyles.Title>
      <JobLimitStyles.InputLabel>{INPUT_LABEL}</JobLimitStyles.InputLabel>
      <JobLimitStyles.Input
        name={`${INPUT_NAME}-${title}`}
        value={value && value}
        onChange={onChange}
      />
    </JobLimitStyles.Wrapper>
  );
};
