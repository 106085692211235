import { RouteComponentProps, StaticContext } from 'react-router';

import { TabLayout } from 'components/tab-layout';
import { StatisticMenu } from 'components/statistic-menu';
import { StatisticsContent } from 'components/statistics-content';

import { useStatistics } from './statistics.state';
import { GetReviews } from './components';
import styled from 'styled-components';

interface IMatchParams {
  gameCode?: string;
}

const Styles = {
  Tab: styled(TabLayout)`
    height: 100%;
  `,

  AlignWrapper: styled.div`
    display: grid;
    justify-items: center;
    grid-template-rows: auto 1fr auto;
  `,
};

export const Statistics = (
  props: RouteComponentProps<IMatchParams, StaticContext, string>
) => {
  const { match, location } = props;

  const routeGameCode = match.params.gameCode || location.state;

  const {
    isLoading,
    round,
    statistics,
    isSelectedGameFinished,
    gameCode,
    isGamesExist,
    onSelectGame,
    onChangeRound,
  } = useStatistics(routeGameCode);

  const RenderReviewsButton =
    gameCode && isSelectedGameFinished && statistics?.gameVersion === 'ntu' ? (
      <GetReviews gameCode={gameCode} />
    ) : null;

  return (
    <Styles.Tab>
      <Styles.AlignWrapper>
        <StatisticMenu
          currentGameCode={gameCode}
          currentRound={round}
          selectGame={onSelectGame}
          onSelectSnapshot={onChangeRound}
        />

        <StatisticsContent
          isGameExists={isGamesExist}
          isLoading={isLoading}
          gameCode={gameCode}
          statistics={statistics}
          isAllowPrint
        />

        {RenderReviewsButton}
      </Styles.AlignWrapper>
    </Styles.Tab>
  );
};
