import styled from 'styled-components';

import { Icon } from 'components/icons';
import { Selector } from 'components/selector';
import { TextInput } from 'components/text-input';

import { Theme } from 'theme';

const {
  Button,
  ButtonMedium,
  ButtonSmall,
  IconButton,
  BorderedContainer,
  ContainerColumn,
} = Theme;

export const StatisticMenuStyle = {
  TopMenuBlock: styled.div<{ isSmall: boolean }>`
    display: flex;
    margin-top: ${({ isSmall }) => (isSmall ? 0 : '50px')};
    margin-bottom: ${({ isSmall }) => (isSmall ? 0 : '24px')};
    width: 1100px;

    @media print {
      display: none;
    }

    > * + * {
      margin-left: 16px;
    }
  `,

  HeadRow: styled.div`
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    margin-bottom: 16px;
  `,

  Container: styled(BorderedContainer)`
    width: 920px;
    justify-content: space-between;
  `,

  ArrowContainer: styled.div`
    height: 44px;
    width: 95px;
    display: flex;
    align-content: flex-end;
  `,

  SelectComponent: styled(Selector)``,
  TextInput: styled(TextInput)`
    & input {
      height: 44px;
      width: 426px;
    }
  `,

  LeftArrowIcon: styled(Icon)`
    transform: scaleX(-1);
  `,
  ArrowIcon: styled(Icon)``,
  ContainerColumn: styled(ContainerColumn)``,
  Button: styled(Button)``,
  ButtonMedium: styled(ButtonMedium)``,
  ButtonSmall: styled(ButtonSmall)``,
  IconButton: styled(IconButton)``,
};
