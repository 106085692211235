import styled, { css } from 'styled-components';

import { Input } from 'components/UI';
import { COLORS, typographyCss } from 'theme';

export const SectorsSettingsStyles = {
  Root: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3px;

    :nth-child(odd) > div {
      background-color: ${COLORS.blue_gray};
    }

    :nth-child(even) > div {
      background-color: ${COLORS.whiteAntiFlash};
    }
  `,
  Cell: styled.div<{ $isContentStart?: boolean }>`
    display: grid;
    padding: 5px 16px;
  `,
  CellLeft: styled.div`
    display: grid;
    padding: 5px 24px 5px 16px;
    align-content: center;
    text-align: left;
  `,
  Input: styled(Input)<{ isChanged: boolean }>`
    border-radius: 10px;
    text-align: center;

    ${({ isChanged }) =>
      isChanged
        ? css`
            ${typographyCss.QuicksandBold16};
            color: ${COLORS.redSeconday};
          `
        : css`
            ${typographyCss.Regular};
            color: ${COLORS.black};
          `}
  `,
};
