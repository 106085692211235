import styled from 'styled-components';

import { COLORS } from 'theme';
import { Icon } from '../icons';

export const LogoWrapperStyles = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: -webkit-fill-available;
    background: ${COLORS.violet};
    flex: 1;
  `,
  Icon: styled(Icon)`
    width: 225px;
  `,
};
