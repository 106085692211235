import styled from 'styled-components';

import { FONTS, COLORS } from 'theme';

import { Theme } from 'theme';

interface IText {
  isBold?: boolean;
  color?: string;
  ml?: string;
}

const { TextRow } = Theme;

export const ScaleStyles = {
  Container: styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    flex-wrap: wrap;
  `,
  ChainContainer: styled.div`
    margin-top: 25px;
    width: 210px;
    display: flex;
    flex-direction: column;
  `,
  Chain: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
  `,
  InfoContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-self: flex-start;
    height: 74px;
  `,
  Text: styled.p<IText>`
    display: flex;
    font-family: ${(props) =>
      (props.isBold && FONTS.quicksandBold) || FONTS.regular};
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 14px;
    color: ${(props) => props.color};
    margin-left: ${(props) => props.ml};
  `,
  Dot: styled.div`
    border-radius: 100%;
    width: 15.6px;
    height: 16px;
    left: 323px;
    top: 512px;
    background: ${(props) => props.color || COLORS.yellow};
    border: ${(props) =>
      (props.color && `2px solid ${props.color}`) ||
      `2px solid ${COLORS.yellow}`};
  `,
  Line: styled.div`
    margin-top: -11px;
    height: 0px;
    left: 330.8px;
    top: 522px;
    background: ${(props) => props.color || COLORS.yellow};
    min-width: 119px;
    border: ${(props) =>
      (props.color && `1px solid ${props.color}`) ||
      `1px solid ${COLORS.yellow}`};
  `,

  TextRow: styled(TextRow)``,
};
