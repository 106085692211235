import { entriesObject } from 'services/object';

const getNumberFromJobLevel = (level: string) => Number(level.split(' ')[1]); // Level "1"

export const careerTreeBranchToSortedJobs = (branch: Record<string, string>) =>
  entriesObject(branch).sort(
    ([levelCurrent], [levelNext]) =>
      getNumberFromJobLevel(levelCurrent) - getNumberFromJobLevel(levelNext)
  );

export const careerTreeToSortedUniqueJobsMap = (
  careerTree: Record<string, string>[]
) => {
  const sectorJobs = careerTree
    .map((branch) => {
      const sortedJobs = careerTreeBranchToSortedJobs(branch).map(
        ([, job]) => job
      );

      return sortedJobs;
    })
    .flat()
    .filter((job) => !!job);

  const uniqueJobs = [...new Set(sectorJobs)];
  return uniqueJobs;
};
