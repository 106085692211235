import { useRef, useState } from 'react';
import styled from 'styled-components';

import { Paragraph } from 'components/typography';
import { clientFirebase } from 'services/firebase';
import { COLORS } from 'theme';

const Styles = {
  Tip: styled(Paragraph)<{ color?: string }>`
    justify-self: end;
    text-decoration-line: underline;
    cursor: pointer;

    color: ${({ color = COLORS.blackGray }) => color};
  `,

  HiddenAnchor: styled.a`
    position: absolute;
    opacity: 0;
  `,
};

export const Sample = () => {
  const [isLoading, setIsLoading] = useState(false);

  const anchorRef = useRef<HTMLAnchorElement>(null);

  const label = isLoading ? 'Downloading...' : 'Download sample file';

  const onClick = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const url = await clientFirebase.storage
        .folders()
        .superAdmin.files()
        .gamesUploadSample.getDownloadURL();

      if (anchorRef.current) {
        anchorRef.current.href = url;
        anchorRef.current.click();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Styles.Tip
        typography="QuicksandRegular14"
        color={COLORS.blackGray}
        onClick={onClick}
      >
        {label}
      </Styles.Tip>

      <Styles.HiddenAnchor ref={anchorRef} download="games-upload-sample.csv" />
    </>
  );
};
