import styled, { css } from 'styled-components';

import { TableButton } from '../table-button';

export const FiltersStyles = {
  Root: styled.form<{ isShow: boolean; isMounted: boolean }>`
    ${({ isShow }) => !isShow && 'display: none'};
    padding: 20px;
    box-shadow: 2px 6px 16px rgba(45, 45, 55, 0.1);
    transition: max-height 0.3s ease-out;
    max-height: ${({ isMounted }) => (isMounted ? '1000px' : '0')};
  `,
  ButtonContainer: styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: center;
  `,
  Button: styled(TableButton)`
    &:not(:last-child) {
      margin-right: 12px;
    }
  `,
};

export const FilterContainerCss = css`
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
  margin-right: 40px;
  width: 270px;
`;
