import { realtimeDatabase } from './firebase';
import { TDBSectors, IJobSectorData } from 'typings/games';

import { valuesOfObject } from './object';

export const postJobLimits = (gameCode: string, jobLimits?: TDBSectors) => {
  if (!jobLimits) {
    return;
  }
  try {
    return realtimeDatabase.ref(`games/${gameCode}/sectors`).set(jobLimits);
  } catch (error) {
    console.error(error);
  }
};

export const getEmployees = async (gameCode: string) => {
  const snap = await realtimeDatabase.ref(`games/${gameCode}/sectors`).get();

  const sectors = snap.val();
  const sectorValues = valuesOfObject(sectors);

  const result: number =
    sectorValues &&
    sectorValues.reduce(
      (totalEmployees: number, currentSector: IJobSectorData) => {
        return (totalEmployees = currentSector?.players
          ? +(totalEmployees + Object.values(currentSector.players).length)
          : totalEmployees);
      },
      0
    );

  return result;
};
