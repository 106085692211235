import styled, { css } from 'styled-components';

import { Grid } from 'components/UI';

const paddingCss = css`
  padding-left: 32px;
  padding-right: 20px;
`;

export const AdminGameStyles = {
  Root: styled(Grid)`
    justify-content: initial;
    border-radius: 20px;
    overflow: hidden;
  `,
  Header: styled(Grid)<{ backgroundColor: string; color: string }>`
    height: 65px;
    grid-template-columns: 1fr auto;
    align-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
    ${paddingCss}
    color: ${({ color }) => color};
    cursor: pointer;
  `,
  Main: styled(Grid)<{ backgroundColor: string }>`
    position: relative;
    height: 265px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding: 24px;
    ${paddingCss}
    overflow: auto;
  `,
  List: styled.dl`
    display: grid;
    row-gap: 16px;
  `,
};
