import { IGame } from '@avid/common';
import { firebaseFunctions } from 'services/firebase';
import { IUploadGame } from 'typings/upload-games';

const callableFunction = firebaseFunctions.httpsCallable('superAdmin');

export const SUPER_CALLABLE_ACTION_TYPES = {
  UPLOAD_GAMES: '@SUPER_ADMIN/UPLOAD_GAMES',
} as const;

interface IUploadGamesPayload {
  uploadGames: IUploadGame[];
}

interface IUploadGamesData {
  [SUPER_CALLABLE_ACTION_TYPES.UPLOAD_GAMES]: { games: IGame[] };
}

interface ISuperActions {
  [SUPER_CALLABLE_ACTION_TYPES.UPLOAD_GAMES]: {
    payload: IUploadGamesPayload;
    data: IUploadGamesData;
  };
}

export const callSuperAdmin = async <
  T extends typeof SUPER_CALLABLE_ACTION_TYPES[keyof typeof SUPER_CALLABLE_ACTION_TYPES]
>(
  type: T,
  payload: ISuperActions[T]['payload']
): Promise<ISuperActions[T]['data']> => {
  const action = { type, payload };

  const result = await callableFunction({ action });

  return result.data;
};
