import { useLocation } from 'react-router-dom';

import { GameLink } from './game-link';

export const CurrentGameLink = () => {
  const location = useLocation();

  const gameCode = location.pathname.split('/').pop();

  if (!gameCode) {
    return null;
  }

  return <GameLink gameCode={gameCode} />;
};
