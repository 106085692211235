import firebase from 'firebase/app';

import { firebaseApp, db } from './firebase';

export type IUser = firebase.User;

interface IFilterProps {
  key: string;
  operator: any;
  value: string;
}

export const signUp = (email: string, password: string) => {
  return firebaseApp.auth().createUserWithEmailAndPassword(email, password);
};

export const logIn = async (email: string, password: string) => {
  const res = await firebaseApp.auth().signInWithEmailAndPassword(email, password);
  return res.user;
};

export const getUser = async (
  setUser: (user: IUser) => void,
  noUser: () => void
) => {
  return firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
    } else {
      noUser();
    }
  });
};

export const signOut = async () => {
  return firebaseApp.auth().signOut();
};

export const update = async <
  T extends Partial<firebase.firestore.DocumentData>,
>(
  parent: string,
  data: T,
  document?: string
) => {
  try {
    return db.collection(`${parent}`).doc(document).set(data, { merge: true });
  } catch (error) {
    console.error(error);
  }
};

export const post = async <T extends firebase.firestore.DocumentData>(
  parent: string,
  data: T,
  document?: string
) => {
  try {
    return db.collection(parent).doc(document).set(data);
  } catch (error) {
    console.error(error);
  }
};

export const fetchByEqualChild = async (
  parent: string,
  filterProps: IFilterProps
) => {
  const { key, operator, value } = filterProps;
  const querySnapshot = await db
    .collection(`${parent}`)
    .where(key, operator, value)
    .get();
  return querySnapshot.docs.map((doc) => doc.data());
};

export const fetch = async (parent: string, document: string) => {
  return (await db.collection(parent).doc(document).get()).data();
};

export const fetchCollection = async (collection: string) => {
  return db.collection(collection).get();
};

export const fetchSubDocument = async (
  game: string,
  subCollection: string,
  subDocument: string
) => {
  return (
    await db
      .collection('games')
      .doc(game)
      .collection(subCollection)
      .doc(subDocument)
      .get()
  ).data();
};

export const getGameDocuments = (document: string, subCollection: string) =>
  db.collection('games').doc(document).collection(subCollection).get();
