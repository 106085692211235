"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Firestore = void 0;
var cheat_codes_firestore_service_1 = require("./cheat-codes-firestore.service");
var constants_firestore_service_1 = require("./constants-firestore.service");
var versions_firestore_service_1 = require("./versions-firestore.service");
var games_firestore_service_1 = require("./games-firestore.service");
var Firestore = /** @class */ (function () {
    function Firestore(rawFirestore) {
        this.cheatCodes = new cheat_codes_firestore_service_1.CheatCodesFirestore(rawFirestore);
        this.constants = new constants_firestore_service_1.ConstantsFirestore(rawFirestore);
        this.versions = new versions_firestore_service_1.VersionsFirestore(rawFirestore);
        this.games = new games_firestore_service_1.GamesFirestore(rawFirestore);
    }
    return Firestore;
}());
exports.Firestore = Firestore;
