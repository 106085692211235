const MODALS = {
  END_GAME: 'end_game',
} as const;

const BUTTON = 'button';

export const IDS = {
  CONTAINERS: {
    END_GAME_MODAL: 'end_game_modal',
    ROUND: 'round_container',
    ROUND_STATUS: 'round_status',
  },

  PARAGRAPHS: {
    GAME_NAME: `game_name`,
    GAME_CODE: `game_code`,
  },

  BUTTONS: {
    CREATE_GAME: `create_game_${BUTTON}`,
    EMPTY_CREATE_GAME: `empty_create_game_${BUTTON}`,
    SAVE_GAME: `save_game_${BUTTON}`,

    PLAYER_LIST: `player_list_${BUTTON}`,
    GAME_STATISTICS: `game_statistics_${BUTTON}`,
    CHANGE_LIMITS: `change_job_limits_${BUTTON}`,
    PLAYERS_HISTORY: `players_history_${BUTTON}`,
    REFRESH: `refresh_${BUTTON}`,
    END_GAME: `end_game_${BUTTON}`,
    ROUND_BUTTON: `round_${BUTTON}`,

    END_GAME_CONFIRM: `${MODALS.END_GAME}-confirm_${BUTTON}`,
    END_GAME_CANCEL: `${MODALS.END_GAME}-cancel${BUTTON}`,
  },
} as const;
