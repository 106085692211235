import { useCallback, useState } from 'react';

type TUpdateField<O extends {}> = <K extends keyof O>(
  option: K
) => (value: O[K]) => void;

type TUpdateFieldCallback<O extends {}> = <K extends keyof O>(
  option: K
) => (value: O[K]) => () => void;

type TUpdateState<T> = (data: ((s: T) => Partial<T>) | Partial<T>) => void;

export const useUpdateState = <T extends {}>(initState: T) => {
  const [state, setState] = useState<T>(initState);

  const updateState: TUpdateState<T> = useCallback(
    (data) =>
      setState((current) => ({
        ...current,
        ...(typeof data === 'function' ? data(current) : data),
      })),
    []
  );

  const updateField: TUpdateField<T> = (option) => (value) =>
    updateState({ [option]: value } as any);

  const updateFieldCallback: TUpdateFieldCallback<T> = (option) => (
    value
  ) => () => updateState({ [option]: value } as any);

  return { state, updateState, updateField, updateFieldCallback };
};
