import { IGameMods } from '@avid/common';

export const getGaugesModDefaultSettings = (
  roundSeconds: number
): IGameMods['gauges'] => ({ intervalSeconds: roundSeconds / 4 + 1 });

export const getModDefaultSettings = (
  modName: keyof IGameMods,
  roundSeconds: number
) => {
  switch (modName) {
    case 'bonusCodes': {
      return {};
    }
    case 'gauges': {
      return getGaugesModDefaultSettings(roundSeconds);
    }
    case 'popupTips': {
      return {};
    }
    case 'specialJobs': {
      return { round: 2 };
    }

    case 'marriage': {
      return { round: 2 };
    }

    case 'limitedEducation': {
      return { maxPlayers: 1 };
    }

    case 'limitedHighestJobs': {
      return { maxPlayers: 1 };
    }

    default: {
      return {};
    }
  }
};
