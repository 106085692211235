import styled from 'styled-components';

import { COLORS } from 'theme';

export const JobLimitStyles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 33%;
    margin-bottom: 44px;
  `,
  Title: styled.span`
    font-size: 14px;
    font-weight: bold;
    line-height: 17.5px;
    margin-bottom: 8px;
    text-align: start;
  `,
  InputLabel: styled.span`
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    margin-bottom: 4px;
    text-align: start;
  `,
  Input: styled.input`
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    width: 250px;
    height: 46px;
    background-color: ${COLORS.white};
    box-shadow: 2px 6px 16px ${COLORS.box_shadow};
    border-radius: 10px;
    border: none;
    padding-left: 15px;

    &:focus {
      outline: none;
    }
  `,
};
