import styled from 'styled-components';
import { COLORS } from 'theme';

export const LimitsListStyles = {
  Root: styled.dl`
    margin: 0;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 145px;
    row-gap: 10px;
  `,
  Item: styled.div`
    display: flex;
    justify-content: flex-start;
  `,
  Value: styled.dd<{ isExceed: boolean }>`
    margin: 0;
    margin-left: 6px;
    color: ${({ isExceed }) => (isExceed ? COLORS.red : COLORS.violet)};
  `,
};
