import { lazy } from 'react';

import { LazyLoader } from 'components/lazy-loader';

const Element = lazy(() => import('./constants-uploader'));

export const ConstantsUploaderLazy = () => (
  <LazyLoader>
    <Element />
  </LazyLoader>
);
