import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { IReduxState } from 'typings/redux';

import { AdminRoute } from './admin-route';

interface IRoute extends RouteProps {
  component: ComponentType<any>;
  isAdminRoute?: boolean;
}

export const PrivateRoute: React.FC<IRoute> = ({
  component: Component,
  isAdminRoute,
  ...options
}) => {
  const isLoggedIn = useSelector(
    (state: IReduxState) => state.main.currentUser
  );

  const renderRoute = (props: any) => {
    return <Component {...props} />;
  };

  if (isLoggedIn) {
    return isAdminRoute ? (
      <AdminRoute {...options} render={renderRoute} />
    ) : (
      <Route {...options} render={renderRoute} />
    );
  }

  return <Redirect to={{ pathname: ROUTES.LOGIN }} />;
};
