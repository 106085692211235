import { unparse } from 'papaparse';

import { readFileToArray } from 'services/utils';
import { ICsvUploader } from 'typings/constants-uploading.typing';

export class CsvUploading {
  constructor(
    private constantsUploader: ICsvUploader
    // eslint-disable-next-line no-empty-function
  ) {}

  async fromFile<T extends []>(file: File) {
    const csvArray = await readFileToArray(file);

    const formattedCSV = unparse(csvArray);
    const formattedFile = new Blob([formattedCSV], { type: 'text/csv' });

    const beforeData = this.constantsUploader.beforeParseRows
      ? await this.constantsUploader.beforeParseRows?.()
      : undefined;

    const parsed = this.constantsUploader.parseRows(csvArray as T, beforeData);

    await this.constantsUploader.upload(parsed);

    return { formattedFile };
  }
}
