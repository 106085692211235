import { ChangeEventHandler } from 'react';

import {
  IUsePositionLimitsItemProps,
  usePositionLimitsItem,
} from './position-limits-item.state';
import { PositionLimitsStyles as Styles } from './position-limits-item.styles';

interface IProps extends IUsePositionLimitsItemProps {
  sectorOptions: IOption[];
  jobOptions: IOption[];

  onChangeSector: ChangeEventHandler<HTMLSelectElement>;
  onChangeJob: ChangeEventHandler<HTMLSelectElement>;
  onRemove: () => void;
}

export const PositionLimitsItem = (props: IProps) => {
  const {
    sector,
    job,
    limit,
    sectorOptions,
    jobOptions,
    onChangeSector,
    onChangeJob,
    onRemove,
  } = props;

  const { playersAmount, isValid, onChangeLimitField, onClickCheck } =
    usePositionLimitsItem(props);

  const tipSymbol = isValid ? '✓' : '!';
  const title = isValid ? 'Valid limit' : 'Fill all the values';

  return (
    <Styles.Root>
      <Styles.Tip title={title} isValid={isValid}>
        {tipSymbol}
      </Styles.Tip>

      <Styles.SectorSelect
        options={sectorOptions}
        value={sector}
        onChange={onChangeSector}
        placeholder="Sector"
        title={sector}
      />
      <Styles.JobSelect
        options={jobOptions}
        value={job}
        onChange={onChangeJob}
        disabled={!sector}
        placeholder="Job Title"
        title={job}
      />

      <Styles.Input
        value={limit}
        onChange={onChangeLimitField}
        placeholder="Limit"
        disabled={!sector || !job}
      />

      <Styles.CheckButton text="Check Status" onClick={onClickCheck} />

      <Styles.AmountInfo isShow={playersAmount !== undefined}>
        {playersAmount} players
      </Styles.AmountInfo>

      <Styles.RemoveButton icon="bin" onClick={onRemove} />
    </Styles.Root>
  );
};
