
import { ITrainerLimits } from '@avid/common';

import { Loader } from 'components/loader';

import { LimitsBlockStyles as Styles } from './limits-block.styles';
import { LimitsList } from '../limits-list';

interface IProps {
  isLoading: boolean;
  limits?: ITrainerLimits;
  className?: string;
}

export const LimitsBlock = (props: IProps) => {
  const { isLoading, limits, className } = props;

  let RenderLimits: JSX.Element | null = null;

  if (isLoading) {
    RenderLimits = <Loader size="80px" />;
  } else if (limits) {
    RenderLimits = <LimitsList limits={limits} />;
  } else {
    RenderLimits = <Styles.NotFound>Not Found</Styles.NotFound>;
  }

  return (
    <Styles.Root className={className}>
      <Styles.Title>Game Account Settings</Styles.Title>

      {RenderLimits}

      <Styles.Tip>
        If you want to increase your game limit, please upgrade your
        subscription or email jac@witgrifit.com.
      </Styles.Tip>
    </Styles.Root>
  );
};
