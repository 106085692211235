"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.versionsPossessionsLevels = exports.PossessionLevel = exports.possessionItems = void 0;
exports.possessionItems = ["car", "home", "mobile", "travel"];
var PossessionLevel;
(function (PossessionLevel) {
    PossessionLevel["Budget"] = "Budget";
    PossessionLevel["Standard"] = "Standard";
    PossessionLevel["Luxury"] = "Luxury";
})(PossessionLevel || (exports.PossessionLevel = PossessionLevel = {}));
exports.versionsPossessionsLevels = {
    us: {
        car: {
            Budget: "Car",
            Standard: "SUV",
            Luxury: "Truck",
        },
        home: {
            Budget: "Apartment",
            Standard: "House",
            Luxury: "Luxury Home",
        },
    },
};
