import styled from 'styled-components';

import { COLORS } from 'theme';

export const ActiveGamePanelStyles = {
  Root: styled.div`
    background-color: ${COLORS.blue_gray};
    border-radius: 20px;
    border: 1px solid ${COLORS.light_gray};
    padding: 32px;
  `,
  Header: styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
  `,
  MiddleWrapper: styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 140px;
  `,
  SummaryWrapper: styled.div`
    margin-top: 40px;
  `,
  GameProgressWrapper: styled.div`
    margin-top: 32px;
  `,
  RectanglesWrapper: styled.div`
    display: grid;
    width: fit-content;
    row-gap: 30px;
  `,
};
