import { useState } from 'react';
import { useSelector } from 'react-redux';

import { signOut } from 'services/firebase.api';
import { history } from 'services/history';
import { IReduxState } from 'typings/redux';
import { ROUTES } from 'constants/routes';

import { HeaderStyle as Styles } from './header.style';
import { IDS } from './header.constants';

const { USER_EMAIL, USER_PROFILE_ITEM, USER_LOGOUT_ITEM } = IDS;

export const Header = () => {
  const [isShowMenu, setIsShowMenu] = useState(false);

  const currentUser = useSelector(
    (state: IReduxState) => state.main.currentUser
  );

  if (!currentUser) {
    return null;
  }

  const toggleMenu = () => {
    setIsShowMenu((prev) => !prev);
  };

  const onSignOut = () => {
    signOut();
    history.push(ROUTES.START);
  };

  const RenderDropdown = isShowMenu && (
    <Styles.Menu>
      <Styles.MenuItem id={USER_PROFILE_ITEM}>
        <Styles.MenuIcon type="userBlack" />
        Profile
      </Styles.MenuItem>

      <Styles.MenuItem id={USER_LOGOUT_ITEM} onClick={onSignOut}>
        <Styles.MenuIcon type="logOut" />
        Logout
      </Styles.MenuItem>
    </Styles.Menu>
  );

  return (
    <Styles.Root onClick={toggleMenu}>
      <Styles.UserIcon type="user" />

      <Styles.UserName id={USER_EMAIL}>
        {currentUser?.displayName || currentUser?.email}
      </Styles.UserName>

      <Styles.Triangle type="triangle" />

      {RenderDropdown}
    </Styles.Root>
  );
};
