import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import { callableFunctionsService } from 'services/api';
import { getGameIdentity } from 'services/utils';
import { IGameEmailReview } from 'typings/games';

export interface IUseGetReviewsProps {
  gameCode: string;
}

const getFileName = (gameCode: string) => `game-reviews-${gameCode}`;

export const useGetReviews = (props: IUseGetReviewsProps) => {
  const { gameCode } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState<IGameEmailReview[]>();

  const csvLinkRef = useRef(CSVLink) as any;

  const fileName = getFileName(gameCode);

  const onClick = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await callableFunctionsService.getGameReviews(
        getGameIdentity(gameCode)
      );

      setReviews(result.reviews);

      csvLinkRef.current.link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { reviews, isLoading, fileName, csvLinkRef, onClick };
};
