import { CheckBoxStyle as Styles } from './checkbox.style';

interface IProps {
  isChecked: boolean;
  onClick: () => void;
}

export const Checkbox = (props: IProps) => {
  const { isChecked, onClick } = props;

  return (
    <Styles.Root onClick={onClick}>
      <Styles.Icon type="check" isChecked={isChecked} />
    </Styles.Root>
  );
};
