

import { useTablePagination } from './pagination.state';
import { TablePaginationStyles as Styles } from './pagination.styles';

interface ITablePaginationProps {
  pageIndex: number;
  pagesLength: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  previousPage: () => void;
  gotoPage: (pageIndex: number) => void;
  nextPage: () => void;
}

const BUTTONS = {
  PREVIOUS: 'Previous',
  NEXT: 'Next',
};

export const TablePagination = (props: ITablePaginationProps) => {
  const {
    pageIndex,
    canPreviousPage,
    canNextPage,
    pagesLength,
    nextPage,
    previousPage,
    gotoPage,
  } = props;

  const { onChangePageInput } = useTablePagination(gotoPage);

  return (
    <Styles.Root>
      <Styles.PaginationInfo>
        Page{' '}
        <Styles.PageInput
          value={pagesLength > 0 ? pageIndex + 1 : 0}
          onChange={onChangePageInput}
        />{' '}
        of {pagesLength}
      </Styles.PaginationInfo>
      <Styles.ButtonsContainer>
        <Styles.SwitchButton
          onClick={previousPage}
          text={BUTTONS.PREVIOUS}
          isDisabled={!canPreviousPage}
        />
        <Styles.SwitchButton
          onClick={nextPage}
          text={BUTTONS.NEXT}
          isDisabled={!canNextPage}
        />
      </Styles.ButtonsContainer>
    </Styles.Root>
  );
};
