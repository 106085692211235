import styled from 'styled-components';

import { Button } from 'components/button';
import { COLORS } from 'theme';

export const CreateBlockStyles = {
  Root: styled.div<{ isLeft: boolean }>`
    display: flex;
    justify-content: ${({ isLeft }) =>
      isLeft ? 'flex-start' : 'space-between'};
  `,
  Button: styled(Button)`
    margin-top: 24px;
    width: 255px;
    height: 54px;

    :disabled {
      cursor: not-allowed;
      background-color: ${COLORS.gray};
    }
  `,
};
