"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageBase = void 0;
var StorageBase = /** @class */ (function () {
    function StorageBase(parent, path) {
        this.root = parent.child(path);
    }
    return StorageBase;
}());
exports.StorageBase = StorageBase;
