import { ChangeEventHandler } from 'react';

import { SUPER_CALLABLE_ACTION_TYPES, callSuperAdmin } from 'services/api';
import { useUpdateState } from 'services/hooks';

import { gamesUploadUtils } from './games-upload.utils';

export type TGamesUploadStatus = 'init' | 'success' | 'error' | 'loading';

interface IState {
  status: TGamesUploadStatus;
  error?: string;
  file?: File;
}

const INIT_STATE: IState = {
  status: 'init',
};

export const useGameUpload = () => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  const onError = (row: number) => {
    updateState({ status: 'error', error: `Validation error on row ${row}` });

    console.error('row error', row);
  };

  const onChangeFile: ChangeEventHandler<HTMLInputElement> = (ev) => {
    const file = ev.target.files?.[0];

    if (!file) {
      return;
    }

    updateState({ file });
  };

  const onUpload = async () => {
    if (!state.file || state.status === 'loading') {
      return;
    }

    updateState({ status: 'loading', error: undefined });

    const fileText = (await gamesUploadUtils.getFileText(state.file)) as string;

    const uploadGames = gamesUploadUtils.getConfigArrayFromText(
      fileText,
      onError
    );

    if (!uploadGames) {
      return;
    }

    try {
      await callSuperAdmin(SUPER_CALLABLE_ACTION_TYPES.UPLOAD_GAMES, {
        uploadGames,
      });

      updateState({ status: 'success' });
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error';

      updateState({ status: 'error', error: errorMessage });

      console.error(error);
    }
  };

  const isUploadDisabled = !state.file || state.status === 'loading';

  return {
    ...state,
    isUploadDisabled,
    onChangeFile,
    onUpload,
  };
};
