import { memo } from 'react';
import styled from 'styled-components';

import { H3 } from 'components/typography';
import { Loader } from 'components/loader';
import { Button } from 'components/button';
import { PositionLimitsEditor } from 'components/position-limits-editor';
import { FONT_SIZE } from 'theme';

import { WorkLimitsNotification } from '../work-limits-notification';
import { EmploymentInfo } from '../employment-info';
import { SectorsSettings } from '../sectors-settings';

import { useWorkLimits } from './work-limits.state';
import { WorkLimitsStyles as Styles } from './work-limits.styles';

interface IProps {
  gameCode: string;
  onCloseModal: () => void;
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 20px;
  font-size: ${FONT_SIZE[18]};
`;

export const WorkLimits = memo((props: IProps) => {
  const { gameCode, onCloseModal } = props;

  const {
    isLoading,
    isSaving,
    isCanSave,
    isShowNotification,
    sectorSettings,
    sectorJobsMap,
    positionLimitsEditorHook,
    selectedLimits,
    selectedSalaries,
    isAllLimitsSelected,
    isAllSalariesSelected,
    isDisabledLimitsApply,
    isDisabledSalariesApply,
    onUndraggableMouseDown,
    onApplyItems,
    changeItemCallback,
    selectItem,
    toggleAllItems,
    save,
  } = useWorkLimits(gameCode);

  const buttonLabel = isSaving ? 'Saving...' : 'Save';

  const RenderSectorsSettings =
    isLoading || !sectorSettings ? (
      <Loader />
    ) : (
      <SectorsSettings
        sectorSettings={sectorSettings}
        selectedLimits={selectedLimits}
        selectedSalaries={selectedSalaries}
        isAllLimitsSelected={isAllLimitsSelected}
        isAllSalariesSelected={isAllSalariesSelected}
        isDisabledLimitsApply={isDisabledLimitsApply}
        isDisabledSalariesApply={isDisabledSalariesApply}
        onApplyItems={onApplyItems}
        changeItemCallback={changeItemCallback}
        onSelectItem={selectItem}
        onToggleAllItems={toggleAllItems}
      />
    );

  const RenderPositionsLimitsEditor = sectorJobsMap && (
    <PositionLimitsEditor
      gameCode={gameCode}
      sectorPositionsMap={sectorJobsMap}
      {...positionLimitsEditorHook}
    />
  );

  return (
    <>
      <WorkLimitsNotification isShow={isShowNotification} />

      <Root>
        <H3 typography="RalewayBold24" textAlign="left">
          Job Limits
        </H3>

        <Styles.ScrollWrapper onMouseDown={onUndraggableMouseDown}>
          <Styles.EmploymentInfoWrapper>
            <EmploymentInfo gameCode={gameCode} />
          </Styles.EmploymentInfoWrapper>

          <Styles.SectorsSettingsWrapper>
            {RenderSectorsSettings}
          </Styles.SectorsSettingsWrapper>

          <Styles.PositionLimitsWrapper>
            {RenderPositionsLimitsEditor}
          </Styles.PositionLimitsWrapper>
        </Styles.ScrollWrapper>

        <Styles.ButtonsLine>
          <Styles.ButtonsWrapper onMouseDown={onUndraggableMouseDown}>
            <Button isLightTheme onClick={onCloseModal}>
              Cancel
            </Button>

            <Button isDisabled={isSaving || !isCanSave} onClick={save}>
              {buttonLabel}
            </Button>
          </Styles.ButtonsWrapper>
        </Styles.ButtonsLine>
      </Root>
    </>
  );
});
