

import { Table } from 'components/table';
import { Theme } from 'theme';
import { STRINGS } from 'constants/string';
import { TABLES_TYPES } from 'constants/table';
import { IStatisticsData } from 'typings/games';
import { IColumnPossessionData, TPossession } from 'typings';

import {
  Boxes,
  LifeGoalsTable,
  PieChart,
  PossessionHistogram,
} from './components';
import { PanelStyles as Styles } from './statistics-panel.styles';
import { roundLocaleNumber } from 'services/utils';

interface IProps {
  gameCode: string;
  statistics: IStatisticsData;
}

const { gameCode } = STRINGS.ACTIVE_GAME;
const strings = STRINGS.STATISTIC;

const DEFAULT_POSSESSION_ITEM: TPossession<number> = {
  Budget: 0,
  Standard: 0,
  Luxury: 0,
};

export const DEFAULT_POSSESSION: IColumnPossessionData<number> = {
  car: DEFAULT_POSSESSION_ITEM,
  home: DEFAULT_POSSESSION_ITEM,
  mobile: DEFAULT_POSSESSION_ITEM,
  travel: DEFAULT_POSSESSION_ITEM,
};

export const StatisticsPanel = (props: IProps) => {
  const { gameCode: code, statistics } = props;

  const {
    happiest,
    possession,
    topEducated,
    topSkills,
    charities,
    hobbies,
    workStatistics: {
      highestPositions,
      employmentPercentages,
      sectorSalary,
      earners,
      workers,
      totalPlayers,
    },
    lifeGoals,
  } = statistics;

  const sectorSalaryFormatted = sectorSalary
    .sort((first, second) => +second.value - +first.value)
    .map(({ name, value }) => ({
      name,
      value: isNaN(+value) ? value : roundLocaleNumber(+value),
    }));

  const RenderLifeGoals = lifeGoals ? (
    <LifeGoalsTable lifeGoalsStatistics={lifeGoals} />
  ) : null;

  const RenderCharities = charities && (
    <Table
      tableData={charities}
      title="Top 5 Charity Activities"
      type={TABLES_TYPES.default}
      tableWidth="auto"
    />
  );

  const RenderHobby = hobbies && (
    <Table
      tableData={hobbies}
      title="Top 5 Hobby Activities"
      type={TABLES_TYPES.default}
      tableWidth="auto"
    />
  );

  return (
    <Styles.MainContainer>
      <Styles.BoxContainer>
        <Styles.TitleContainer>
          <Styles.Title>{strings.currentStatusBoxes}</Styles.Title>
          <Styles.GameCode>{`${gameCode} ${code}`}</Styles.GameCode>
        </Styles.TitleContainer>

        <Theme.TextRow>
          <Boxes title={strings.employee} value={workers} />
          <Boxes title={strings.nonEmployee} value={totalPlayers - workers} />
          <Boxes title={strings.total} value={totalPlayers} />
        </Theme.TextRow>
      </Styles.BoxContainer>

      <Styles.TopRow>
        <Styles.ContentBox padding="20px 0 10px 0">
          <Styles.BoxText>{strings.employeesDistribution}</Styles.BoxText>
          <PieChart pieData={employmentPercentages} />
        </Styles.ContentBox>

        <Table
          tableData={sectorSalaryFormatted}
          title={strings.averageSalaryTitle}
          tableHeight="344px"
          type={TABLES_TYPES.default}
        />
      </Styles.TopRow>

      <Styles.MarginWrapper>
        <Styles.SubTitle>{strings.leaderBoards}</Styles.SubTitle>

        <Styles.LeaderboardsWrapper>
          <Table
            tableData={happiest}
            title={strings.happiest}
            isLimitedList={true}
            type={TABLES_TYPES.icon}
            tableWidth="auto"
          />
          <Table
            tableData={earners}
            title={strings.earners}
            type={TABLES_TYPES.icon}
            tableWidth="auto"
          />

          <Table
            tableData={highestPositions}
            title={strings.highestPositions}
            isLimitedList={true}
            type={TABLES_TYPES.icon}
            tableWidth="auto"
          />
          <Table
            tableData={topEducated}
            title={strings.educated}
            isLimitedList={true}
            type={TABLES_TYPES.default}
            tableWidth="auto"
          />

          {RenderLifeGoals}

          <Table
            tableData={topSkills}
            title={strings.popularSkills}
            type={TABLES_TYPES.default}
            tableWidth="auto"
          />
          {RenderCharities}
          {RenderHobby}
        </Styles.LeaderboardsWrapper>
      </Styles.MarginWrapper>

      <Styles.MarginWrapper>
        <PossessionHistogram
          columnPossession={possession ?? DEFAULT_POSSESSION}
          version={statistics.gameVersion || 'standard'}
        />
      </Styles.MarginWrapper>
    </Styles.MainContainer>
  );
};
