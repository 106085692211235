import { clientFirebase } from '../firebase';
import {
  ICsvUploader,
  ITutorialVideo,
} from 'typings/constants-uploading.typing';

export class TutorialVideoCsvUploader implements ICsvUploader {
  parseRows(csvRows: ITutorialVideo[]) {
    return csvRows;
  }

  upload(tutorialVideos: ITutorialVideo[]) {
    return clientFirebase.firestore.constants.updateGameConstants({
      tutorialVideo: tutorialVideos,
    });
  }
}
