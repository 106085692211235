import styled from 'styled-components';

import { FONT_SIZE } from 'theme/font-size';
import { FONTS } from 'theme/fonts';
import { COLORS } from 'theme/colors';

export const TextAreaStyle = {
  Label: styled.label``,
  Input: styled.textarea`
    background: ${COLORS.white};
    box-shadow: 2px 6px 16px rgba(45, 45, 55, 0.1);
    border-radius: 10px;
    width: 100%;
    height: 144px;
    box-sizing: border-box;
    border: none;
    padding-left: 16px;
    padding-top: 16px;
    line-height: 20px;
    font-size: ${FONT_SIZE.label};
    font-family: ${FONTS.semiBold};
    resize: none;
    &::-webkit-input-placeholder {
      color: ${COLORS.black};
      font-size: ${FONT_SIZE.label};
      font-family: ${FONTS.regular};
    }
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
    &:focus {
      outline: none;
    }
  `,
  Title: styled.p`
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;
    font-family: ${FONTS.regular};
  `,
  Notification: styled.p`
    margin-top: 5px;
    font-size: 12px;
    font-family: ${FONTS.regular};
  `,
  ErrorMessage: styled.p`
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;
    font-family: ${FONTS.regular};
    color: ${COLORS.red};
  `,
};
