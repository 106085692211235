"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PossessionsUtils = void 0;
var possessions_1 = require("./possessions");
var PossessionsUtils = /** @class */ (function () {
    function PossessionsUtils() {
    }
    PossessionsUtils.getVersionPossessionLevels = function (params) {
        var version = params.version, possession = params.possession;
        var versionPossessions = possessions_1.versionsPossessionsLevels[version];
        if (versionPossessions) {
            return versionPossessions[possession];
        }
    };
    PossessionsUtils.getVersionPossessionLevelLabel = function (params) {
        var version = params.version, possession = params.possession, level = params.level;
        var possessionLevels = PossessionsUtils.getVersionPossessionLevels({
            version: version,
            possession: possession,
        });
        if (possessionLevels) {
            return possessionLevels[level];
        }
    };
    return PossessionsUtils;
}());
exports.PossessionsUtils = PossessionsUtils;
