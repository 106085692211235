import { ChangeEventHandler, useMemo, useState } from 'react';

import { callableFunctionsService } from 'services/api';
import { MAX_GAME_PLAYERS } from 'constants/validation';
import { getGameIdentity } from 'services/utils';

export interface IUsePositionLimitsItemProps {
  gameCode: string;
  sector: string;
  job: string;
  setLimit: (limit: number) => void;
  limit?: number;
}

export const usePositionLimitsItem = (props: IUsePositionLimitsItemProps) => {
  const { gameCode, sector, job, limit, setLimit } = props;

  const [playersAmount, setPlayersAmount] = useState<number>();

  const onChangeLimitField: ChangeEventHandler<HTMLInputElement> = (ev) => {
    const value = Number(ev.target.value.replace(/[^0-9]/g, ''));

    if (value > MAX_GAME_PLAYERS) {
      return;
    }

    setLimit(value);
  };

  const onClickCheck = async () => {
    const players = await callableFunctionsService.getPlayersOfPosition({
      gameIdentity: getGameIdentity(gameCode),
      sector,
      job,
    });

    setPlayersAmount(players);
  };

  const isValid = useMemo(
    () => !!sector && !!job && limit !== undefined && !isNaN(limit),
    [sector, job, limit]
  );

  return {
    playersAmount,
    isValid,
    onChangeLimitField,
    onClickCheck,
  };
};
