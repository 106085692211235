import {ChangeEventHandler } from 'react';

import { Theme } from 'theme';

interface IProps {
  round: number;
  onChangeGameModConfig: (prop: string, value: string | number) => void;
}

export const RoundModSettings = (props: IProps) => {
  const { round, onChangeGameModConfig } = props;

  const onChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    onChangeGameModConfig('round', +ev.target.value);
  };

  return (
    <Theme.Paragraph>
      Apply on <input type="number" value={round} onChange={onChange} /> round
    </Theme.Paragraph>
  );
};
