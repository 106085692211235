import { TGameVersion } from '@avid/common';

import { ICsvUploader } from 'typings/constants-uploading.typing';

export abstract class VersionCsvUploader implements ICsvUploader {
  protected version: TGameVersion;

  constructor(version: TGameVersion) {
    this.version = version;
  }

  beforeParseRows?<TBeforeData>(): Promise<TBeforeData>;
  beforeParseRows?<TBeforeData>(): Promise<TBeforeData>;

  abstract parseRows<TCsvRow, TData, TBeforeData = undefined>(
    parsedRows: TCsvRow[],
    beforeData?: TBeforeData
  ): TData;
  abstract parseRows<TCsvRow, TData, TBeforeData = undefined>(
    parsedRows: TCsvRow[],
    beforeData?: TBeforeData
  ): TData;

  abstract upload<TData, TPromise>(data: TData): Promise<TPromise>;
  abstract upload<TData, TPromise>(data: TData): Promise<TPromise>;
}
