import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGame } from '@avid/common';

import { fetchByEqualChild, getUser, IUser } from 'services/firebase.api';
import { useUpdateState } from 'services/hooks';
import { IReduxState } from 'typings/redux';

import { MainActions } from './main.actions';

export interface IRounds {
  duration: number;
  startTime: number;
  status: string;
}

interface IMainStateData {
  isUser: boolean;
  isLoading: boolean;
}

export const useMainState = () => {
  const dispatch = useDispatch();
  const { currentUserId } = useSelector((state: IReduxState) => ({
    currentUserId: state.main.currentUser,
  }));

  const { state, updateField } = useUpdateState<IMainStateData>({
    isLoading: true,
    isUser: !!currentUserId,
  });

  const getAdminGames = async (uid: string) => {
    const filterParams = { key: 'ownerId', operator: '==', value: uid };
    const items = (await fetchByEqualChild('games', filterParams)) as IGame[];

    if (!items) {
      return;
    }

    const sortGames = Object.keys(items)
      .map((game: string) => items[+game])
      .sort((first, second) => first.status.localeCompare(second.status));

    dispatch(MainActions.setUserGames(sortGames));
    updateField('isLoading')(false);
  };

  const setCurrentUser = (user: IUser) => {
    updateField('isUser')(!!user);
    dispatch(MainActions.getUserId(user));
    getAdminGames(user.uid);
  };

  const noUser = () => {
    updateField('isLoading')(false);
  };

  useEffect(() => {
    if (state.isUser) {
      updateField('isLoading')(false);
      getAdminGames(`${currentUserId}`);
    }
    getUser(setCurrentUser, noUser);
  }, []);

  return {
    isLoading: state.isLoading,
  };
};
