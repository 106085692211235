export const BUTTONS = {
  CONFIRM: 'Confirm',
  LOADING: 'Loading...',
} as const;

export const STRINGS = {
  CLICK_CONFIRM: 'Click confirm to start downloading the csv export.',
  LOADING: 'Loading. Please, do not close this window.',
  ERROR: 'An error has ocurred. Please try again later.',
} as const;
