import styled from 'styled-components';

export const WorkLimitsStyles = {
  ScrollWrapper: styled.div`
    overflow-y: auto;
    cursor: default;
  `,
  EmploymentInfoWrapper: styled.div`
    width: fit-content;
  `,
  SectorsSettingsWrapper: styled.div`
    margin-top: 40px;
  `,
  ButtonsLine: styled.div`
    display: grid;
    justify-items: end;
  `,
  PositionLimitsWrapper: styled.div`
    margin-top: 36px;
  `,
  ButtonsWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 16px;
    cursor: default;
  `,
};
