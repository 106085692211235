import {ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { Icon, TIconNames } from './icons';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: TIconNames;
}

const StyledButton = styled.button`
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
`;

const StyledIcon = styled(Icon)`
  width: 100%;
  height: 100%;
`;

export const IconButton = (props: IProps) => {
  const { icon } = props;

  return (
    <StyledButton {...props}>
      <StyledIcon type={icon} />
    </StyledButton>
  );
};
