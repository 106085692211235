import {
  ICsvUploader,
  ITitleDescriptionCsv,
} from 'typings/constants-uploading.typing';
import { clientFirebase } from '../firebase';

export class CareerAnchorsCsvUploader implements ICsvUploader {
  parseRows(csvRows: ITitleDescriptionCsv[]) {
    return csvRows;
  }

  upload(careeerAnchors: ITitleDescriptionCsv[]) {
    return clientFirebase.firestore.constants.updateCreateCharacterConstants({
      anchors: careeerAnchors,
    });
  }
}
