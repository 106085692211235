import z from 'zod';
import {
  IGameConfig,
  GAME_VERSIONS,
  TGameVersion,
  TGameModName,
  IGameMods,
} from '@avid/common';

import { keyofObject } from 'services/object';
import { getModDefaultSettings } from 'services/utils';
import { IUploadGame } from 'typings/upload-games';

import {
  DEFAULT_VALUES,
  MOD_CONFIG_PARAM_MAP,
  VALIDATION_RULES,
  MOD_ENABLED_VALUE,
  FLAG_MOD_MAP,
  CHECK_FIELDS,
} from './game-upload.constants';
import { TValidationField } from './games-upload.typing';

const getModConfig = (modName: TGameModName, rowObject: any) => {
  const roundSeconds = +(
    rowObject.roundDuration || DEFAULT_VALUES.roundDuration
  );

  let config = getModDefaultSettings(modName, roundSeconds);

  switch (modName) {
    case 'gauges': {
      const intervalSeconds = rowObject[MOD_CONFIG_PARAM_MAP.gauges];

      if (intervalSeconds) {
        config = { intervalSeconds };
      }

      break;
    }

    case 'specialJobs':
    case 'marriage': {
      const round = +rowObject[MOD_CONFIG_PARAM_MAP[modName]];

      if (round && !isNaN(round)) {
        config = { round };
      }

      break;
    }

    case 'limitedEducation':
    case 'limitedHighestJobs': {
      const maxPlayers = +rowObject[MOD_CONFIG_PARAM_MAP[modName]];

      if (maxPlayers && !isNaN(maxPlayers)) {
        config = { maxPlayers };
      }

      break;
    }

    default: {
      break;
    }
  }

  return { ...config };
};

const validateField = (field: TValidationField, value: string | number) => {
  if (
    value === undefined &&
    keyofObject(DEFAULT_VALUES).includes(field as any)
  ) {
    return DEFAULT_VALUES[field as keyof typeof DEFAULT_VALUES];
  }

  switch (field) {
    case 'ownerEmail': {
      const schema = z.coerce.string().email();

      schema.parse(value);

      break;
    }

    case 'gameCode': {
      const schema = z.coerce
        .number()
        .min(VALIDATION_RULES.GAME_CODE_MIN)
        .max(VALIDATION_RULES.GAME_CODE_MAX);

      schema.parse(value);

      break;
    }

    case 'roundsNumber': {
      const schema = z.coerce.number().max(VALIDATION_RULES.ROUNDS_MAX);

      schema.parse(value);

      break;
    }

    case 'roundDuration': {
      const schema = z.coerce.number();

      schema.parse(value);

      break;
    }

    case 'account': {
      const schema = z.coerce
        .number()
        .min(VALIDATION_RULES.STAT_MIN)
        .max(VALIDATION_RULES.STAT_MAX);

      schema.parse(value);

      break;
    }

    case 'energy': {
      const schema = z.coerce
        .number()
        .min(VALIDATION_RULES.STAT_MIN)
        .max(VALIDATION_RULES.STAT_MAX);

      schema.parse(value);

      break;
    }

    case 'version': {
      if (!(GAME_VERSIONS as (string | number)[]).includes(value)) {
        throw new Error();
      }

      break;
    }

    case 'minimalMoney': {
      const schema = z.coerce
        .number()
        .min(VALIDATION_RULES.STAT_MIN)
        .max(VALIDATION_RULES.STAT_MAX);

      schema.parse(value);

      break;
    }

    case 'startEducationOrder': {
      const schema = z.coerce
        .number()
        .min(VALIDATION_RULES.START_EDUCATION_ORDER_MIN)
        .max(VALIDATION_RULES.START_EDUCATION_ORDER_MAX);

      schema.parse(value);

      break;
    }

    default: {
      break;
    }
  }

  return value;
};

const isModEnabled = (value: string) => value === MOD_ENABLED_VALUE;

const createGameMods = (rowObject: any) => {
  const mods = CHECK_FIELDS.reduce<IGameMods>((gameMods, flag) => {
    if (!isModEnabled(rowObject[flag])) {
      return gameMods;
    }

    const modName = FLAG_MOD_MAP[flag];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    gameMods[modName] = getModConfig(modName, rowObject);

    return gameMods;
  }, {});

  return mods;
};

export const gamesUploadUtils = {
  validateAndGetRowConfig(rowObject: any, row: number) {
    try {
      if (!rowObject.ownerEmail) {
        throw new Error();
      }

      const mods = createGameMods(rowObject);

      const config: IGameConfig = {
        description: rowObject.description || '',
        gameTitle: rowObject.gameTitle,

        account: validateField('account', rowObject.account).toString(),
        energy: validateField('energy', rowObject.energy).toString(),
        gameCode: validateField('gameCode', rowObject.gameCode).toString(),
        minimalMoney: validateField(
          'minimalMoney',
          rowObject.minimalMoney
        ).toString(),
        roundsNumber: validateField(
          'roundsNumber',
          rowObject.roundsNumber
        ).toString(),
        version: validateField(
          'version',
          rowObject.version
        )?.toString() as TGameVersion,

        roundDuration: +validateField('roundDuration', rowObject.roundDuration),
        startEducationOrder: +(
          validateField('startEducationOrder', rowObject.startEducationOrder) ||
          0
        ),
        mods,

        date: 0,
      };

      return { config, ownerEmail: rowObject.ownerEmail };
    } catch (error) {
      throw new Error(row.toString());
    }
  },

  getFileText(file: File) {
    const fileReader = new FileReader();

    const promise = new Promise<string | ArrayBuffer>((resolve, reject) => {
      fileReader.onload = (ev) => {
        const result = ev.target?.result;

        if (!result) {
          reject();
          return;
        }

        resolve(result);
      };
    });

    fileReader.readAsText(file);

    return promise;
  },

  getConfigArrayFromText(text: string, onError: (row: number) => void) {
    const csvHeader = text.slice(0, text.indexOf('\r\n')).split(',');
    const csvRows = text.slice(text.indexOf('\n') + 1).split('\r\n');

    try {
      const array: IUploadGame[] = csvRows
        .filter((csvRow) => csvRow.length >= 2)
        .map((csvRow, rowIndex) => {
          const values = csvRow.split(',');

          const obj = csvHeader.reduce<any>((object, header, index) => {
            object[header] = values[index];
            return object;
          }, {});

          const uploadGame = this.validateAndGetRowConfig(obj, rowIndex + 2);

          return uploadGame;
        });

      return array;
    } catch (error) {
      onError(+(error as any).message);
    }
  },
};
