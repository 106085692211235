import { IGame, IRound, TGameStatus } from '@avid/common';

export interface IUpdateParams {
  status: TGameStatus;
  rounds: IRound[];
  timeElapsed: number;
}

interface IUpdateGamesArrayParams {
  updateParams: IUpdateParams;
  games: IGame[];
  gameIndex: number;
}

export const updateGamesArray = (params: IUpdateGamesArrayParams) => {
  const { gameIndex, games, updateParams } = params;
  const { rounds, status, timeElapsed } = updateParams;

  const gamesCopy = [...games];

  if (gameIndex === -1) {
    return gamesCopy;
  }

  const gameCopy = { ...games[gameIndex] };

  gameCopy.rounds = rounds;
  gameCopy.status = status;
  gameCopy.timeElapsed = timeElapsed;

  gamesCopy[gameIndex] = gameCopy;

  return gamesCopy;
};
