import { Theme } from 'theme';

import { FilterStyles as Styles, TLabelVariant } from './filter.styles';

interface IFilterProps {
  id: string;
  label: string;
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  variant?: TLabelVariant;
  inputType?: string;
}

export const Filter = (props: IFilterProps) => {
  const { label, id, value, inputType, variant = 'default', onChange } = props;

  return (
    <Styles.Label variant={variant}>
      <Theme.SubTitle>{label}</Theme.SubTitle>
      <Styles.ControlContainer>
        <Styles.Input
          type={inputType}
          name={id}
          value={value}
          onChange={onChange}
        />
      </Styles.ControlContainer>
    </Styles.Label>
  );
};
