import { ISkillInfo } from '@avid/common';

import { ISkillCsv } from 'typings/constants-uploading.typing';
import { clientFirebase } from '../firebase';

import { VersionCsvUploader } from './version-csv-uploader';

export class AllSkillsCsvUploader extends VersionCsvUploader {
  parseRows(parsedRows: ISkillCsv[]) {
    return parsedRows.map((row) => ({
      ...row,
      smart: row.smart.toLocaleLowerCase().split('-')[0],
    }));
  }

  upload(skills: ISkillInfo[]) {
    return clientFirebase.firestore.versions.updateAllSkills(this.version, {
      skills,
    });
  }
}
