import { memo } from 'react';

import { JobLimit } from 'components/job-limit';
import { entriesObject } from 'services/object';
import { TDBSectors } from 'typings/games';

import { DefaultJobLimitsStyles } from './default-job-limits.styles';

interface IDefaultJobLimitsProps {
  onChangeLimit: (sector: string, limit: string | number) => void;
  jobLimits?: TDBSectors;
}

export const DefaultJobLimits = memo((props: IDefaultJobLimitsProps) => {
  const { jobLimits, onChangeLimit } = props;

  if (!jobLimits) {
    return null;
  }

  return (
    <DefaultJobLimitsStyles.JobLimitsContainer>
      {entriesObject(jobLimits).map(([sector, sectorData]) => (
        <JobLimit
          title={String(sector)}
          sector={String(sector)}
          value={sectorData.limit}
          onChangeLimit={onChangeLimit}
          key={sector}
        />
      ))}
    </DefaultJobLimitsStyles.JobLimitsContainer>
  );
});
