import styled from 'styled-components';

export const HidingStyles = {
  Root: styled.div`
    margin-top: 16px;
  `,
  Label: styled.label`
    &:not(:first-child) {
      margin-left: 8px;
    }
  `,
};
