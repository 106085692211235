import { Selector } from 'components/selector';

import { AmountSelectStyles as Styles } from './amount-select.styles';
import { IAmountSelectProps } from './amount-select.typing';
import { OPTIONS, STRINGS } from './amount-select.constants';

const { START, END } = STRINGS;

export const AmountSelect = (props: IAmountSelectProps) => {
  const { value, onChangeAmount } = props;

  const onChange = (option: any) => {
    const newAmount = Number((option as IOption).value);

    if (isNaN(newAmount)) {
      return;
    }

    onChangeAmount(newAmount);
  };

  return (
    <Styles.Root>
      {START}{' '}
      <Styles.SelectContainer>
        <Selector
          containerWidth="72px"
          value={{ value: value, label: value }}
          options={OPTIONS}
          onChange={onChange}
        />
      </Styles.SelectContainer>{' '}
      {END}
    </Styles.Root>
  );
};
