import styled from 'styled-components';

import { TabLayout } from 'components/tab-layout';
import { FONTS } from 'theme';

import { AdminGames, HomeEmpty } from './components';

import { useHomeState } from './home.state';

const Styles = {
  Tab: styled(TabLayout)`
    justify-content: normal;
    font-family: ${FONTS.regular};
  `,
};

export const Home = () => {
  const { games, gameCreate } = useHomeState();

  const RenderElement = games.length ? (
    <AdminGames />
  ) : (
    <HomeEmpty gameCreate={gameCreate} isLoading={false} />
  );

  return <Styles.Tab>{RenderElement}</Styles.Tab>;
};
