import { TGameVersion } from '@avid/common';

import { fetchJobSectorsAsync } from 'services/api';
import { TDBSectors, IJobSector } from 'typings/games';

import { TNewGameValidateFields } from './new-game.typing';

export const getJobLimits = async (
  version: TGameVersion,
  callback: React.Dispatch<React.SetStateAction<TDBSectors | undefined>>
) => {
  try {
    const jobSectors = await fetchJobSectorsAsync(version);

    if (!jobSectors) {
      return;
    }

    const workSectors = jobSectors.filter((sector) => !sector.isNoWork);

    const defaultJobLimits = workSectors.reduce(
      (jobLimits: TDBSectors, currentJobSector: IJobSector) => {
        jobLimits[currentJobSector.value] = {
          limit: 100,
          players: {},
        };
        return jobLimits;
      },
      {}
    );

    callback(defaultJobLimits);
  } catch (error) {
    console.error(error);
  } finally {
    return [];
  }
};

export const VALIDATION_STATE: Record<keyof TNewGameValidateFields, boolean> = {
  gameCode: false,
  version: true,
  gameTitle: true,
  description: true,
  roundsNumber: false,
  energy: false,
  roundDuration: false,
  account: false,
  minimalMoney: false,
};

export const EDUCATION_SELECTOR_OPTIONS = [
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'US',
    value: 'us',
  },
  {
    label: 'NTU',
    value: 'ntu',
  },
];

export const RESERVED_PREFIXES = ['23', '24', '25', '26', '27', '28', '29'];
