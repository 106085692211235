import styled from 'styled-components';
import { COLORS } from 'theme';

export const InfoBlockStyles = {
  Wrapper: styled.div`
    padding: 12px 16px;
    background-color: ${COLORS.white};
    box-shadow: 0px 4px 4px ${COLORS.info_block_shadow};
    border-radius: 10px;
  `,
  Title: styled.span`
    color: ${COLORS.black_title};
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
  `,
  Value: styled.span`
    display: block;
    margin-top: 12px;
    color: ${COLORS.lightRed};
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  `,
};
