import {memo } from 'react';
import styled from 'styled-components';

import { ActiveGameButton } from './active-game-button';
import { TIconNames } from './icons';

export interface IActiveGameButton {
  icon: TIconNames;
  title: string;
  onClick: () => void;
  isActive?: boolean;
}

interface IProps {
  buttons: IActiveGameButton[];
}

const Wrapper = styled.div`
  margin-top: 56px;
  display: flex;
  justify-content: space-around;
`;

export const ActiveGameButtons = memo((props: IProps) => {
  const { buttons } = props;

  const RenderButtons = buttons.map((button) => (
    <ActiveGameButton
      key={button.icon}
      icon={button.icon}
      title={button.title}
      isActive={button.isActive}
      onClick={button.onClick}
    />
  ));

  return <Wrapper>{RenderButtons}</Wrapper>;
});
