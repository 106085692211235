"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientFirebase = void 0;
require("firebase/firestore");
require("firebase/storage");
var firestore_1 = require("./firestore");
var storage_1 = require("./storage");
var ClientFirebase = /** @class */ (function () {
    function ClientFirebase(app) {
        this.app = app;
        this.firestore = new firestore_1.Firestore(new firestore_1.RawFirestore(this.app.firestore()));
        this.storage = new storage_1.Storage(this.app);
    }
    return ClientFirebase;
}());
exports.ClientFirebase = ClientFirebase;
