import {FC } from 'react';

import { STRINGS } from 'constants/string';

import { TableStyle as Styles } from './table.style';
import { tableRow } from './components';

export interface IRowData {
  [key: string]: string | number;
}

interface ITable {
  type: string;
  isLoading?: boolean;
  tableData?: TItem[];
  title?: string;
  tableWidth?: string;
  tableHeight?: string;
  isLimitedList?: boolean;
  maxWidth?: string;
}

const { emptyInfoMessage } = STRINGS.STATISTIC;

export const Table: FC<ITable> = (props) => {
  const {
    tableData,
    title,
    tableWidth,
    tableHeight,
    isLimitedList,
    maxWidth,
    type,
    isLoading = false,
  } = props;

  if (!tableData) {
    return null;
  }

  const getItemList = () =>
    tableData.map((data: TItem, index) => tableRow(data, type, index));

  const isLimitedDataList = isLimitedList && tableData.length > 10;

  const RenderContent = isLoading ? (
    <Styles.EmptyTitle>Loading</Styles.EmptyTitle>
  ) : tableData.length < 2 ? (
    <Styles.EmptyTitle>{emptyInfoMessage}</Styles.EmptyTitle>
  ) : (
    <Styles.ScrollWrapper>
      <Styles.Table>
        <tbody>{getItemList()}</tbody>
      </Styles.Table>
    </Styles.ScrollWrapper>
  );

  return (
    <Styles.TableBox
      width={tableWidth}
      height={tableHeight}
      isLimitedList={isLimitedDataList}
      maxWidth={maxWidth}
    >
      {title && <Styles.BoxText>{title}</Styles.BoxText>}

      {RenderContent}
    </Styles.TableBox>
  );
};
