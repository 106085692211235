import styled from 'styled-components';

import { Icon } from 'components/icons';
import { COLORS, FONTS, Theme } from 'theme';
import { STRINGS } from 'constants/string';

import { LimitsBlock } from '../limits-block';
import { IDS } from '../../home.constants';

import { useEmptyHome } from './empty-home.state';

const { EMPTY_CREATE_GAME } = IDS.BUTTONS;

interface IHomeEmpty {
  gameCreate: () => void;
  isLoading: boolean;
}

const { noCreated, pleaseClick, createGameButton } = STRINGS.HOME;

const LimitsBlockStyled = styled(LimitsBlock)`
  max-width: 795px;
`;

const Styles = {
  Root: styled.div`
    padding-top: 16px;
  `,

  GameCreateBlock: styled.div`
    display: grid;
    justify-content: center;
    justify-items: center;
    align-content: start;
    row-gap: 16px;
  `,

  Tip: styled.p`
    font-family: ${FONTS.regular};
    margin-top: 16px;
  `,

  Button: styled(Theme.Button)`
    margin-top: 0;

    :disabled {
      background-color: ${COLORS.gray};
      cursor: not-allowed;
    }
  `,

  Spinner: styled.div`
    position: absolute;
    align-self: center;
    top: calc(50% - 50px);
  `,
};

export const HomeEmpty = ({ gameCreate, isLoading }: IHomeEmpty) => {
  const {
    isAllowed,
    isPlatformUser,
    isLoading: isLoadingLimits,
    limits,
  } = useEmptyHome();

  const RenderLimitsBlock = isPlatformUser && (
    <LimitsBlockStyled isLoading={isLoadingLimits} limits={limits} />
  );

  const RenderLoading = isLoading && (
    <Styles.Spinner>
      <Theme.Title>LOADING...</Theme.Title>
    </Styles.Spinner>
  );

  return (
    <Styles.Root>
      <Styles.GameCreateBlock>
        <Theme.Title>{noCreated}</Theme.Title>

        {RenderLimitsBlock}

        <Styles.Tip>{pleaseClick}</Styles.Tip>

        <Styles.Button
          id={EMPTY_CREATE_GAME}
          text={createGameButton}
          onClick={gameCreate}
          isLightTheme={false}
          isDisabled={isLoading || !isAllowed}
        />

        <Icon type="noGames" width="476px" />
      </Styles.GameCreateBlock>

      {RenderLoading}
    </Styles.Root>
  );
};
