import firebase from 'firebase/app';
import { IPlayerStored } from '@avid/common';

import { STRINGS } from 'constants/string';

const { PLAYERS_LIST } = STRINGS;
const { table } = PLAYERS_LIST;

export const playersSnapshotToTableItems = (
  playersSnapshot: firebase.firestore.QuerySnapshot<
    firebase.firestore.DocumentData
  >
) =>
  playersSnapshot.docs.map((playerDoc) => {
    const { authInfo, createCharacter } = playerDoc.data() as IPlayerStored;

    return {
      name: authInfo?.name || '?Unknown?',
      email: authInfo?.email || '?Unknown?',
      characterName: createCharacter?.name || '',
    };
  });

export const PLAYERS_TABLE_TITLE = {
  name: table.name,
  characterName: table.characterName,
  email: table.email,
};
