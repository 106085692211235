import { gamesFirestoreRefs } from 'services/games/games.firestore';

const refs = {
  playersCreatedCharacter(gameCode: string) {
    return gamesFirestoreRefs
      .gamePlayers(gameCode)
      .where('createCharacter.isCreate', '==', true);
  },
};

export const playersFirestore = {
  async countPlayersCreatedCharacter(gameCode: string) {
    const ref = refs.playersCreatedCharacter(gameCode);

    const snapshot = await ref.get();

    return snapshot.docs.length;
  },
};
