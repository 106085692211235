import styled from 'styled-components';

interface IProps {
  onClick: () => void;
}

const MinusSVG = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path
      d="M12.5 0C5.60722 0 0 5.60722 0 12.5C0 19.3928 5.60722 25 12.5 25C19.3928 25 25 19.3928 25 12.5C25 5.60722 19.3928 0 12.5 0ZM17.9688 13.5416C11.6155 13.5416 14.0203 13.5416 7.03125 13.5416C6.45618 13.5416 5.98965 13.0751 5.98965 12.5C5.98965 11.9249 6.45618 11.4584 7.03125 11.4584C13.4051 11.4584 10.9699 11.4584 17.9688 11.4584C18.5438 11.4584 19.0104 11.9249 19.0104 12.5C19.0104 13.0751 18.5438 13.5416 17.9688 13.5416Z"
      fill="#5748AF"
      fillOpacity="0.3"
    />
  </svg>
);

const PlusSVG = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path
      d="M12.5 0C5.60722 0 0 5.60722 0 12.5C0 19.3928 5.60722 25 12.5 25C19.3928 25 25 19.3928 25 12.5C25 5.60722 19.3928 0 12.5 0ZM17.9688 13.5416H13.5416V17.9688C13.5416 18.5438 13.0751 19.0104 12.5 19.0104C11.9249 19.0104 11.4584 18.5438 11.4584 17.9688V13.5416H7.03125C6.45618 13.5416 5.98965 13.0751 5.98965 12.5C5.98965 11.9249 6.45618 11.4584 7.03125 11.4584H11.4584V7.03125C11.4584 6.45618 11.9249 5.98965 12.5 5.98965C13.0751 5.98965 13.5416 6.45618 13.5416 7.03125V11.4584H17.9688C18.5438 11.4584 19.0104 11.9249 19.0104 12.5C19.0104 13.0751 18.5438 13.5416 17.9688 13.5416Z"
      fill="#5748AF"
      fillOpacity="0.3"
    />
  </svg>
);

const Root = styled.div`
  cursor: pointer;
  user-select: none;
`;

const Minus = (props: IProps) => {
  const { onClick } = props;
  return <Root onClick={onClick}>{MinusSVG}</Root>;
};

const Plus = (props: IProps) => {
  const { onClick } = props;
  return <Root onClick={onClick}>{PlusSVG}</Root>;
};

export const SectorsSettingsCounterControllers = {
  Minus,
  Plus,
};
