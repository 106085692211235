import { moveToRouteCallback } from 'services/history';
import { useReduxSelector } from 'services/hooks';
import { checkActiveGame } from 'services/utils/games';
import { ROUTES } from 'constants/routes';

export const useHomeState = () => {
  const games = useReduxSelector((redux) => redux.main.games);
  const activeGame = games.find(checkActiveGame);

  const gameCreate = moveToRouteCallback(ROUTES.NEW_GAME);

  return {
    games,
    activeGame,
    gameCreate,
  };
};
