import styled from 'styled-components';

import { FONTS, FONT_SIZE, COLORS } from 'theme';

export const LimitsBlockStyles = {
  Root: styled.div`
    padding: 25px;
    background-color: ${COLORS.blue_gray};
    border: 1px solid ${COLORS.light_gray};
    box-sizing: border-box;
    border-radius: 20px;
    min-height: 208px;
  `,
  Title: styled.h3`
    margin: 0;
    font-family: ${FONTS.bold};
    font-size: ${FONT_SIZE.large};
  `,
  Tip: styled.p`
    margin-top: 18px;
  `,
  NotFound: styled.p`
    text-align: center;
    font-family: ${FONTS.medium};
  `,
};
