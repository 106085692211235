import { TGameVersion } from '@avid/common';

import { fetch } from 'services/firebase.api';
import { IJobSector } from 'typings/games';

export const fetchJobSectorsAsync = async (version: TGameVersion) => {
  try {
    const educationConstants = await fetch('versions', version);

    if (!educationConstants) {
      return;
    }

    return educationConstants.sectors as IJobSector[];
  } catch (error) {
    console.error(error);
  }
};
