import {SelectHTMLAttributes } from 'react';
import styled from 'styled-components';

import { COLORS, typographyCss } from 'theme';
import { ARROW_SVG } from 'constants/styles';

interface IProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: IOption[];
  placeholder?: string;
}

const SelectStyled = styled.select`
  appearance: none;
  border: none;
  outline: none;
  padding: 14px 16px;
  padding-right: 24px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 2px 6px 16px rgba(45, 45, 55, 0.1);
  background-color: ${COLORS.white};

  background-image: url('${ARROW_SVG}');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 46%;

  ${typographyCss.Regular};
`;

export const NativeSelect = (props: IProps) => {
  const { options, value = '', placeholder, ...other } = props;

  const RenderPlaceholder = placeholder && (
    <option value="" disabled hidden>
      {placeholder}
    </option>
  );

  const RenderOptions = options.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  return (
    <SelectStyled value={value} {...other}>
      {RenderPlaceholder}
      {RenderOptions}
    </SelectStyled>
  );
};
