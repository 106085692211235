import { STRINGS } from 'constants/string';

import { useCreateBlock } from './create-block.state';
import { CreateBlockStyles as Styles } from './create-block.styles';
import { LimitsBlock } from '../limits-block';

const { HOME } = STRINGS;

export const CreateBlock = () => {
  const { limits, isLoading, isAllowed, isPlatformUser, onClickCreate } =
    useCreateBlock();

  const RenderLimitsBlock = isPlatformUser && (
    <LimitsBlock isLoading={isLoading} limits={limits} />
  );

  return (
    <Styles.Root isLeft={!isPlatformUser}>
      {RenderLimitsBlock}

      <Styles.Button
        text={HOME.createGameButton}
        onClick={onClickCreate}
        isLightTheme={false}
        isDisabled={isLoading || !isAllowed}
      />
    </Styles.Root>
  );
};
