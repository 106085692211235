import { db } from 'services/firebase';

import { IFetchLogsSnapshotParams } from './logs.api.typing';
import { generateQuery } from './logs.api.utils';

const PAGE_LIMIT = 10;

let lastDoc: any = null;

export const unlimitedLogsQuery = (gameCode: string) =>
  db.collection(`games/${gameCode}/logs`);

export const sortedUnlimitedLogsQuery = (gameCode: string) =>
  unlimitedLogsQuery(gameCode)
    .orderBy('created', 'desc')
    .orderBy('playerEmail');

export const logsQuery = (gameCode: string) =>
  unlimitedLogsQuery(gameCode).limit(PAGE_LIMIT);

export const logsRealTimeQuery = (gameCode: string, limit: number) =>
  unlimitedLogsQuery(gameCode).orderBy('created', 'desc').limit(limit);

export const fetchLogsSnapshot = async (params: IFetchLogsSnapshotParams) => {
  const { gameCode, isNext, options } = params;

  let query = logsQuery(gameCode);

  if (options) {
    query = generateQuery(query, options);
  }

  if (isNext) {
    query = query.startAfter(lastDoc || 0);
  } else {
    lastDoc = null;
  }

  const result = await query.get();
  lastDoc = result.docs[result.docs.length - 1];

  return result;
};
