
import styled from 'styled-components';

import { Theme } from 'theme';
import { IStatisticsData } from 'typings/games';

import { StatisticsPanel } from './statistics-panel';
import { ButtonMedium } from './button-medium';

interface IProps {
  isGameExists: boolean;
  isLoading: boolean;
  gameCode?: string;
  statistics?: IStatisticsData;
  isAllowPrint?: boolean;
}

const Title = styled(Theme.Title)`
  margin-top: 32px;
  margin-bottom: 32px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const PrintPDFButton = styled(ButtonMedium)`
  margin-top: 48px;
  align-self: center;

  @media print {
    display: none;
  }
`;

export const StatisticsContent = (props: IProps) => {
  const { isGameExists, isLoading, gameCode, statistics, isAllowPrint } = props;

  if (!isGameExists) {
    return <Title>You have no games yet</Title>;
  } else if (!gameCode) {
    return <Title>Select Game</Title>;
  } else if (isLoading) {
    return <Title>Loading...</Title>;
  } else if (
    !statistics ||
    (Object.keys(statistics).length === 0 && statistics.constructor === Object)
  ) {
    return <Title>{"There's no one here yet"}</Title>;
  } else {
    const onSavePDF = () => window.print();

    const RenderPrintButton = isAllowPrint && (
      <PrintPDFButton text="Save PDF" onClick={onSavePDF} isLightTheme />
    );

    return (
      <>
        <StatisticsPanel statistics={statistics} gameCode={gameCode} />
        {RenderPrintButton}
      </>
    );
  }
};
