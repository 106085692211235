import { IPlayerStored } from '@avid/common';

import { db } from '../firebase';

export const gamesFirestoreRefs = {
  games() {
    return db.collection('games');
  },
  game(gameCode: string) {
    return this.games().doc(gameCode);
  },
  gamePlayers(gameCode: string) {
    return this.game(gameCode).collection('users');
  },
  gamePlayer(gameCode: string, email: string) {
    return this.gamePlayers(gameCode).doc(email);
  },
};

export const gamesFirestore = {
  async getGamePlayers(gameCode: string) {
    const ref = gamesFirestoreRefs.gamePlayers(gameCode);

    const snapshot = await ref.get();

    const players = snapshot.docs.map((doc) => doc.data() as IPlayerStored);

    return players;
  },
};
