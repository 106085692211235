
import styled from 'styled-components';
import { COLORS } from 'theme';

import { Button, IButtonProps } from './button';
import { Icon, TIconNames } from './icons';

interface IProps extends IButtonProps {
  icon: TIconNames;
  title: string;
  isActive?: boolean;
}

const Root = styled(Button)<{ isActive?: boolean }>`
  width: 78px;
  height: 60px;
  border: none;
  box-sizing: border-box;
  box-shadow: 2px 6px 16px rgba(45, 45, 55, 0.15);

  ${({ isActive }) => isActive && `background-color: ${COLORS.darkPurple}`}
`;

export const ActiveGameButton = (props: IProps) => {
  const { icon, title, isActive, onClick } = props;

  return (
    <Root isLightTheme onClick={onClick} title={title} isActive={isActive}>
      <Icon type={icon} />
    </Root>
  );
};
