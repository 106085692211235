export const COLORS = <const>{
  black: '#0B1836',
  black_title: '#000',
  transparent: 'initial',

  white: '#fff',
  whiteAntiFlash: '#F3F4F4',
  turquoise: '#c7eaef',
  gray: '#ABABB6',
  grey: '#cccccc',
  light_gray: '#F2F2F2',
  middle_gray: '#E4E4E5',
  blue_gray: '#FBFBFF',
  table_gray: 'rgba(87, 72, 175, 0.05)',
  blackGray: '#888281',
  darkGray: '#D8D8D8',
  box_shadow: 'rgba(45, 45, 55, 0.1)',
  info_block_shadow: 'rgba(0, 0, 0, 0.15)',
  scrollbar_track: 'rgba(11, 24, 54, 0.15)',
  modal_background: '#2D2D3728',

  blue: '#0F7FF1',
  input_blue: '#2684ff',

  orange: '#F48A02',

  violet: '#5748AF',

  red: '#FF0000',
  lightRed: '#DC4F89',
  redSeconday: '#FF000E',

  yellow: '#F9C13F',
  yellowBold: '#FFCB12',

  green: 'green',
  darkPurple: '#5448A9',
  lightPurple: '#ef60a3',
};

export type TColorsName = keyof typeof COLORS;
