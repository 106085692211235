import styled, { css } from 'styled-components';

import { COLORS } from 'theme/colors';
import { FONTS } from 'theme/fonts';

import { CSS } from 'typings';

export const ButtonThemes = {
  light: css`
    background: ${COLORS.white};
    color: ${COLORS.black};
    border: 2px solid ${COLORS.violet};
  `,
  dark: css`
    background: ${COLORS.violet};
    color: ${COLORS.white};
    border: none;
  `,
};

export const StyledButton = styled.button<{ css: CSS }>`
  ${(props) => props.css}
  font-size: 16px;
  border-radius: 15px;
  font-family: ${FONTS.semiBold};
  width: 255px;
  height: 54px;
  cursor: pointer;

  &:active {
    outline: none;
    border: none;
    box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.8),
      inset -1px -1px 1px rgba(255, 255, 255, 0.4);
  }
  &:focus {
    outline: 0;
  }
  &:disabled {
    box-shadow: none;
    filter: grayscale(1);
    cursor: default;
  }
`;
