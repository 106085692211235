import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';

import { formatDuration, getNowMoment } from 'services/utils';

export interface IUseRoundTimerProps {
  roundDuration: number;
  addTimeElapsed: (interval: number) => void;
  roundStart?: string | null;
  endRound?: () => void;
}

interface IState {
  timeoutString?: string;
  percent?: number;
}

const getFormatTimeout = (duration: number, start: string) => {
  const roundStart = moment(start).utc(false).unix();

  const end = roundStart + duration;

  const now = getNowMoment();
  const time = moment.duration(moment.unix(end).diff(now));
  const timeSeconds = time.asSeconds();

  const durationFormat = formatDuration(timeSeconds);

  return { durationFormat, timeSeconds };
};

export const useRoundTimer = (props: IUseRoundTimerProps) => {
  const { endRound, addTimeElapsed, roundDuration, roundStart } = props;

  const [state, setState] = useState<IState>({});

  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const clearIntervalRef = useCallback(() => {
    if (!intervalRef.current) {
      return;
    }

    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }, [intervalRef]);

  const clearTimer = useCallback(() => {
    setState({ percent: 0, timeoutString: '00:00' });

    clearIntervalRef();
  }, [clearIntervalRef]);

  const updateTimer = useCallback(() => {
    if (!roundStart) {
      clearTimer();
      return;
    }

    const { durationFormat, timeSeconds } = getFormatTimeout(
      roundDuration,
      roundStart
    );

    if (timeSeconds <= 0) {
      clearTimer();
      endRound?.();
      return;
    }

    const passed = roundDuration - timeSeconds;

    addTimeElapsed(passed);

    const percent = (passed / roundDuration) * 100;

    setState({ percent, timeoutString: durationFormat });
  }, [roundStart]);

  useEffect(() => {
    if (!roundStart) {
      clearTimer();
      return;
    }

    updateTimer();

    intervalRef.current = setInterval(updateTimer, 1000);

    return () => {
      clearIntervalRef();
    };
  }, [clearIntervalRef, clearTimer, updateTimer]);

  return { ...state };
};
