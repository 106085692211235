import { TEducationLevels, TGameVersion } from '@avid/common';

import { db } from 'services/firebase';
import {
  ISectorWorkDocParams,
  TVersionData,
  TWorkSectorWorkDocs,
} from 'typings/firestore';

const queries = {
  versions: () => db.collection('versions'),

  version: (gameVersion: string) => queries.versions().doc(gameVersion),

  sectors: (version: string) => queries.version(version).collection('sectors'),

  sector: (version: string, sector: string) =>
    queries.sectors(version).doc(sector),

  sectorWork: (version: string, sector: string) =>
    queries.sector(version, sector).collection('work'),

  sectorWorkDoc: <T extends keyof TWorkSectorWorkDocs>(
    params: ISectorWorkDocParams<T>
  ) => {
    const { version, sector, doc } = params;
    return queries.sectorWork(version, sector).doc(doc);
  },
};

class VersionsAPI {
  async getEducationLevels(version: TGameVersion) {
    const data = (
      await db.doc(`versions/${version}/education/levels`).get()
    ).data() as Record<'levels', TEducationLevels> | undefined;

    return data;
  }

  async getSectors(version: string) {
    const doc = await queries.version(version).get();
    return doc.data() as TVersionData;
  }

  async getSectorWorkDoc<T extends keyof TWorkSectorWorkDocs>(
    params: ISectorWorkDocParams<T>
  ) {
    const doc = await queries.sectorWorkDoc(params).get();
    return doc.data() as TWorkSectorWorkDocs[T] | undefined;
  }

  async getCareerTree(version: string, sector: string) {
    return this.getSectorWorkDoc({
      version,
      sector,
      doc: 'careerTree',
    });
  }
}

export const versionsAPI = new VersionsAPI();
