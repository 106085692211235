import { memo } from 'react';
import { entriesObject } from '@avid/common';

import {
  ISectorsSettings,
  TSectorSettings,
  TWorkLimitParam,
} from 'typings/sectors-settings';

import { SectorsSettingsRow } from '../sectors-settings-row';

interface IRowProps {
  sector: string;
  settings: ISectorsSettings;
  isLimitSelected: boolean;
  isSalarySelected: boolean;
  onSelectItem: (selection: TWorkLimitParam, sector: string) => void;
  changeItemCallback: (
    param: TWorkLimitParam
  ) => (sector: string, limit: number) => void;
}

interface IProps {
  sectorSettings: TSectorSettings;
  selectedLimits: string[];
  selectedSalaries: string[];
  onSelectItem: (selection: TWorkLimitParam, sector: string) => void;
  changeItemCallback: (
    param: TWorkLimitParam
  ) => (sector: string, limit: number) => void;
}

const Row = memo((props: IRowProps) => {
  const {
    sector,
    settings,
    isLimitSelected,
    isSalarySelected,
    changeItemCallback,
    onSelectItem,
  } = props;

  const changeLimit = (limit: number) =>
    changeItemCallback('limit')(sector, limit);

  const changeSalary = (salary: number) =>
    changeItemCallback('salary')(sector, salary);

  const selectItem = (selection: TWorkLimitParam) =>
    onSelectItem(selection, sector);

  return (
    <SectorsSettingsRow
      sector={sector}
      employeesCount={settings.employeesCount}
      limit={settings.limit}
      salary={settings.salary}
      isLimitSelected={isLimitSelected}
      isSalarySelected={isSalarySelected}
      onSelectItem={selectItem}
      onChangeLimit={changeLimit}
      onChangeSalary={changeSalary}
    />
  );
});

export const SectorsSettingsGrid = (props: IProps) => {
  const {
    sectorSettings,
    selectedLimits,
    selectedSalaries,
    onSelectItem,
    changeItemCallback,
  } = props;

  const entries = entriesObject(sectorSettings);

  const RenderRows = entries
    .sort((first, second) => second[1].employeesCount - first[1].employeesCount)
    .map(([sector, settings]) => (
      <Row
        key={sector}
        sector={sector}
        settings={settings}
        isLimitSelected={selectedLimits.includes(sector)}
        isSalarySelected={selectedSalaries.includes(sector)}
        changeItemCallback={changeItemCallback}
        onSelectItem={onSelectItem}
      />
    ));

  return <div>{RenderRows}</div>;
};
