import {FC } from 'react';

import { IComponent } from 'typings';

import { ButtonThemes, StyledButton } from './button.style';

export interface IButtonProps extends IComponent {
  text?: string;
  isLightTheme?: boolean;
  isDisabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  id?: string;
  title?: string;
  onClick?: () => void;
}

export const Button: FC<IButtonProps> = (props) => {
  const {
    id,
    text,
    isLightTheme,
    onClick,
    className,
    type,
    isDisabled = false,
    testId,
    title,
    children,
  } = props;

  const css = isLightTheme ? ButtonThemes.light : ButtonThemes.dark;

  return (
    <StyledButton
      id={id}
      type={type}
      title={title}
      css={css}
      className={className}
      onClick={onClick}
      disabled={isDisabled}
      data-testid={testId}
    >
      {children || text}
    </StyledButton>
  );
};
