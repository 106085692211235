import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { TStoredLog } from '@avid/common';

import { fetchLogsSnapshot, IFetchLogsOptions } from 'services/api';
import { logToStructured } from 'screens/players-history';
import {
  IFilters,
  IStructuredLog,
  ITableProps,
} from '../../players-history.typing';

import { mapFiltersToFilterOptions } from './logs-table.utils';
import { INIT_TABLE_STATE, INIT_STATE } from './logs-table.constants';
import { useHistoryColumns } from 'services/hooks';

export const useLogsTable = (props: ITableProps) => {
  const { gameCode } = props;

  const [state, setState] = useState(INIT_STATE);

  const { columns, isLoading } = useHistoryColumns(gameCode);

  const data = useMemo(() => state.logs, [state.logs]);

  const {
    page,
    headerGroups,
    canPreviousPage,
    canNextPage,
    allColumns,
    pageOptions: { length: pagesLength },
    state: { pageIndex, sortBy },
    getTableProps,
    getTableBodyProps,
    prepareRow,
    previousPage,
    nextPage,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: INIT_TABLE_STATE,
    },
    useSortBy,
    usePagination
  );

  const isNextButtonDisabled =
    (state.isEmpty && pageIndex + 1 >= pagesLength) || state.isUpdating;

  const onClickFilters = () => {
    setState((current) => ({
      ...current,
      isShowFilters: !current.isShowFilters,
    }));
  };

  const onApplyFilters = (params: IFilters) => {
    const filterOptions = mapFiltersToFilterOptions(params);
    setState((current) => ({ ...current, filters: filterOptions }));
  };

  const getFetchOptions = useCallback((): IFetchLogsOptions => {
    const options: IFetchLogsOptions = {};

    if (sortBy.length > 0) {
      const sort = sortBy[0];
      options.sortBy = { field: sort.id, desc: sort.desc };
    }

    if (state.filters) {
      options.filters = state.filters;
    }

    return options;
  }, [sortBy, state.filters]);

  const fetchLogsAsync = useCallback(
    async (isNext = false) => {
      setState((current) => ({ ...current, isUpdating: true }));

      const fetchOptions = getFetchOptions();
      setState((current) => ({ ...current, isEmpty: false }));

      try {
        const logsSnap = await fetchLogsSnapshot({
          gameCode,
          isNext,
          options: fetchOptions,
        });

        if (isNext && logsSnap.empty) {
          return setState((current) => ({
            ...current,
            isEmpty: true,
            isLoading: false,
            isUpdating: false,
          }));
        }

        const fetchedLogs: IStructuredLog[] = [];

        logsSnap.forEach((doc) => {
          const storedLog = doc.data() as TStoredLog | undefined;
          if (storedLog) {
            fetchedLogs.push(logToStructured(storedLog));
          }
        });

        setState((current) => ({
          ...current,
          logs: isNext ? [...current.logs, ...fetchedLogs] : fetchedLogs,
          isLoading: false,
          isUpdating: false,
          isError: false,
        }));
      } catch (ex) {
        console.error(ex);
        setState((current) => ({
          ...current,
          isLoading: false,
          isUpdating: false,
          isError: true,
        }));
      }
    },
    [gameCode, getFetchOptions]
  );

  const loadNextPage = async () => {
    if (pageIndex + 1 >= pagesLength) {
      await fetchLogsAsync(true);
    }
    gotoPage(pageIndex + 1);
  };

  useEffect(() => {
    setState(INIT_STATE);
  }, [gameCode]);

  useEffect(() => {
    fetchLogsAsync();
  }, [fetchLogsAsync]);

  return {
    ...state,
    isLoading: state.isLoading || isLoading,
    isNextButtonDisabled,
    page,
    headerGroups,
    canPreviousPage,
    canNextPage,
    allColumns,
    pageIndex,
    pagesLength,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    previousPage,
    nextPage,
    gotoPage,
    onClickFilters,
    loadNextPage,
    onApplyFilters,
  };
};
