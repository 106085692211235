

import { Loader } from 'components/loader';

import { TableStyledComponents as TableStyles } from '../table-styled';
import { DataCells } from '../data-cells';
import { ITableProps } from '../../players-history.typing';
import { AmountSelect } from './components';

import { useRealTimeTable } from './realtime-table.state';
import { Hiding } from '../hiding';

export const RealTimeTable = (props: ITableProps) => {
  const {
    isLoading,
    showAmount,
    rows,
    headerGroups,
    allColumns,
    prepareRow,
    getTableProps,
    getTableBodyProps,
    onChangeShowAmount,
  } = useRealTimeTable(props);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AmountSelect
        value={showAmount.toString()}
        onChangeAmount={onChangeShowAmount}
      />
      <Hiding columns={allColumns} />

      <TableStyles.TableContainer>
        <TableStyles.Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <TableStyles.HeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableStyles.HeadCell {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </TableStyles.HeadCell>
                ))}
              </TableStyles.HeadRow>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                // eslint-disable-next-line react/jsx-key
                <TableStyles.DataRow {...row.getRowProps()}>
                  <DataCells cells={row.cells} />
                </TableStyles.DataRow>
              );
            })}
          </tbody>
        </TableStyles.Table>
      </TableStyles.TableContainer>
    </>
  );
};
