import {
  IEducationCost,
  IEducationSkillUpgrades,
  IPrerequisites,
} from '@avid/common';

import { IStudyCsv } from 'typings/constants-uploading.typing';

const parsPercentSkills = (skillsString: string) => {
  const upgrades = skillsString.split('+');

  return upgrades.map<IEducationSkillUpgrades>((upgrade) => {
    const res = upgrade.split('%');
    return {
      percent: +res[0],
      level: +res[1],
    };
  });
};

const parseFixedSkills = (skillsString: string): IEducationSkillUpgrades[] => {
  const skillStrings = skillsString.split('+');

  const skillArrs = skillStrings.map((skillString) => skillString.split('='));

  const parsedSkills = skillArrs.map(([skill, level]) => {
    const levelNumber = +level;

    if (isNaN(levelNumber)) {
      throw new Error('level must be a number');
    }

    return { skill: skill.trim(), level: levelNumber };
  });

  return parsedSkills;
};

export class MapStudyService {
  private readonly $education: IStudyCsv;

  constructor(education: IStudyCsv) {
    this.$education = education;
  }

  public parseSkillUpgrade(isSector: boolean) {
    if (!this.$education.skill) {
      return;
    }

    const parsedSkills = (isSector ? parsPercentSkills : parseFixedSkills)(
      this.$education.skill
    );

    return parsedSkills;
  }

  private parsePrerequisite = (str: string): IPrerequisites => {
    const prerequisite = str.split('%');
    return {
      level: prerequisite[1].trim(),
      rating: +prerequisite[0],
    };
  };

  public parsePrerequisites = (): IPrerequisites[] => {
    const parse = this.$education.prerequisites;
    if (!parse) {
      return [];
    }
    if (!parse.includes('%')) {
      return [{ level: parse.trim() }];
    }
    if (!parse.includes('OR')) {
      return [this.parsePrerequisite(parse)];
    }
    return parse.split('OR').map((t) => this.parsePrerequisite(t));
  };

  public parseCost = (): IEducationCost => {
    const price: IEducationCost = {
      energy: +this.$education.energy,
    };

    const money = this.$education.money;

    if (money) {
      price.money = +money;
    }

    return price;
  };
}
