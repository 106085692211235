import styled from 'styled-components';

import { FONTS } from 'theme';

export const AmountSelectStyles = {
  Root: styled.div`
    font-family: ${FONTS.medium};
    text-align: right;
  `,
  SelectContainer: styled.div`
    display: inline-block;
  `,
};
