
import Chart from 'react-apexcharts';

import { FONTS } from 'theme';
import { STRINGS } from 'constants/string';

import { PieStyle } from './pie-chart.style';
import { valuesOfObject } from 'services/object';

interface IPieChart {
  pieData?: Record<string, number | [number, number]>;
}

const { emptyInfoMessage } = STRINGS.STATISTIC;

const createOptions = (
  labels: string[],
  sectorNumbers?: (number | null)[]
) => ({
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -25,
      },
    },
  },
  labels,
  legend: {
    show: false,
  },
  dataLabels: {
    style: {
      fontSize: '16px',
      fontFamily: FONTS.regular,
    },
    minAngleToShowLabel: 20,
    formatter(val: number, opts: any) {
      const name = opts.w.globals.labels[opts.seriesIndex];
      return [name];
    },
  },
  tooltip: {
    y: {
      formatter(val: number, { seriesIndex }: { seriesIndex: number }) {
        const sectorNumber = sectorNumbers?.[seriesIndex];

        return (
          `${sectorNumber ? `${sectorNumber} | ` : ''}` + Math.round(val) + '%'
        );
      },
    },
  },
  chart: {
    width: 250,
  },
});

export const PieChart = ({ pieData }: IPieChart) => {
  if (!pieData || !Object.keys(pieData).length) {
    return <PieStyle.EmptyTitle>{emptyInfoMessage}</PieStyle.EmptyTitle>;
  }

  const pieDataValues = valuesOfObject(pieData);

  const series = pieDataValues.map((value) =>
    Array.isArray(value) ? value[1] : value
  );

  const playerAmounts = pieDataValues.map((value) =>
    Array.isArray(value) ? value[0] : null
  );

  return (
    <div id="chart">
      <Chart
        options={createOptions(Object.keys(pieData), playerAmounts)}
        series={series}
        type="pie"
        width={310}
      />
    </div>
  );
};
