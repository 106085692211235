import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { IModalProps, Modal } from './modal';

const Styles = {
  Root: styled(Modal)`
    cursor: pointer;
  `,
};

const DraggableModalContent = (props: IModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const isDraggingRef = useRef(false);
  const positionRef = useRef({ x: 0, y: 0 });

  const onMouseDown = useCallback((ev: React.MouseEvent) => {
    ev.preventDefault();

    isDraggingRef.current = true;
  }, []);

  const onMouseMove = useCallback((ev: MouseEvent) => {
    if (isDraggingRef.current && modalRef.current) {
      const newX = positionRef.current.x + ev.movementX;
      const newY = positionRef.current.y + ev.movementY;

      positionRef.current.x = newX;
      positionRef.current.y = newY;

      modalRef.current.style.transform = `translate(${newX}px, ${newY}px)`;
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    isDraggingRef.current = false;
  }, []);

  useEffect(() => {
    const modalRefCurrent = modalRef.current;

    if (!modalRefCurrent) {
      return;
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [onMouseMove, handleMouseUp, onMouseDown]);

  return (
    <Styles.Root modalRef={modalRef} onMouseDown={onMouseDown} {...props} />
  );
};

export const DraggableModal = (props: IModalProps) => {
  const { isOpen } = props;

  if (!isOpen) {
    return null;
  }

  return <DraggableModalContent {...props} />;
};
