import { memo } from 'react';
import { TGameStatus } from '@avid/common';

import { Span } from 'components/typography';
import { COLORS } from 'theme';

import { AdminGameStyles as Styles } from './admin-game.styles';
import { AdminGameValue } from '../admin-game-value';
import { Icon, TIconNames } from 'components/icons';
import { moveToRoute } from 'services/history';
import { ROUTES } from 'constants/routes';
import { AdminGameButtons } from '../admin-game-buttons';

type TGameState = 'created' | 'active' | 'finished';

interface IStateContent {
  backgroundColors: [string, string];
  headColor: string;
  icon: TIconNames;
  head: string;
}

interface IComponentProps extends IStateContent {
  title: string;
  gameCode: string;
  description: string;
  stateValue: string;
  currentRound: number;
  onClickHead?: () => void;
}

interface IProps {
  title: string;
  gameCode: string;
  description: string;
  status: TGameStatus;
  currentRound?: number;
}

const getGameState = (
  status: TGameStatus,
  roundsLength: number
): TGameState => {
  if (status === 'finished') {
    return 'finished';
  }
  if (status === 'in-progress') {
    return 'active';
  }
  return roundsLength ? 'active' : 'created';
};

const STATE_CONTENT_MAP: Record<TGameState, IStateContent> = {
  active: {
    backgroundColors: ['rgba(239, 96, 163, 1)', 'rgba(239, 96, 163, 0.2)'],
    headColor: COLORS.white,
    icon: 'playProgress',
    head: 'Game is in progress',
  },
  created: {
    backgroundColors: ['rgba(255, 203, 18, 1)', 'rgba(255, 203, 18, 0.2)'],
    headColor: COLORS.black,
    icon: 'play',
    head: 'Start game',
  },
  finished: {
    backgroundColors: ['rgba(216, 216, 216, 1)', 'rgba(216, 216, 216, 0.2)'],
    headColor: COLORS.black,
    icon: 'pieGraphBig',
    head: 'View Game Stats',
  },
};

export const AdminGameComponent = (props: IComponentProps) => {
  const {
    title,
    gameCode,
    description,
    stateValue,
    currentRound,
    backgroundColors,
    head,
    headColor,
    icon,
    onClickHead,
  } = props;

  const [headerColor, mainColor] = backgroundColors;

  return (
    <Styles.Root rowGap="0">
      <Styles.Header
        alignItems="center"
        backgroundColor={headerColor}
        color={headColor}
        onClick={onClickHead}
      >
        <Span typography="QuicksandBold16">{head}</Span>
        <Icon type={icon} />
      </Styles.Header>

      <Styles.Main backgroundColor={mainColor}>
        <Styles.List>
          <AdminGameValue label="Status" value={stateValue} />
          <AdminGameValue
            label="Current Round"
            value={currentRound.toString()}
          />
          <AdminGameValue label="Game Code" value={gameCode} />
          <AdminGameValue label="Game Title" value={title} />
          <AdminGameValue
            label="Description"
            value={description}
            isRow={false}
          />
        </Styles.List>

        <AdminGameButtons gameCode={gameCode} />
      </Styles.Main>
    </Styles.Root>
  );
};

export const AdminGame = memo((props: IProps) => {
  const { description, gameCode, status, title, currentRound = 0 } = props;

  const gameState = getGameState(status, currentRound);

  const stateValue = gameState === 'finished' ? 'Completed' : 'Active';

  const stateContent = STATE_CONTENT_MAP[gameState];

  const onClick = () => {
    if (gameState === 'finished') {
      moveToRoute(ROUTES.STATISTIC, gameCode);
      return;
    }

    moveToRoute(ROUTES.ACTIVE_GAME + '/' + gameCode);
  };

  return (
    <AdminGameComponent
      gameCode={gameCode}
      title={title}
      description={description}
      currentRound={currentRound}
      backgroundColors={stateContent.backgroundColors}
      head={stateContent.head}
      headColor={stateContent.headColor}
      icon={stateContent.icon}
      stateValue={stateValue}
      onClickHead={onClick}
    />
  );
});
