

import { LogoWrapper } from 'components/logo-wrapper';
import { STRINGS } from 'constants/string';

import { usePasswordLogin } from './password-login.state';
import { PasswordLogInStyles as Styles } from './password-login.styles';

const { placeholder, nextButtonStr, title, error } = STRINGS.AUTH.logIn;

export const PasswordLogin = () => {
  const { userLogIn, setEmailPassword, isError } = usePasswordLogin();

  return (
    <LogoWrapper>
      <Styles.Title>{title}</Styles.Title>
      <Styles.TInputContainer>
        {isError && <Styles.ErrorMessage>{error}</Styles.ErrorMessage>}
        <Styles.TextInput
          isError={isError}
          placeholder={placeholder.email}
          onChange={setEmailPassword}
        />
        <Styles.TextInput
          isError={isError}
          placeholder={placeholder.password}
          type={'password'}
          onChange={setEmailPassword}
        />
      </Styles.TInputContainer>
      <Styles.Button text={nextButtonStr} onClick={userLogIn} isLightTheme />
    </LogoWrapper>
  );
};
