import styled, { css } from 'styled-components';

import { Grid, Input } from 'components/UI';
import { typographyCss, COLORS } from 'theme';
import { ISectorsSettingsValue } from 'typings/sectors-settings';
import { checkInt } from 'services/utils';

import { Checkbox } from 'components/checkbox';

interface IProps extends ISectorsSettingsValue {
  isSelected: boolean;
  onChangeLimit: (limit: number) => void;
  onSelect: () => void;
}

const Styles = {
  Input: styled(Input)<{ isChanged: boolean }>`
    width: 154px;
    min-width: 100px;
    border-radius: 7px;
    text-align: center;
    padding: 3px 12px;

    ${({ isChanged }) =>
      isChanged
        ? css`
            ${typographyCss.QuicksandBold18};
            color: ${COLORS.redSeconday};
          `
        : css`
            ${typographyCss.QuicksandRegular18};
            color: ${COLORS.black};
          `}
  `,
};

export const SectorsSettingsLimit = (props: IProps) => {
  const { isChanged, isSelected, value, onChangeLimit, onSelect } = props;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const strValue = event.target.value;

    if (strValue.length === 0) {
      return onChangeLimit(0);
    }

    if (!checkInt(strValue)) {
      return;
    }

    onChangeLimit(+strValue);
  };

  return (
    <Grid columns={2} alignItems="center" columnGap="12px">
      <Checkbox isChecked={isSelected} onClick={onSelect} />

      <Styles.Input isChanged={isChanged} value={value} onChange={onChange} />
    </Grid>
  );
};
