

import { COLORS } from 'theme';

import { InfoRectangle } from '../info-rectangle';
import { EnergyRefresh } from './refresh';
import { useEnergy } from './energy.state';

interface IProps {
  gameCode: string;
}

export const Energy = (props: IProps) => {
  const { total, players, isLoading, onClickUpdate } = useEnergy(
    props.gameCode
  );

  return (
    <InfoRectangle
      title="Energy"
      icon="energy"
      iconWidth="21px"
      color={COLORS.lightRed}
      items={[
        { title: 'Total Energy left', value: total },
        { title: 'Players with Energy left', value: players },
      ]}
      HeaderComponent={
        <EnergyRefresh isDisabled={isLoading} onClick={onClickUpdate} />
      }
    />
  );
};
