import Select from 'react-select';
import styled from 'styled-components';

import { Icon } from 'components/icons';

import { COLORS, FONTS } from 'theme';
import { CSS } from 'typings';

export const SelectStyles = {
  Wrapper: styled.div<{ width?: string }>`
    width: ${({ width }) => width || '300px'};
  `,
  ArrowImage: styled(Icon)`
    margin-right: 10px;
  `,
  Select: styled(Select)`
    text-align: start;
    color: ${COLORS.black};
  `,
  Text: styled.p`
    font-family: ${FONTS.medium};
  `,
};

export const SelectComponentStyles = {
  control: () => ({
    height: '44px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: COLORS.white,
    color: COLORS.black,
    fontFamily: FONTS.medium,
    boxShadow: '2px 6px 16px rgba(45, 45, 55, 0.1)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: () => ({
    display: 'flex',
    background: '#FFFFFF',
    borderRadius: '10px',
    position: 'absolute',
    flex: 1,
    width: '100%',
    zIndex: 100500,
    maxHeight: '460px',
    overflowY: 'scroll',
  }),
  menuList: () => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  }),
  placeholder: () => ({
    color: COLORS.black,
    lineHeight: '10px',
    marginLeft: '5px',
  }),
  option: (base: CSS) => ({
    ...base,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: '40px',
  }),
};
