import { useState } from 'react';

type TStatus = 'loading' | 'success' | 'downloaded' | 'error';

export const STATUS_LABEL_MAP: Record<TStatus, string> = {
  error: 'Error',
  loading: 'Loading...',
  success: 'Uploaded',
  downloaded: 'Downloaded',
};

export const useCsvUploadingStatus = () => {
  const [status, setStatus] = useState<TStatus>();

  const statusLabel = status ? STATUS_LABEL_MAP[status] : '';

  const isAvailableStatus = status !== 'loading' && status !== 'success';
  const isAvailableDownloadStatus = status !== 'loading';

  return {
    status,
    isAvailableStatus,
    isAvailableDownloadStatus,
    statusLabel,
    setStatus,
  };
};
