export const useTablePagination = (gotoPage: (pageIndex: number) => void) => {
  const onChangePageInput: React.ChangeEventHandler<HTMLInputElement> = (
    ev
  ) => {
    const page = ev.target.value ? Number(ev.target.value) - 1 : 0;
    gotoPage(page);
  };

  return { onChangePageInput };
};
