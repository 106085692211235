import { ReactNode } from 'react';

import { LogoWrapperStyles as Styles } from './logo-wrapper.styles';

interface IProps {
  children?: ReactNode;
}

export const LogoWrapper = (props: IProps) => (
  <Styles.Root>
    <Styles.Icon type="logo" />
    {props.children}
  </Styles.Root>
);
