

import { RefreshButtonStyles } from './refresh-button.styles';

interface IRefreshButtonProps {
  onRefresh: () => void;
}

export const RefreshButton = (props: IRefreshButtonProps) => {
  const { onRefresh } = props;

  return (
    <RefreshButtonStyles.ButtonContainer>
      <RefreshButtonStyles.ButtonMedium
        alignSelf={'flex-start'}
        text="Refresh"
        onClick={onRefresh}
        isLightTheme
      />
    </RefreshButtonStyles.ButtonContainer>
  );
};
