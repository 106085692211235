import {ChangeEventHandler } from 'react';
import styled from 'styled-components';

import { PositionLimitsItem } from './position-limits-item';

interface IItemProps {
  gameCode: string;
  item: IPositionLimitEditable;
  index: number;
  sectorOptions: IOption[];
  setSectorCb: (index: number) => ChangeEventHandler<HTMLSelectElement>;
  setJobCb: (index: number) => ChangeEventHandler<HTMLSelectElement>;
  setLimitCb: TSetPositionLimitParamCb<number>;
  removeLimitCb: (index: number) => () => void;
}

interface IProps {
  gameCode: string;
  sectors: string[];
  items: IPositionLimitEditable[];
  setSectorCb: (index: number) => ChangeEventHandler<HTMLSelectElement>;
  setJobCb: (index: number) => ChangeEventHandler<HTMLSelectElement>;
  setLimitCb: TSetPositionLimitParamCb<number>;
  removeLimitCb: (index: number) => () => void;
}

const stringToOption = (value: string) => ({ value, label: value });

const Styled = {
  Root: styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
  `,
};

const Item = (props: IItemProps) => {
  const {
    gameCode,
    item,
    index,
    sectorOptions,
    removeLimitCb,
    setJobCb,
    setLimitCb,
    setSectorCb,
  } = props;

  const jobOptions = item.availableJobs.map(stringToOption);

  const setSector = setSectorCb(index);
  const setJob = setJobCb(index);
  const setLimit = setLimitCb(index);
  const onRemove = removeLimitCb(index);

  return (
    <PositionLimitsItem
      gameCode={gameCode}
      key={item.sector + item.job}
      sector={item.sector}
      job={item.job}
      limit={item.limit}
      sectorOptions={sectorOptions}
      jobOptions={jobOptions}
      onChangeSector={setSector}
      onChangeJob={setJob}
      setLimit={setLimit}
      onRemove={onRemove}
    />
  );
};

export const PositionLimitsList = (props: IProps) => {
  const {
    gameCode,
    sectors,
    items,
    setSectorCb,
    setJobCb,
    setLimitCb,
    removeLimitCb,
  } = props;

  const sectorOptions = sectors.map(stringToOption);

  const RenderItems = items.map((item, index) => (
    <Item
      key={item.sector + item.job}
      gameCode={gameCode}
      sectorOptions={sectorOptions}
      item={item}
      index={index}
      removeLimitCb={removeLimitCb}
      setJobCb={setJobCb}
      setLimitCb={setLimitCb}
      setSectorCb={setSectorCb}
    />
  ));

  return <Styled.Root>{RenderItems}</Styled.Root>;
};
