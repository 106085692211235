import styled from 'styled-components';

export const GameSettingsInfoStyles = {
  ItemsWrapper: styled.div`
    // @related
    margin-top: -32px;
    margin-left: -32px;

    display: grid;
    grid-template-columns: minmax(500px, 1fr) 1fr;
  `,
};
