import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Icon } from 'components/icons';

import { COLORS } from 'theme';
import { FONTS } from 'theme/fonts';

interface IButtonBar {
  name: string;
  active?: number;
}

export const SidebarLayoutStyles = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    min-width: 250px;
    background: ${COLORS.violet};
    position: relative;

    @media print {
      display: none;
    }
  `,
  LogoWrapper: styled.div`
    align-self: center;
    margin-top: 54px;
    margin-bottom: 50px;
    cursor: pointer;
    width: fit-content;
  `,
  LogoIcon: styled(Icon)`
    width: 154px;
  `,
  ButtonBar: styled(Link)<IButtonBar>`
    display: flex;
    align-items: center;
    padding-left: 25px;
    width: 207px;
    height: 46px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: ${COLORS.white};
    font-family: ${FONTS.bold};
    font-size: 16px;
    border-radius: 12px;
    text-decoration: none;
    background: ${({ active }) =>
      (active &&
        `linear-gradient(
      to left,
      rgba(175, 72, 87, 0.15) 0.14%,
      rgba(255, 255, 255, 0.21) 0.15%,
      rgba(255, 255, 255, 0) 102.79%
    )`) ||
      'none'};
    &:focus {
      outline: 0;
    }
  `,
  ButtonIcon: styled(Icon)`
    margin-right: 16px;
  `,
};
