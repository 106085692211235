import { ILifeCard } from '@avid/common';

import { ILifeCardCsv } from 'typings/constants-uploading.typing';
import { entriesObject } from '../object';
import { clientFirebase } from '../firebase';

import { VersionCsvUploader } from './version-csv-uploader';

export class LifeCardsCsvUploader extends VersionCsvUploader {
  parseRows(parsedRows: ILifeCardCsv[]): ILifeCard[] {
    return parsedRows.map((row) => {
      const { title, choose, description, ...fields } = row;

      const card: ILifeCard = { title, choose, description };

      entriesObject(fields).forEach(([field, value]) => {
        if (!!value) {
          card[field] = value;
        }
      });

      return card;
    });
  }

  upload(lifeCards: ILifeCard[]) {
    return clientFirebase.firestore.versions.updateLifeCards(this.version, {
      lifeCards,
    });
  }
}
