import styled from 'styled-components';

import { Icon } from 'components/icons';
import { COLORS } from 'theme';

interface IModalWindow {
  isCenterContent?: boolean;
  backgroundColor?: string;
  isDragging?: boolean;
}

export const ModalStyles = {
  Wrapper: styled.div`
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    background-color: ${COLORS.modal_background};
    z-index: 150;
  `,
  ModalWindow: styled.div<IModalWindow>`
    justify-content: ${(p) => (p.isCenterContent ? 'center' : 'start')};
    position: relative;
    max-height: calc(100vh - 100px);
    padding: 40px 32px;
    margin: 24px;
    border-radius: 10px;
    overflow-y: auto;
    text-align: center;
    background-color: ${({ backgroundColor }) =>
      backgroundColor ?? COLORS.white};
    ${({ isDragging }) => isDragging && 'cursor: pointer;'}
  `,
  CloseModal: styled(Icon)`
    display: block;
    position: absolute;
    top: 25px;
    right: 25px;
    width: 15px;
    height: 15px;
    && {
      margin-top: 0;
      cursor: pointer;
    }
  `,
};
