import {ChangeEventHandler } from 'react';

import { Theme } from 'theme';

interface IProps {
  interval: number;
  onChangeGameModConfig: (props: string, value: string | number) => void;
}

export const GaugesModSettings = (props: IProps) => {
  const { interval, onChangeGameModConfig } = props;

  const onChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    onChangeGameModConfig('intervalSeconds', +ev.target.value);
  };

  return (
    <Theme.Paragraph>
      Deduct every <input type="number" value={interval} onChange={onChange} />{' '}
      seconds
    </Theme.Paragraph>
  );
};
