import styled from 'styled-components';

import { Button } from 'components/button';
import { Icon } from 'components/icons';
import { TextInput } from 'components/text-input';
import { COLORS, FONTS } from 'theme';

export const PasswordLogInStyles = {
  TInputContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,

  TextInput: styled(TextInput)`
    &:last-child {
      margin-top: 16px;
    }
    & input {
      width: 346px;
    }
  `,

  LogoTitle: styled.p`
    height: 25px;
    font-family: ${FONTS.bold};
    font-size: 32px;
    color: ${COLORS.white};
    margin: 0;
  `,

  Title: styled.p`
    text-align: center;
    width: 330px;
    font-family: ${FONTS.bold};
    font-size: 20px;
    color: ${COLORS.white};
    margin-bottom: 30px;
  `,

  ErrorMessage: styled.p`
    margin-bottom: 5px;
    margin-top: -20px;
    align-self: flex-start;
    font-family: ${FONTS.regular};
    font-size: 14px;
    line-height: 17px;
    color: ${COLORS.red};
  `,

  Button: styled(Button)`
    align-self: center;
    width: 280px;
    height: 54px;
    margin-top: 24px;
    margin-bottom: 40px;
    font-family: ${FONTS.quicksandBold};
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  `,

  LogoIcon: styled(Icon)`
    width: 225px;
  `,
};
