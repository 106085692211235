import moment from 'moment';
import { IRound } from '@avid/common';

export const getIntervalForNowToUNIX = (unix: number, formate = 'mm:ss') => {
  const startMoment = moment.unix(unix);
  const now = moment();
  const time = now.diff(startMoment);
  const interval = moment.utc(time).format(formate);

  return interval;
};

export const getNowMoment = () => moment().utc(false);

export const getTimeFormatPart = (value: number) =>
  value >= 10 ? value : `0${value}`;

export const formatDuration = (seconds: number) => {
  const duration = moment.duration(seconds * 1000);

  const { day, hour, min, sec } = {
    day: duration.get('d'),
    hour: duration.get('h'),
    min: duration.get('m'),
    sec: duration.get('s'),
  };

  const ms = `${getTimeFormatPart(min)}:${getTimeFormatPart(sec)}`;

  if (!day) {
    if (!hour) {
      return ms;
    }

    return `${getTimeFormatPart(hour)}:${ms}`;
  }

  return `${getTimeFormatPart(day)}:${getTimeFormatPart(hour)}:${ms}`;
};

export const getPassedGameTime = (rounds: IRound[]) =>
  rounds
    .map((round: IRound) => {
      return round.duration || 0;
    })
    .reduce((a: number, b: number) => a + b, 0);
