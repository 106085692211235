
import styled from 'styled-components';

import { Theme } from 'theme';

import { TableButton } from 'screens/players-history/components';
import { useLogsCSVModalState, ILogsCSVModalProps } from './csv-modal.state';
import { BUTTONS, STRINGS } from './csv-modal.constants';

const { CONFIRM, LOADING } = BUTTONS;
const { ERROR, LOADING: LOADING_TEXT, CLICK_CONFIRM } = STRINGS;

const HiddenAnchor = styled.a`
  display: none;
`;

export const LogsCSVModal = (props: ILogsCSVModalProps) => {
  const { isLoading, isError, fileLinkRef, onConfirm } = useLogsCSVModalState(
    props
  );

  return (
    <Theme.CentredContainer direction="column">
      <Theme.Paragraph>{CLICK_CONFIRM}</Theme.Paragraph>

      <TableButton
        text={isLoading ? LOADING : CONFIRM}
        isDisabled={isLoading}
        onClick={onConfirm}
      />
      {isLoading && <Theme.Paragraph>{LOADING_TEXT}</Theme.Paragraph>}
      {isError && <Theme.Paragraph>{ERROR}</Theme.Paragraph>}

      <HiddenAnchor ref={fileLinkRef} />
    </Theme.CentredContainer>
  );
};
