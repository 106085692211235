
import styled from 'styled-components';
import { Cell as TCell } from 'react-table';

import { FONTS } from 'theme';
import { IStructuredLog, formatRoundSecond } from 'screens/players-history';

import { ELLIPSIS_IDS, ELLIPSIS } from './data-cell.constants';

interface ICellProps {
  cells: TCell<IStructuredLog, any>[];
}

const StyledTD = styled.td`
  padding-left: 17px;
  text-align: left;
  font-family: ${FONTS.regular};

  & > div.${ELLIPSIS} {
    max-width: 120px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`;

export const DataCells = (props: ICellProps) => {
  const { cells } = props;

  const cellsMap = cells.map((cell) => {
    const className = ELLIPSIS_IDS.includes(cell.column.id)
      ? ELLIPSIS
      : undefined;

    return (
      // eslint-disable-next-line react/jsx-key
      <StyledTD {...cell.getCellProps()} title={cell.value}>
        <div className={className}>
          {cell.column.id === 'roundSecond'
            ? formatRoundSecond(cell.value)
            : cell.render('Cell')}
        </div>
      </StyledTD>
    );
  });

  return <>{cellsMap}</>;
};
