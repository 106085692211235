
import styled from 'styled-components';

import { ButtonMedium } from 'components/button-medium';
import { IUseGetReviewsProps, useGetReviews } from './get-reviews.state';
import { CSVLink } from 'react-csv';

const HEADERS = [
  { label: 'Email', key: 'email' },
  { label: 'Rating', key: 'rating' },
  { label: 'Comment', key: 'comment' },
];

const ButtonStyled = styled(ButtonMedium)`
  margin-top: 16px;
  align-self: center;
`;

export const GetReviews = (props: IUseGetReviewsProps) => {
  const { isLoading, reviews, csvLinkRef, fileName, onClick } = useGetReviews(
    props
  );

  return (
    <>
      <CSVLink
        headers={HEADERS}
        data={reviews || []}
        filename={fileName}
        ref={csvLinkRef}
        className="hidden"
        target="_blank"
      />
      <ButtonStyled
        text="Get reviews"
        isDisabled={isLoading}
        onClick={onClick}
      />
    </>
  );
};
