import React, { ReactNode, RefObject } from 'react';

import { ModalStyles } from './modal.styles';

export interface IModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  className?: string;
  isCenterContent?: boolean;
  color?: string;
  children?: ReactNode;
  modalRef?: RefObject<HTMLDivElement>;
  onMouseDown?: (ev: React.MouseEvent) => void;
}

export const Modal = (props: IModalProps) => {
  const {
    isOpen,
    className,
    isCenterContent,
    color,
    children,
    modalRef,
    onCloseModal,
    onMouseDown,
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <ModalStyles.Wrapper className={className}>
      <ModalStyles.ModalWindow
        className="modal-window"
        isCenterContent={isCenterContent}
        backgroundColor={color}
        ref={modalRef}
        onMouseDown={onMouseDown}
      >
        <ModalStyles.CloseModal
          type="closeModal"
          isPointerEvents={true}
          onClick={onCloseModal}
        />
        {children}
      </ModalStyles.ModalWindow>
    </ModalStyles.Wrapper>
  );
};
