import Highcharts from 'highcharts';

import { IColumnPossessionData, PossessionLevel } from 'typings';
import { COLORS } from 'theme';

export const CHART_OPTIONS: Highcharts.Options = {
  chart: {
    type: 'column',
    width: 1110,
    height: 450,
  },
  title: {
    text: 'Possessions',
    align: 'left',
  },

  xAxis: {
    categories: ['Car', 'Travel', 'Home', 'Mobile'],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: '',
    },
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
};

const createPossessionOrderList = (column: IColumnPossessionData<number>) => (
  level: PossessionLevel
) => {
  const { car, home, mobile, travel } = column;
  return [car[level], travel[level], home[level], mobile[level]];
};

export const createSeries = (
  columnPossession: IColumnPossessionData<number>
) => {
  const generateList = createPossessionOrderList(columnPossession);

  return [
    {
      name: 'Budget',
      color: COLORS.blue,
      data: generateList(PossessionLevel.Budget),
    },
    {
      name: 'Standard',
      color: COLORS.orange,
      data: generateList(PossessionLevel.Standard),
    },
    {
      name: 'Luxury',
      color: COLORS.yellow,
      data: generateList(PossessionLevel.Luxury),
    },
  ];
};
