

import { COLORS } from 'theme';

import { InfoRectangle } from '../info-rectangle';

import { usePlayers } from './players.state';

interface IProps {
  gameCode: string;
}

export const Players = (props: IProps) => {
  const { gameCode } = props;

  const { total, createdCharacter } = usePlayers(gameCode);

  return (
    <InfoRectangle
      title="Players"
      icon="playersPurple"
      iconWidth="26px"
      color={COLORS.violet}
      items={[
        { title: 'No. of Players in Game Code', value: total },
        { title: 'No. of Players Ready', value: createdCharacter },
      ]}
    />
  );
};
