import { memo } from 'react';
import styled from 'styled-components';
import { IRound } from '@avid/common';

import { Theme } from 'theme';

interface IProps {
  rounds: IRound[];
  onSelectRound:
    | ((round: number) => () => void)
    | ((round: number) => () => Promise<void>);
  selectedRound?: number;
}

const Styles = {
  Wrapper: styled.div`
    display: flex;
    overflow-x: auto;
  `,
  RoundButton: styled(Theme.ButtonSmall)`
    margin-left: 10px;
    margin-right: 10px;
    flex-shrink: 0;
  `,
};

export const StatisticsRounds = memo((props: IProps) => {
  const { rounds, selectedRound, onSelectRound } = props;

  const RenderRounds = rounds.map((_, index) => (
    <Styles.RoundButton
      key={index}
      text={`${index + 1} round`}
      darkTheme={index === selectedRound}
      onClick={onSelectRound(index)}
      isLightTheme
    />
  ));

  return <Styles.Wrapper>{RenderRounds}</Styles.Wrapper>;
});
