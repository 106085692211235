
import styled, { css } from 'styled-components';

interface ILoaderStyled {
  size?: string;
  borderSize: string;

  loaderColor: string;
  backgroundColor: string;
}

const LOADER_STYLED: Record<string, ILoaderStyled> = {
  default: {
    borderSize: '16px',
    backgroundColor: 'blue',
    loaderColor: 'lightGreen',
  },
  buttonLoader: {
    size: '20px',
    backgroundColor: 'blue',
    loaderColor: 'lightGreen',
    borderSize: '5px',
  },
};

interface ILoaderProps {
  size?: string;
  borderSize?: string;
  theme?: keyof typeof LOADER_STYLED;
}

const LoaderStyled = styled.div<ILoaderStyled>(
  (props) => css`
    border: ${props.borderSize} solid ${props.backgroundColor};
    border-top: ${props.borderSize} solid ${props.loaderColor};
    border-radius: 50%;
    width: ${props.size};
    height: ${props.size};
    animation: spin 0.5s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
);
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Loader = (props: ILoaderProps) => {
  const { theme = 'default', size = '120px' } = props;

  const styles: Required<ILoaderStyled> = { size, ...LOADER_STYLED[theme] };

  return (
    <Wrapper>
      <LoaderStyled {...styles} />
    </Wrapper>
  );
};
