import { useCallback, useEffect, useState } from 'react';

import { playersDB } from './players.db';
import { playersFirestore } from './players.firestore';

interface IState {
  total?: number;
  createdCharacter?: number;
}

const INTERVAL = 30000;

const getPlayerCounts = async (gameCode: string) => {
  const [total, createdCharacter] = await Promise.all([
    playersDB.getPlayersCount(gameCode),
    playersFirestore.countPlayersCreatedCharacter(gameCode),
  ]);

  return { total, createdCharacter };
};

export const usePlayers = (gameCode: string) => {
  const [state, setState] = useState<IState>({});

  const updatePlayersCount = useCallback(async () => {
    const { createdCharacter, total } = await getPlayerCounts(gameCode);

    setState({ total, createdCharacter });
  }, []);

  useEffect(() => {
    updatePlayersCount();

    const interval = setInterval(updatePlayersCount, INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [updatePlayersCount]);

  return { ...state };
};
