import { entriesObject } from '@avid/common';

import { IHighestPositionCsv } from 'typings/constants-uploading.typing';
import { clientFirebase } from '../firebase';

import { VersionCsvUploader } from './version-csv-uploader';

export class HighestPositionsCsvUploader extends VersionCsvUploader {
  parseRows(csvRows: IHighestPositionCsv[]) {
    return csvRows.reduce(
      (acc, row) => {
        const { sector, job } = row;

        if (!acc[sector]) {
          acc[sector] = [];
        }

        acc[sector].push(job);

        return acc;
      },
      {} as Record<string, string[]>
    );
  }

  upload(sectorsHighestPositions: Record<string, string[]>) {
    return Promise.all(
      entriesObject(sectorsHighestPositions).map(([sector, highestPositions]) =>
        clientFirebase.firestore.versions.sectors.setWorkHighestPositions(
          this.version,
          sector,
          { highestPositions }
        )
      )
    );
  }
}
