
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { TIconNames } from 'components/icons';
import { history } from 'services/history';

import { SidebarLayoutStyles as Styles } from './sidebar-layout.styles';

interface IButtonsBar {
  type: TIconNames;
  name: string;
  route: string;
  alias?: string;
}

interface IProps {
  buttons: IButtonsBar[];
}

export const SidebarLayout = (props: IProps) => {
  const { buttons } = props;

  const { pathname } = useLocation();

  const onPressLogo = () => {
    history.push(ROUTES.START);
  };

  return (
    <Styles.Container>
      <Styles.LogoWrapper onClick={onPressLogo}>
        <Styles.LogoIcon type="logo" />
      </Styles.LogoWrapper>

      {buttons.map((button) => {
        return (
          <Styles.ButtonBar
            to={button.route}
            key={button.route}
            active={
              button.route === pathname || button.alias === pathname ? 1 : 0
            }
            name={button.route}
          >
            <Styles.ButtonIcon type={button.type} />
            {button.name}
          </Styles.ButtonBar>
        );
      })}
    </Styles.Container>
  );
};
