import { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { IconButton } from 'components/UI';
import { moveToRoute } from 'services/history';
import { ROUTES } from 'constants/routes';

interface IProps {
  gameCode: string;
}

const Styles = {
  Root: styled.div`
    position: absolute;
    top: 14px;
    right: 20px;
  `,
};

export const AdminGameButtons = (props: IProps) => {
  const { gameCode } = props;

  const onClickCopy: MouseEventHandler<HTMLDivElement> = () => {
    moveToRoute(ROUTES.NEW_GAME, gameCode);
  };

  return (
    <Styles.Root>
      <IconButton icon="duplicate" onClick={onClickCopy} />
    </Styles.Root>
  );
};
