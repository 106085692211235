import styled from 'styled-components';

import { COLORS } from 'theme';

import { Theme } from 'theme';

const { SubTitle } = Theme;

export const PieStyle = {
  EmptyTitle: styled(SubTitle)`
    margin-top: auto;
    margin-bottom: 37%;
    padding: 20px;
    color: ${COLORS.gray};
  `,
};
