
import { TRoundStatus } from '@avid/common';

import { STRINGS } from 'constants/string';
import { COLORS } from 'theme/colors';

import { ScaleStyles } from 'components/progress-bar/progress-bar.styles';
import { getNumberPostfix } from 'services/utils';

interface IInactiveLink {
  index: number;
  lastRoundIndex: number;
  activeRounds: number;
  lastRoundStatus?: TRoundStatus;
}

const { notStartedStatus, roundStr, statusStr } = STRINGS.ACTIVE_GAME;

export const InactiveLink = ({
  activeRounds,
  lastRoundStatus,
  index,
  lastRoundIndex,
}: IInactiveLink) => {
  const getRoundInfo = () => {
    if (
      (!activeRounds && index < 1) ||
      (index === lastRoundIndex && lastRoundStatus === 'ended')
    ) {
      return (
        <>
          <ScaleStyles.Text isBold>{`${getNumberPostfix(
            activeRounds + 1
          )} ${roundStr}`}</ScaleStyles.Text>
          <ScaleStyles.TextRow>
            <ScaleStyles.Text>{statusStr}</ScaleStyles.Text>
            <ScaleStyles.Text ml={'5px'} color={COLORS.gray}>
              {notStartedStatus}
            </ScaleStyles.Text>
          </ScaleStyles.TextRow>
        </>
      );
    }
    return;
  };

  return (
    <ScaleStyles.ChainContainer>
      <ScaleStyles.Chain>
        <ScaleStyles.InfoContainer>
          <div>{getRoundInfo()}</div>

          <div>
            <ScaleStyles.Dot color={COLORS.middle_gray} />
            <ScaleStyles.Line color={COLORS.middle_gray} />
          </div>
        </ScaleStyles.InfoContainer>
      </ScaleStyles.Chain>
    </ScaleStyles.ChainContainer>
  );
};
