import { useCallback, useRef, useState } from 'react';
import firebase from 'firebase/app';
import moment from 'moment';
import { IGame } from '@avid/common';

import { HEADERS_KEYS } from './export-data.constants';

import { fetchCollection } from 'services/firebase.api';
import { arrayToChunks } from 'services/utils';

import { IExportDataState, IExportGame } from './export-data.typing';

const INIT_STATE: IExportDataState = {
  isLoading: false,
  exportData: [],
  progress: [0, 0],
};

export const useExportDataState = () => {
  const [state, setState] = useState(INIT_STATE);

  const csvLinkRef = useRef(null) as any;

  const isOpenDownloadWindow = () => state.exportData.length > 0;

  const setFinalProgressState = useCallback(
    (value: number) =>
      setState((prevState) => {
        const progressCopy = [...prevState.progress];
        progressCopy[1] = value;
        return { ...prevState, progress: progressCopy };
      }),
    []
  );

  const incrementCurrentProgressState = useCallback(
    () =>
      setState((prevState) => {
        const progressCopy = [...prevState.progress];
        progressCopy[0]++;
        return { ...prevState, progress: progressCopy };
      }),
    []
  );

  const mapGameToExport = useCallback(
    async (
      gameDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    ) => {
      const game = gameDoc.data() as IGame;

      const gamePlayersCollection = await gameDoc.ref.collection('users').get();
      const playersCount = gamePlayersCollection?.docs.length || 0;

      incrementCurrentProgressState();

      const email = game.ownerEmail || game.config.email;

      const gameCreator = `${game.ownerId}${email ? ` (${email})` : ''}`;

      return {
        [HEADERS_KEYS.gameCode]: game.config.gameCode,
        [HEADERS_KEYS.adminAccount]: gameCreator,
        [HEADERS_KEYS.playersCount]: playersCount.toString(),
        [HEADERS_KEYS.startTime]: moment.unix(game.config.date).format(),
      };
    },
    [incrementCurrentProgressState]
  );

  const onExportData = useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, isLoading: true }));

      const gamesCollection = await fetchCollection('games');
      setFinalProgressState(gamesCollection.docs.length);

      const gameChunks = arrayToChunks(gamesCollection.docs, 200);
      const gameData: IExportGame[] = [];

      for (const chunk of gameChunks) {
        const chunkGames = await Promise.all(chunk.map(mapGameToExport));
        gameData.push(...chunkGames);
      }

      if (gameData) {
        setState((prev) => ({
          ...prev,
          progress: INIT_STATE.progress,
          isLoading: false,
          exportData: gameData,
        }));
      }

      setTimeout(() => {
        csvLinkRef.current?.link.click();
      });
    } catch (error) {
      setState((prev) => ({ ...prev, isLoading: false }));
      console.error(error);
    }
  }, [mapGameToExport, setFinalProgressState]);

  return {
    ...state,
    csvLinkRef,
    isOpenDownloadWindow,
    onExportData,
  };
};
