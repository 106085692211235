import { TGameModName } from '@avid/common';

import { TGameModFlag } from './games-upload.typing';

export const FLAG_MOD_MAP: Record<TGameModFlag, TGameModName> = {
  isBonusCodes: 'bonusCodes',
  isGauges: 'gauges',
  isCharityHobby: 'charityHobby',
  isGameReport: 'gameReport',
  isGaugesHard: 'gaugesHard',
  isLifeGoals: 'lifeGoals',
  isMarriage: 'marriage',
  isPopupTips: 'popupTips',
  isSpecialJobs: 'specialJobs',
  isFinancial: 'financial',
  isLimitedEducation: 'limitedEducation',
  isLimitedHighestJobs: 'limitedHighestJobs',
};

export const MOD_CONFIG_PARAM_MAP = {
  gauges: 'gaugesIntervalSeconds',
  specialJobs: 'specialJobsRound',
  marriage: 'marriageRound',
  limitedEducation: 'limitedEducationMaxPlayers',
  limitedHighestJobs: 'limitedHighestJobsMaxPlayers',
} as const;

export const VALIDATION_RULES = {
  GAME_CODE_MIN: 100000,
  GAME_CODE_MAX: 999999,
  ROUNDS_MAX: 10000,
  STAT_MAX: 100_000_000_000,
  STAT_MIN: -100_000_000_000,
  START_EDUCATION_ORDER_MIN: 0,
  START_EDUCATION_ORDER_MAX: 3,
} as const;

export const DEFAULT_VALUES = {
  roundsNumber: 10,
  roundDuration: 600,
  account: 3000,
  energy: 20,
  minimalMoney: -20000,
  startEducationOrder: 0,
} as const;

export const CHECK_FIELDS: TGameModFlag[] = [
  'isBonusCodes',
  'isGauges',
  'isGaugesHard',
  'isPopupTips',
  'isLifeGoals',
  'isCharityHobby',
  'isGameReport',
  'isSpecialJobs',
  'isMarriage',
  'isFinancial',
  'isLimitedEducation',
  'isLimitedHighestJobs',
];

export const MOD_ENABLED_VALUE = 'TRUE';
