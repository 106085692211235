import { useRef } from 'react';

import { useRequest } from 'services/hooks';
import { callableFunctionsService } from 'services/api';
import { getGameIdentity } from 'services/utils';

export interface ILogsCSVModalProps {
  gameCode: string;
  onClose: () => void;
}

export const useLogsCSVModalState = (props: ILogsCSVModalProps) => {
  const { gameCode, onClose } = props;

  const { isLoading, isError, callbackRequest } = useRequest();

  const fileLinkRef = useRef<HTMLAnchorElement>(null);

  const onConfirm = callbackRequest(async () => {
    const logFileUrl = await callableFunctionsService.getGameLogs(
      getGameIdentity(gameCode)
    );

    const fileLinkCurrent = fileLinkRef.current;

    if (!fileLinkCurrent) {
      return;
    }

    fileLinkCurrent.download = 'logs.csv';
    fileLinkCurrent.href = logFileUrl;
    fileLinkCurrent.click();

    onClose();
  });

  return { isLoading, isError, fileLinkRef, onConfirm };
};
