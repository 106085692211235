import { entriesObject } from '@avid/common';

import { IJobSalaryCsv } from 'typings/constants-uploading.typing';
import { clientFirebase } from '../firebase';

import { VersionCsvUploader } from './version-csv-uploader';

export class JobSalariesCsvUploader extends VersionCsvUploader {
  parseRows(csvRows: IJobSalaryCsv[]) {
    return csvRows.reduce(
      (acc, row) => {
        const { sector, work, salary } = row;

        if (!acc[sector]) {
          acc[sector] = {};
        }

        acc[sector][work] = +salary;

        return acc;
      },
      {} as Record<string, Record<string, number>> // sector -> work -> salary
    );
  }

  upload(sectorsJobSalaries: Record<string, Record<string, number>>) {
    return Promise.all(
      entriesObject(sectorsJobSalaries).map(([sector, jobSalaries]) =>
        Promise.all(
          entriesObject(jobSalaries).map(([job, salary]) =>
            clientFirebase.firestore.versions.sectors.setWorkJobSalary(
              {
                version: this.version,
                sector,
                job,
              },
              { salary }
            )
          )
        )
      )
    );
  }
}
