import styled from 'styled-components';

import { Span } from 'components/typography';

import { COLORS } from 'theme';

export const RoundTimerStyles = {
  Root: styled.div`
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: ${COLORS.white};
  `,
  ProgressChildrenWrapper: styled.div`
    margin-bottom: 44px;
    display: grid;
    justify-items: center;
    row-gap: 16px;
  `,
  RoundNumber: styled(Span)<{ isOpacity: boolean }>`
    color: ${COLORS.violet};
    ${({ isOpacity }) => isOpacity && 'opacity: 0.3;'}
  `,
};
