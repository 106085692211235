import { IGameMods } from '@avid/common';

import { VALIDATION_STATE } from './new-game.constants';
import { IConfigGameModParams } from './new-game.typing';

const DURATION_PATTERN = /^[0-9][0-9]:[0-2][0-9]:[0-5][0-9]$/;

export const getValidationState = (isDerivative: boolean) => {
  if (!isDerivative) {
    return VALIDATION_STATE;
  }

  return {
    ...VALIDATION_STATE,
    gameTitle: false,
    description: false,
    version: false,
  };
};

export const validateDuration = (duration: string) => {
  if (!duration.match(DURATION_PATTERN)) {
    return false;
  }

  const hr = Number(duration.split(':')[1]);
  return hr <= 23;
};

export const durationToSeconds = (duration: string) => {
  const [day, hr, min] = duration.split(':').map((param) => +param);
  return day * 86400 + hr * 3600 + min * 60;
};

export const getValidValueCb = (
  roundsNumber: number,
  roundDuration: number
) => <T extends keyof IGameMods>(params: IConfigGameModParams<T>) => {
  const { modName, prop, value } = params;

  switch (modName) {
    case 'gauges': {
      switch (prop) {
        case 'intervalSeconds': {
          if (+value < 1) {
            return '';
          }
          if (+value > roundDuration) {
            return roundDuration - 1;
          }
          return +value;
        }

        default: {
          return value;
        }
      }
    }

    case 'specialJobs':
    case 'marriage': {
      switch (prop) {
        case 'round': {
          if (+value < 1) {
            return '';
          }
          if (+value > roundsNumber) {
            return roundsNumber;
          }
          return +value;
        }

        default: {
          return value;
        }
      }
    }

    default: {
      return value;
    }
  }
};
