import { Suspense } from 'react';

import { Paragraph } from './typography';

interface IProps {
  children: React.ReactNode;
}

export const LazyLoader = (props: IProps) => {
  const { children } = props;

  return (
    <Suspense fallback={<Paragraph>Loading...</Paragraph>}>{children}</Suspense>
  );
};
