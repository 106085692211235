import { Redirect, RouteComponentProps } from 'react-router-dom';

import { ActiveGameButtons } from 'components/active-game-buttons';
import { ActiveGamePanel } from 'components/active-game-panel';
import { Paragraph } from 'components/typography';
import { IDS } from 'screens/home/home.constants';
import { STRINGS } from 'constants/string';
import { ROUTES } from 'constants/routes';

import { GameSettings, Modals } from './components';

import { useGameState } from './active-game.state';
import { ActiveGameStyle as Styles } from './active-game.style';
import { useReduxSelector } from 'services/hooks';

const { endGameButton } = STRINGS.ACTIVE_GAME;
const { END_GAME, ROUND_BUTTON } = IDS.BUTTONS;

interface IMatchParams {
  gameCode: string;
}

export const ActiveGameGame = (props: IMatchParams) => {
  const { gameCode } = props;

  const {
    isDisabled,
    timeElapsed,
    isSettings,
    openModal,
    buttonProps,
    buttons,
    rounds,
    config,

    endRoundAsync,
    updateElapsedTime,
    confirmEndRound,
    onEndGame,
    onOpenModal,
    onCloseModal,
  } = useGameState(gameCode);

  const statusLabel = rounds?.length
    ? 'is in progress'
    : 'has not been started';

  const RenderTab = isSettings ? (
    <GameSettings gameCode={gameCode} />
  ) : (
    <>
      <Paragraph typography="RalewayBold24">Your game {statusLabel}</Paragraph>

      <Styles.ActiveGamePanelWrapper>
        <ActiveGamePanel
          rounds={rounds}
          gameCode={gameCode}
          gameTitle={config.gameTitle}
          roundDuration={config.roundDuration}
          roundsNumber={+config.roundsNumber}
          timeElapsed={timeElapsed}
          endRound={endRoundAsync}
          addTimeElapsed={updateElapsedTime}
        />
      </Styles.ActiveGamePanelWrapper>

      <Styles.BottomButtonsContainer>
        <Styles.Button
          id={END_GAME}
          text={endGameButton}
          onClick={onOpenModal('end')}
          isLightTheme
        />

        <Styles.Button
          id={ROUND_BUTTON}
          testId={ROUND_BUTTON}
          isDisabled={isDisabled}
          {...buttonProps}
        />
      </Styles.BottomButtonsContainer>
    </>
  );

  return (
    <>
      <Modals
        gameCode={gameCode}
        gameVersion={config.version}
        openModal={openModal}
        onCloseModal={onCloseModal}
        onConfirmEndGame={onEndGame}
        onConfirmStopRound={confirmEndRound}
        isStopRoundDisabled={isDisabled}
      />

      <Styles.Tab>
        <ActiveGameButtons buttons={buttons} />

        <Styles.TabWrapper>{RenderTab}</Styles.TabWrapper>
      </Styles.Tab>
    </>
  );
};

export const ActiveGame = (props: RouteComponentProps<IMatchParams>) => {
  const { match } = props;

  const gameCode = match.params.gameCode;

  const game = useReduxSelector((redux) =>
    redux.main.games.find((g) => g.config.gameCode === gameCode)
  );

  if (!game) {
    return <Redirect to={ROUTES.START} />;
  }

  if (game.status === 'finished') {
    return <Redirect to={`${ROUTES.STATISTIC}/${gameCode}`} />;
  }

  return <ActiveGameGame gameCode={gameCode} />;
};
