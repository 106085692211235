import { ChangeEventHandler } from 'react';
import styled from 'styled-components';

import { Theme } from 'theme';

interface IProps {
  label: string;
  maxPlayers: number;
  onChangeGameModConfig: (prop: string, value: string | number) => void;
  ending?: string;
}

const Styles = {
  Input: styled.input`
    width: 40px;
  `,
};

export const LimitedPlayersModSettings = (props: IProps) => {
  const { label, maxPlayers, ending, onChangeGameModConfig } = props;

  const onChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    onChangeGameModConfig('maxPlayers', +ev.target.value);
  };

  return (
    <Theme.Paragraph>
      Restrict {label} to{' '}
      <Styles.Input type="number" value={maxPlayers} onChange={onChange} />{' '}
      players {ending}
    </Theme.Paragraph>
  );
};
