

import { Filter } from './components/filter';

import { useFiltersState } from './filters.state';
import { BUTTONS } from './filters.constants';
import { FiltersStyles as Styles } from './filters.styles';
import { IFiltersProps } from './filters.typing';

const { APPLY, CLEAR } = BUTTONS;

export const Filters = (props: IFiltersProps) => {
  const { isShow } = props;

  const {
    isMounted,
    email,
    round,
    onChangeEmail,
    onChangeRound,
    onClear,
    onSubmit,
  } = useFiltersState(props);

  return (
    <Styles.Root isShow={isShow} isMounted={isMounted} onSubmit={onSubmit}>
      <div>
        <Filter
          label="Email"
          id="email"
          inputType="text"
          value={email}
          onChange={onChangeEmail}
        />
        <Filter
          label="Round"
          id="round"
          variant="short"
          value={round}
          onChange={onChangeRound}
        />
      </div>

      <Styles.ButtonContainer>
        <Styles.Button
          type="button"
          text={CLEAR}
          isLightTheme
          onClick={onClear}
        />
        <Styles.Button text={APPLY} />
      </Styles.ButtonContainer>
    </Styles.Root>
  );
};
