import { entriesObject } from '@avid/common';

import { clientFirebase } from '../firebase';

import { VersionCsvUploader } from './version-csv-uploader';

export class ValuesCsvUploader extends VersionCsvUploader {
  parseRows(csvRows: Record<string, string>[]) {
    return csvRows.reduce(
      (acc, row) => {
        for (const [column, value] of entriesObject(row)) {
          if (!acc[column]) {
            acc[column] = [];
          }

          if (value) {
            acc[column].push(value);
          }
        }

        return acc;
      },
      {} as Record<string, string[]>
    );
  }

  upload(values: Record<string, string[]>) {
    return clientFirebase.firestore.versions.setValues(this.version, { values });
  }
}
