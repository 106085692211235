import { memo } from 'react';
import moment from 'moment';

import { IDS } from 'screens/home/home.constants';
import { COLORS } from 'theme/colors';
import { STRINGS } from 'constants/string';
import { formatDuration, getNumberPostfix } from 'services/utils';

import { ScaleStyles } from 'components/progress-bar/progress-bar.styles';

import { IRoundProps } from './round.typing';

const { ROUND_STATUS, ROUND } = IDS.CONTAINERS;

export const Round = (props: IRoundProps) => {
  const { round, roundDuration, roundStatus } = props;

  const { roundStr, statusStr, durationStr, min } = STRINGS.ACTIVE_GAME;

  const statusComponent = roundStatus && (
    <ScaleStyles.Text
      ml={'5px'}
      color={(roundStatus !== 'ended' && COLORS.yellow) || COLORS.green}
    >
      {roundStatus}
    </ScaleStyles.Text>
  );

  const roundName = `${getNumberPostfix(round)} ${roundStr}`;
  const format = formatDuration(roundDuration || 0);
  const roundTime = moment.utc((roundDuration || 0) * 1000).format(format);
  const time = `${durationStr} ${roundTime} ${min}`;

  return (
    <ScaleStyles.ChainContainer data-testid={`${ROUND}-${round}`}>
      <ScaleStyles.Chain>
        <ScaleStyles.InfoContainer>
          <div>
            <ScaleStyles.Text isBold>{roundName}</ScaleStyles.Text>

            <ScaleStyles.TextRow id={`${ROUND_STATUS}-${round}`}>
              <ScaleStyles.Text>{statusStr}</ScaleStyles.Text>
              {statusComponent}
            </ScaleStyles.TextRow>

            {roundStatus === 'ended' && (
              <ScaleStyles.Text>{time}</ScaleStyles.Text>
            )}
          </div>

          <div>
            <ScaleStyles.Dot />
            <ScaleStyles.Line />
          </div>
        </ScaleStyles.InfoContainer>
      </ScaleStyles.Chain>
    </ScaleStyles.ChainContainer>
  );
};

export const RoundMemo = memo(Round);
