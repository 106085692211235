import * as Sentry from '@sentry/react';

import GlobalFonts from 'theme/fonts';
import { Routes } from '../routes/routes';

import { useMainState } from './main.state';

export const App = () => {
  const { isLoading } = useMainState();

  if (!isLoading) {
    return (
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <div className="App">
          <GlobalFonts />
          <Routes />
        </div>
      </Sentry.ErrorBoundary>
    );
  }
  return null;
};
