import { IFilters } from 'screens/players-history';

export const INIT_STATE: IFilters = {
  email: '',
  round: 0,
};

export const BUTTONS = {
  APPLY: 'Apply',
  CLEAR: 'Clear',
} as const;
