import { FC, ReactNode } from 'react';

import { TIconNames } from 'components/icons';

import { TextInputStyle } from './text-input.styles';
import { Theme } from 'theme';

export interface ITextInput {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  title?: string;
  errorMessage?: string;
  placeholder?: string;
  children?: ReactNode;
  className?: string;
  height?: string;
  name?: string;
  type?: string;
  mask?: string | RegExp[];
  isError?: boolean;
  value?: string | number;
  disabled?: boolean;
  icon?: TIconNames;
}
export const TextInput: FC<ITextInput> = (props) => {
  const {
    title,
    errorMessage,
    placeholder,
    className,
    onChange,
    name,
    type,
    mask,
    isError,
    value,
    disabled,
    children,
    icon,
  } = props;

  return (
    (!mask && (
      <TextInputStyle.Label className={className}>
        {title && <Theme.ControlTitle>{title}</Theme.ControlTitle>}

        <TextInputStyle.InputDiv>
          {icon && <TextInputStyle.Icon type={icon} />}

          <TextInputStyle.Input
            className="input"
            isError={isError}
            name={name}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            value={value}
            disabled={disabled || false}
            isImage={!!icon}
          >
            {children}
          </TextInputStyle.Input>
        </TextInputStyle.InputDiv>
        {errorMessage && (
          <Theme.ErrorMessage>{errorMessage}</Theme.ErrorMessage>
        )}
      </TextInputStyle.Label>
    )) || (
      <TextInputStyle.Label className={className}>
        {title && <Theme.ControlTitle>{title}</Theme.ControlTitle>}
        <TextInputStyle.MaskedInput
          maskChar=" "
          mask={mask || '99999999'}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled || false}
          isError={isError || false}
        />
        {errorMessage && (
          <Theme.ErrorMessage>{errorMessage}</Theme.ErrorMessage>
        )}
      </TextInputStyle.Label>
    )
  );
};
