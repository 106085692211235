import { ITrainerLimits } from '@avid/common';

const checkDate = (expirationDateString: string) => {
  if (expirationDateString === 'unlimited') {
    return true;
  }

  const nowDate = new Date();
  const expirationDate = new Date(expirationDateString);
  expirationDate.setDate(expirationDate.getDate() + 1);

  return nowDate <= expirationDate;
};

export const checkLimits = (limits: ITrainerLimits) => {
  const { gamesUsed, expirationDate, numberOfGames } = limits;

  const gamesLeft =
    numberOfGames === 'unlimited' ? 1 : +numberOfGames - +gamesUsed;

  return checkDate(expirationDate) && gamesLeft > 0;
};
