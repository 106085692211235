import { entriesObject } from '@avid/common';

import { callGameAdminFunction, GAME_ADMIN_ACTIONS } from 'services/api';
import { keyofObject } from 'services/object';
import { getGameIdentity } from 'services/utils';
import { TDBSectors } from 'typings/games';
import {
  ISectorsSettings,
  TSectorSettings,
  TWorkLimitParam,
} from 'typings/sectors-settings';

export const getSectorSettings = (dbSectors: TDBSectors): TSectorSettings => {
  const entries = entriesObject(dbSectors);

  const sectorItems = entries.reduce((acc, [sector, data]) => {
    acc[sector] = {
      limit: { value: +data.limit, isChanged: false },
      salary: {
        value: +((data.salary || 1) * 100).toFixed(),
        isChanged: false,
      },
      employeesCount: data.players ? keyofObject(data.players).length : 0,
    };

    return acc;
  }, {} as TSectorSettings);

  return sectorItems;
};

export const unchangeSectorSettings = (sectorItems: TSectorSettings) =>
  entriesObject(sectorItems).reduce((acc, [sector, settings]) => {
    acc[sector] = {
      ...settings,
      salary: { ...settings.salary, isChanged: false },
      limit: { ...settings.limit, isChanged: false },
    };

    return acc;
  }, {} as TSectorSettings);

export const firePlayers = async (
  gameCode: string,
  entries: [string, ISectorsSettings][]
) => {
  const limits = entries.reduce(
    (acc, [sector, settings]) => {
      acc[sector] = settings.limit.value;

      return acc;
    },
    {} as Record<string, number>
  );

  try {
    await callGameAdminFunction(
      getGameIdentity(gameCode),
      GAME_ADMIN_ACTIONS.FIRE_PLAYERS,
      { limits }
    );
  } catch (error) {
    console.error(error);
  }
};

export const updateSalaries = (
  gameCode: string,
  entries: [string, ISectorsSettings][]
) => {
  const changed = entries.filter(([, settings]) => settings.salary.isChanged);

  if (!changed.length) {
    return;
  }

  const mapped: [string, number][] = changed.map(([sector, settings]) => [
    sector,
    +(settings.salary.value * 0.01).toFixed(1),
  ]);

  return callGameAdminFunction(
    getGameIdentity(gameCode),
    GAME_ADMIN_ACTIONS.UPDATE_SALARIES,
    { sectorSalaries: mapped }
  );
};

export const getSelectionStateKey = (selection: TWorkLimitParam) =>
  selection === 'limit' ? 'selectedLimits' : 'selectedSalaries';
