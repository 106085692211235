import styled from 'styled-components';
import InputMask from 'react-input-mask';

import { Icon } from 'components/icons';

import { FONT_SIZE } from 'theme/font-size';
import { FONTS } from 'theme/fonts';
import { COLORS } from 'theme/colors';

interface IInput {
  isError?: boolean;
  isImage?: boolean;
}

export const TextInputStyle = {
  Label: styled.label`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    position: relative;
  `,
  InputDiv: styled.label`
    display: flex;
    align-items: center;
    font-size: 14px;
    position: relative;
  `,
  Input: styled.input<IInput>`
    background: ${COLORS.white};
    box-shadow: 2px 6px 16px rgba(45, 45, 55, 0.1);
    border-radius: 10px;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: ${({ isError }) =>
      (isError && `1px solid ${COLORS.red}`) || 'none'};
    padding-left: ${({ isImage }) => (isImage && '45px') || '16px'};
    padding-right: 16px;
    line-height: 20px;
    font-size: ${FONT_SIZE.label};
    &::-webkit-input-placeholder {
      color: ${COLORS.black};
      font-size: ${FONT_SIZE.label};
      font-family: ${FONTS.regular};
    }
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
    &:focus {
      outline: none;
    }
  `,
  MaskedInput: styled(InputMask)<{ isError?: boolean }>`
    background: ${COLORS.white};
    box-shadow: 2px 6px 16px rgba(45, 45, 55, 0.1);
    border-radius: 10px;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: ${({ isError }) =>
      (isError && `1px solid ${COLORS.red}`) || 'none'};
    padding-left: 16px;
    padding-right: 16px;
    line-height: 20px;
    font-size: ${FONT_SIZE.label};
    &::-webkit-input-placeholder {
      color: ${COLORS.black};
      font-size: ${FONT_SIZE.label};
      font-family: ${FONTS.regular};
    }
    &:focus::-webkit-input-placeholder {
      color: transparent;
    }
    &:focus {
      outline: none;
    }
  `,
  Icon: styled(Icon)`
    position: absolute;
    left: 15px;
    cursor: pointer;
  `,
};
