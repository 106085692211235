import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'react-circular-progressbar/dist/styles.css';

import { App } from './screens/main/main';
import { store } from 'services/redux';

import './index.css';

Sentry.init({
  dsn: 'https://d829d0d75ecb492399ad559ee6407bbd@o542719.ingest.sentry.io/5668153',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV === 'development' ? 'local' : 'development',
  tracesSampleRate: 1.0,
});
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
