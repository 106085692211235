import styled from 'styled-components';

import { Icon } from 'components/icons';
import { COLORS, FONTS, FONT_SIZE } from 'theme';

export const SignInStyled = {
  Title: styled.p`
    text-align: center;
    width: 330px;
    font-family: ${FONTS.bold};
    font-size: 20px;
    color: ${COLORS.white};
    margin-bottom: 30px;
  `,
  Tip: styled.p`
    text-align: center;
    font-size: ${FONT_SIZE.desktopMiddle};
    color: ${COLORS.white};

    a {
      font: inherit;
      color: inherit;
    }
  `,
  Icon: styled(Icon)`
    width: 225px;
  `,
};
