import { ReactNode } from 'react';

import { Sidebar } from 'components/sidebar';
import { Header } from 'components/header';
import { Navigation } from 'containers/navigation';

import { MenuWrapperStyle as Styles } from './menu-wrapper.styles';

interface IProps {
  children?: ReactNode;
}

export const MenuWrapper = (props: IProps) => {
  const { children } = props;

  return (
    <Styles.Root>
      <Sidebar />

      <Styles.ContentWrapper>
        <Header />

        <Styles.NavigationWrapper>
          <Navigation />
        </Styles.NavigationWrapper>

        <div>{children}</div>
      </Styles.ContentWrapper>
    </Styles.Root>
  );
};
