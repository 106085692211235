
import { BoxesStyle } from './boxes.style';
import { COLORS } from 'theme';

export interface IBoxes {
  title: string | number;
  value: string | number;
}

export const Boxes = ({ title, value }: IBoxes) => {
  return (
    <BoxesStyle.Box>
      <BoxesStyle.BoxText>{title}</BoxesStyle.BoxText>
      <BoxesStyle.BoxText color={COLORS.lightRed}>{value}</BoxesStyle.BoxText>
    </BoxesStyle.Box>
  );
};
