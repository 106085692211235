import { Modal } from 'components/modal';

import { ConfirmModal, ShareGameModal, WorkLimitsModal } from './components';

import { IModalsProps } from './modals.typing';

export const Modals = (props: IModalsProps) => {
  const {
    gameCode,
    gameVersion,
    openModal,
    isStopRoundDisabled,

    onConfirmEndGame,
    onConfirmStopRound,
    onCloseModal,
  } = props;

  return (
    <>
      <WorkLimitsModal
        isOpen={openModal === 'limits'}
        gameCode={gameCode}
        gameVersion={gameVersion}
        onClose={onCloseModal}
      />

      <Modal
        isOpen={openModal === 'end'}
        onCloseModal={onCloseModal}
        isCenterContent={true}
      >
        <ConfirmModal
          isRound={false}
          onConfirm={onConfirmEndGame}
          onCancel={onCloseModal}
        />
      </Modal>

      <Modal
        isOpen={openModal === 'end-round'}
        onCloseModal={onCloseModal}
        isCenterContent={true}
      >
        <ConfirmModal
          isRound
          isConfirmDisabled={isStopRoundDisabled}
          onConfirm={onConfirmStopRound}
          onCancel={onCloseModal}
        />
      </Modal>

      <Modal
        isOpen={openModal === 'share'}
        onCloseModal={onCloseModal}
        isCenterContent={true}
      >
        <ShareGameModal gameCode={gameCode} onClose={onCloseModal} />
      </Modal>
    </>
  );
};
