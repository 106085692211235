import { ILogsTableState } from './logs-table.typing';

export const INIT_STATE: ILogsTableState = {
  isLoading: true,
  isUpdating: false,
  isError: false,
  isShowFilters: false,
  isEmpty: false,
  logs: [],
};

export const DEFAULT_SORT = [
  {
    id: 'created',
    desc: true,
  },
];

export const INIT_TABLE_STATE = {
  pageSize: 10,
  sortBy: DEFAULT_SORT,
  hiddenColumns: [
    'playerName',
    'characterName',
    'work',
    'health',
    'play',
    'love',
    'playerReferrals',
  ],
};

export const BUTTONS = {
  FILTERS: 'Filters',
};

export const STRINGS = {
  ERROR: 'An error has occurred.',
  UPDATING: 'Updating...',
  SORT: 'Sort by',
};
