import { IFilters } from 'screens/players-history';
import { IFilterOptions, IInputFilterOption } from 'services/api';

export const mapFiltersToFilterOptions = (
  filtersObj: IFilters
): IFilterOptions => {
  const filterOptions: IFilterOptions = {};

  const pushInput = (inputOption: IInputFilterOption) => {
    if (!filterOptions.inputs) {
      filterOptions.inputs = [];
    }

    filterOptions.inputs.push(inputOption);
  };

  if (filtersObj.email) {
    pushInput({ field: 'playerEmail', value: filtersObj.email });
  }

  if (filtersObj.round) {
    pushInput({ field: 'round', value: filtersObj.round });
  }

  return filterOptions;
};
