"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VersionsFirestore = void 0;
var SectorsFirestore = /** @class */ (function () {
    function SectorsFirestore(rawFirestore) {
        this.rawFirestore = rawFirestore;
        // construct
    }
    SectorsFirestore.prototype.getSkills = function (version, sector) {
        return this.rawFirestore.getSectorSkills(version, sector);
    };
    SectorsFirestore.prototype.setSkills = function (version, sector, skills) {
        return this.rawFirestore.setSectorSkills(version, sector, skills);
    };
    SectorsFirestore.prototype.getWorkCareerTree = function (version, sector) {
        return this.rawFirestore.getCareerTree(version, sector);
    };
    SectorsFirestore.prototype.setWorkCareerTree = function (version, sector, careerTree) {
        return this.rawFirestore.setCareerTree(version, sector, careerTree);
    };
    SectorsFirestore.prototype.getWorkCompanies = function (version, sector) {
        return this.rawFirestore.getCompanies(version, sector);
    };
    SectorsFirestore.prototype.setWorkCompanies = function (version, sector, companies) {
        return this.rawFirestore.setCompanies(version, sector, companies);
    };
    SectorsFirestore.prototype.getWorkHighestPositions = function (version, sector) {
        return this.rawFirestore.getHighestPositions(version, sector);
    };
    SectorsFirestore.prototype.setWorkHighestPositions = function (version, sector, highestPositions) {
        return this.rawFirestore.setHighestPositions(version, sector, highestPositions);
    };
    SectorsFirestore.prototype.getWorkJobDescription = function (params) {
        return this.rawFirestore.getJobDescription(params);
    };
    SectorsFirestore.prototype.setWorkJobDescription = function (params, data) {
        return this.rawFirestore.setJobDescription(params, data);
    };
    SectorsFirestore.prototype.getWorkJobSalary = function (params) {
        return this.rawFirestore.getJobSalary(params);
    };
    SectorsFirestore.prototype.setWorkJobSalary = function (params, data) {
        return this.rawFirestore.setJobSalary(params, data);
    };
    SectorsFirestore.prototype.getWorkJobQuestions = function (params) {
        return this.rawFirestore.getJobQuestions(params);
    };
    SectorsFirestore.prototype.setWorkJobQuestions = function (params, data) {
        return this.rawFirestore.setJobQuestions(params, data);
    };
    SectorsFirestore.prototype.getWorkJobSkills = function (params) {
        return this.rawFirestore.getJobSkills(params);
    };
    SectorsFirestore.prototype.setWorkJobSkills = function (params, data) {
        return this.rawFirestore.setJobSkills(params, data);
    };
    return SectorsFirestore;
}());
var VersionsFirestore = /** @class */ (function () {
    function VersionsFirestore(rawFirestore) {
        this.rawFirestore = rawFirestore;
        this.sectors = new SectorsFirestore(rawFirestore);
    }
    VersionsFirestore.prototype.getVersion = function (version) {
        return this.rawFirestore.getVersion(version);
    };
    VersionsFirestore.prototype.updateVersion = function (version, data) {
        return this.rawFirestore.updateVersion(version, data);
    };
    VersionsFirestore.prototype.listenVersion = function (version, onNext) {
        return this.rawFirestore.listenVersion(version, onNext);
    };
    VersionsFirestore.prototype.updateLifeCards = function (version, data) {
        return this.rawFirestore.updateLifeCards(version, data);
    };
    VersionsFirestore.prototype.getAllSkills = function (version) {
        return this.rawFirestore.getAllSkills(version);
    };
    VersionsFirestore.prototype.updateAllSkills = function (version, data) {
        return this.rawFirestore.updateAllSkills(version, data);
    };
    VersionsFirestore.prototype.getValues = function (version) {
        return this.rawFirestore.getValues(version);
    };
    VersionsFirestore.prototype.setValues = function (version, data) {
        return this.rawFirestore.setValues(version, data);
    };
    VersionsFirestore.prototype.getCompanyValues = function (version) {
        return this.rawFirestore.getCompanyValues(version);
    };
    VersionsFirestore.prototype.setCompanyValues = function (version, data) {
        return this.rawFirestore.setCompanyValues(version, data);
    };
    VersionsFirestore.prototype.getEducationLevels = function (version) {
        return this.rawFirestore.getEducationLevels(version);
    };
    VersionsFirestore.prototype.updateEducationLevels = function (version, data) {
        return this.rawFirestore.updateEducationLevels(version, data);
    };
    VersionsFirestore.prototype.clearEducationQuestions = function (version) {
        return this.rawFirestore.clearEducationQuestions(version);
    };
    VersionsFirestore.prototype.getEducationQuestions = function (version, part) {
        return this.rawFirestore.getEducationQuestions(version, part);
    };
    VersionsFirestore.prototype.setEducationQuestions = function (version, part, data) {
        return this.rawFirestore.setEducationQuestions(version, part, data);
    };
    VersionsFirestore.prototype.clearQuestionsAmounts = function (version) {
        return this.rawFirestore.clearQuestionsAmounts(version);
    };
    VersionsFirestore.prototype.getQuestionsAmount = function (version, study) {
        return this.rawFirestore.getQuestionsAmount(version, study);
    };
    VersionsFirestore.prototype.setQuestionsAmount = function (version, study, data) {
        return this.rawFirestore.setQuestionsAmount(version, study, data);
    };
    return VersionsFirestore;
}());
exports.VersionsFirestore = VersionsFirestore;
