export const HEADERS_KEYS = {
  gameCode: 'gameCode',
  adminAccount: 'adminAccount',
  playersCount: 'playersCount',
  startTime: 'startTime',
} as const;

export const HEADERS = [
  { label: 'Game code', key: HEADERS_KEYS.gameCode },
  { label: 'Admin account', key: HEADERS_KEYS.adminAccount },
  { label: 'Number of players', key: HEADERS_KEYS.playersCount },
  { label: 'Time started', key: HEADERS_KEYS.startTime },
];

export const DATE_FORMAT = 'MMM DD h:mm A';
