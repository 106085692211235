

import { TIconNames } from 'components/icons';

import { ROUTES } from 'constants/routes';
import { STRINGS } from 'constants/string';
import { SidebarLayout } from 'components/sidebar-layout';

const buttons = STRINGS.SIDEBAR.buttons;

const BUTTONS_BAR = [
  { type: 'home' as TIconNames, name: buttons.home, route: ROUTES.START },
  {
    type: 'pieGraph' as TIconNames,
    name: buttons.gameStats,
    route: ROUTES.STATISTIC,
  },
  {
    type: 'players' as TIconNames,
    name: buttons.players,
    route: ROUTES.PLAYERS,
  },
  {
    type: 'playersHistory' as TIconNames,
    name: buttons.playersHistory,
    route: ROUTES.PLAYERS_HISTORY,
  },
];

export const Sidebar = () => {
  return <SidebarLayout buttons={BUTTONS_BAR} />;
};
