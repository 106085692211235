import styled from 'styled-components';
import { FONTS, FONT_SIZE } from 'theme';

import { COLORS } from 'theme/colors';

export const AuthStyles = {
  Label: styled.p`
    color: ${COLORS.white};
    font-size: ${FONT_SIZE.desktopMiddle};
  `,
  ErrorMessage: styled.p`
    margin-top: 16px;
    font-family: ${FONTS.regular};
    font-size: ${FONT_SIZE.desktopMiddle};
    color: ${COLORS.white};
    font-weight: 700;
    line-height: 1.01;
  `,
};
