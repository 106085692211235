
import styled from 'styled-components';

import { Button, IButtonProps } from 'components/button';

const StyledButton = styled(Button)`
  width: 200px;
  padding: 16px;
  ${({ isDisabled }) => isDisabled && 'opacity: 0.7;'}
`;

export const TableButton = (props: IButtonProps) => <StyledButton {...props} />;
