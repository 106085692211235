import { useRef } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/icons';
import { Grid } from 'components/UI';
import { typographyCss } from 'theme';

const Styles = {
  Picker: styled(Grid)`
    width: 415px;
    height: 145px;
    border-radius: 20px;
    justify-content: center;
    justify-items: center;
    align-content: center;
    row-gap: 12px;
    border: 2px solid #fff;
    box-shadow: 0px 20px 70px 0px rgba(86, 89, 146, 0.1);
    background: rgba(255, 255, 255, 0.3);
  `,
  TextWrapper: styled(Grid)`
    align-content: center;
  `,
  Link: styled.span`
    ${typographyCss.QuicksandMedium16}
    color: #00AEEF;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  `,
  Input: styled.input`
    visibility: hidden;
    height: 1px;
  `,
};

interface IProps {
  onChangeFile: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  fileName?: string;
}

export const FilePicker = (props: IProps) => {
  const { fileName, onChangeFile } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onClickLink = () => inputRef.current?.click();

  return (
    <Styles.Picker>
      <Icon type="dragNDrop" />

      <Styles.TextWrapper rowGap="0">
        <Styles.Link onClick={onClickLink}>{fileName ?? 'Browse'}</Styles.Link>

        <Styles.Input
          id="games-file-input"
          type="file"
          accept=".csv"
          ref={inputRef}
          onChange={onChangeFile}
        />
      </Styles.TextWrapper>
    </Styles.Picker>
  );
};
