import styled from 'styled-components';
import { TGameVersion } from '@avid/common';

import { DraggableModal } from 'components/draggable-modal';

import { WorkLimits } from './work-limits';

interface IProps {
  isOpen: boolean;
  gameCode: string;
  gameVersion?: TGameVersion;
  onClose: () => void;
}

const Styles = {
  Root: styled(DraggableModal)`
    & .modal-window {
      height: calc(100vh - 80px);
      max-height: calc(100vh - 80px);
      padding: 24px;
    }
  `,

  VersionError: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  `,
};

export const WorkLimitsModal = (props: IProps) => {
  const { isOpen, gameCode, gameVersion, onClose } = props;

  const RenderContent = gameVersion ? (
    <WorkLimits gameCode={gameCode} onCloseModal={onClose} />
  ) : (
    <Styles.VersionError>
      <p>Unexpected Error</p>
      <p>{"Couldn't get the game version"}</p>
    </Styles.VersionError>
  );

  return (
    <Styles.Root isOpen={isOpen} onCloseModal={onClose}>
      {RenderContent}
    </Styles.Root>
  );
};
