import styled from 'styled-components';

import { Theme } from 'theme';
import { TableButton } from '../table-button';

interface ISortArrowProps {
  isSorted: boolean;
  isSortedDesc?: boolean;
}

export const LogsTableStyles = {
  ButtonsContainer: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  Button: styled(TableButton)`
    margin-left: 12px;
  `,
  UpdatingParagraph: styled(Theme.Paragraph)<{ isVisible: boolean }>`
    margin: 0;
    margin-top: 16px;
    margin-left: 24px;
    display: flex;
    align-items: flex-end;
    ${({ isVisible }) => !isVisible && 'visibility: hidden'};
  `,
  SortArrow: styled.span<ISortArrowProps>`
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 6.6px;
    margin-bottom: 2px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    ${({ isSorted }) => !isSorted && 'visibility: hidden;'}
    ${({ isSortedDesc }) => isSortedDesc && 'transform: scaleY(-1);'}
  `,
  NoDataParagraph: styled(Theme.Paragraph)`
    text-align: center;
    margin: 40px;
  `,
};
