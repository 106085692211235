import styled from 'styled-components';

import { FONTS, COLORS } from 'theme';

interface IBoxText {
  color?: string;
  fontSize?: string;
}

export const BoxesStyle = {
  Box: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 235px;
    margin-right: 32px;
    background: ${COLORS.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 32px;
    padding-bottom: 20px;
  `,
  BoxText: styled.p<IBoxText>`
    font-family: ${FONTS.quicksandBold};
    font-size: ${(props) => props.fontSize || '18px'};
    color: ${(props) => props.color};
    margin-bottom: 0;
  `,
};
