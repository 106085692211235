import { Span } from 'components/typography';
import {
  ISectorsSettingsValue,
  TWorkLimitParam,
} from 'typings/sectors-settings';

import { SectorsSettingsCounter } from '../sectors-settings-counter';

import { SectorsSettingsStyles as Styles } from './sectors-settings-row.styles';
import { SectorsSettingsLimit } from '../sectors-settings-limit';

export interface ISectorsSettingsRowProps {
  sector: string;
  employeesCount: number;
  limit: ISectorsSettingsValue;
  salary: ISectorsSettingsValue;
  isLimitSelected: boolean;
  isSalarySelected: boolean;
  onSelectItem: (selection: TWorkLimitParam) => void;
  onChangeLimit: (limit: number) => void;
  onChangeSalary: (salary: number) => void;
}

export const SectorsSettingsRow = (props: ISectorsSettingsRowProps) => {
  const {
    employeesCount,
    limit,
    sector,
    salary,
    isLimitSelected,
    isSalarySelected,
    onChangeLimit,
    onChangeSalary,
    onSelectItem,
  } = props;

  const onSelectLimit = () => onSelectItem('limit');
  const onSelectSalary = () => onSelectItem('salary');

  return (
    <Styles.Root>
      <Styles.CellLeft>
        <Span typography="QuicksandBold18">{sector}</Span>
      </Styles.CellLeft>

      <Styles.Cell>
        <Span typography="QuicksandRegular18">{employeesCount}</Span>
      </Styles.Cell>

      <Styles.Cell $isContentStart>
        <SectorsSettingsLimit
          value={limit.value}
          isChanged={limit.isChanged}
          isSelected={isLimitSelected}
          onChangeLimit={onChangeLimit}
          onSelect={onSelectLimit}
        />
      </Styles.Cell>

      <Styles.Cell $isContentStart>
        <SectorsSettingsCounter
          value={salary.value}
          isChanged={salary.isChanged}
          isSelected={isSalarySelected}
          onChangeSalary={onChangeSalary}
          onSelect={onSelectSalary}
        />
      </Styles.Cell>
    </Styles.Root>
  );
};
