import { IGame } from '@avid/common';

import { IUser } from 'services/firebase.api';
import { action } from 'services/utils';
import { TCombiner } from 'typings/react';

export const MAIN_ACTION_TYPES = {
  USER_ID: '@MAIN/USER_ID',
  SET_GAMES: '@MAIN/SET_GAMES',
  UPDATE_GAME: '@MAIN/UPDATE_GAME',
  START_FETCH_GAMES: '@MAIN/START_FETCH_GAMES',
  FAIL_FETCH_GAMES: '@MAIN/FAIL_FETCH_GAMES',
  SET_LAST_ACTIVE_GAME_CODE: '@MAIN/SET_LAST_ACTIVE_GAME_CODE',
};

export const MainActions = {
  getUserId: (userId: IUser) => action(MAIN_ACTION_TYPES.USER_ID, userId),
  setUserGames: (games: IGame[]) => action(MAIN_ACTION_TYPES.SET_GAMES, games),
  startFetchGames: () => action(MAIN_ACTION_TYPES.START_FETCH_GAMES),
  failFetchGames: () => action(MAIN_ACTION_TYPES.FAIL_FETCH_GAMES),
  setLastActiveGameCode: (value: boolean) =>
    action(MAIN_ACTION_TYPES.SET_LAST_ACTIVE_GAME_CODE, value),
  updateGame: (gameCode: string, data: Partial<IGame>) =>
    action(MAIN_ACTION_TYPES.UPDATE_GAME, { gameCode, data }),
};

export type TMainActions = ReturnType<TCombiner<typeof MainActions>>;
