import { useState } from 'react';

export const useLogsCSV = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onChange = () => {
    setIsOpen((currentOpen) => !currentOpen);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return { isOpen, onChange, onClose };
};
