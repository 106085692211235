import { ChangeEventHandler, memo } from 'react';
import styled from 'styled-components';

import { keyofObject } from 'services/object';

import { PositionLimitsList } from './position-limits-list';
import { Button } from './button';
import { Paragraph } from './typography';

interface IHook {
  items: IPositionLimit[];
  onAddLimit: () => void;
  setSectorCb: (index: number) => ChangeEventHandler<HTMLSelectElement>;
  setJobCb: (index: number) => ChangeEventHandler<HTMLSelectElement>;
  setLimitCb: TSetPositionLimitParamCb<number>;
  removeLimitCb: (index: number) => () => void;
}

interface IProps extends IHook {
  gameCode: string;
  sectorPositionsMap: Record<string, string[]>;
}

const StyledButton = styled(Button)`
  display: block;
  margin-top: 16px;
  width: 160px;
  height: 56px;
`;

export const PositionLimitsEditor = memo((props: IProps) => {
  const {
    gameCode,
    items,
    onAddLimit,
    removeLimitCb,
    setSectorCb,
    setJobCb,
    setLimitCb,
    sectorPositionsMap,
  } = props;

  const sectors = keyofObject(sectorPositionsMap);

  const filteredItems = items.map((item, index) => ({
    ...item,
    availableJobs: (sectorPositionsMap[item.sector] || []).filter(
      (job) =>
        items.findIndex(
          (findItem, findIndex) =>
            findItem.sector === item.sector &&
            findItem.job === job &&
            findIndex !== index
        ) === -1
    ),
  }));

  return (
    <div>
      <Paragraph typography="RalewaySemiBold20" textAlign="left">
        Specific Job Limits
      </Paragraph>

      <PositionLimitsList
        gameCode={gameCode}
        sectors={sectors}
        items={filteredItems}
        setSectorCb={setSectorCb}
        setJobCb={setJobCb}
        setLimitCb={setLimitCb}
        removeLimitCb={removeLimitCb}
      />

      <StyledButton text="Add New Limit" onClick={onAddLimit} />
    </div>
  );
});
