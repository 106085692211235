import styled from 'styled-components';

import { IComponent } from 'typings';

const getIcon = (path: string) => require(`../assets/icons/${path}`);
const getImage = (path: string) => require(`../assets/images/${path}`);

const USER_ICONS = {
  //* Man icon
  man: getIcon('character/man-icon/man.svg'),
  manAthlete: getIcon('character/man-icon/athlete.svg'),
  manDoctor: getIcon('character/man-icon/doctor.svg'),
  manExecutive: getIcon('character/man-icon/executive.svg'),
  manGraduate: getIcon('character/man-icon/graduate.svg'),
  manHipster: getIcon('character/man-icon/hipster.svg'),
  manPainter: getIcon('character/man-icon/painter.svg'),

  //* Woman icon
  female: getIcon('character/woman-icon/female.svg'),
  womanAthlete: getIcon('character/woman-icon/athlete.svg'),
  womanDoctor: getIcon('character/woman-icon/doctor.svg'),
  womanExecutive: getIcon('character/woman-icon/executive.svg'),
  womanGraduate: getIcon('character/woman-icon/graduate.svg'),
  womanHipster: getIcon('character/woman-icon/hipster.svg'),
  womanPainter: getIcon('character/woman-icon/painter.svg'),

  //* Shared icons
  characterIcon: getIcon('character/character-icon.svg'),
  avatar1: getIcon('character/avatar-1.svg'),
  avatar2: getIcon('character/avatar-2.svg'),
  avatar3: getIcon('character/avatar-3.svg'),
  avatar4: getIcon('character/avatar-4.svg'),
  avatar5: getIcon('character/avatar-5.svg'),
  avatar6: getIcon('character/avatar-6.svg'),
  avatar7: getIcon('character/avatar-7.svg'),
  avatar8: getIcon('character/avatar-8.svg'),
  avatar9: getIcon('character/avatar-9.svg'),
  avatar10: getIcon('character/avatar-10.svg'),
  avatar11: getIcon('character/avatar-11.svg'),
  avatar12: getIcon('character/avatar-12.svg'),
  avatar13: getIcon('character/avatar-13.svg'),
  avatar14: getIcon('character/avatar-14.svg'),
  avatar15: getIcon('character/avatar-15.svg'),
  avatar16: getIcon('character/avatar-16.svg'),
  avatar17: getIcon('character/avatar-17.svg'),
  avatar18: getIcon('character/avatar-18.svg'),
};

const TYPES = {
  logo: getImage('avid-logo.png'),

  user: getIcon('user.svg'),
  userBlack: getIcon('user-black.svg'),
  logOut: getIcon('log-out.svg'),
  play: getIcon('play.svg'),
  playProgress: getIcon('play-progress.svg'),
  energy: getIcon('energy.svg'),
  duplicate: getIcon('duplicate.svg'),
  pen: getIcon('pen.svg'),
  penPurple: getIcon('pen-purple.svg'),
  triangle: getIcon('triangle.svg'),
  noGames: getIcon('no-games.svg'),
  home: getIcon('home.svg'),
  pieGraph: getIcon('pie-graph.svg'),
  pieGraphBig: getIcon('pie-graph-big.svg'),
  players: getIcon('players.svg'),
  playersHistory: getIcon('players-history.svg'),
  bigArrow: getIcon('big-arrow.svg'),
  thinArrow: getIcon('thin-arrow.svg'),
  arrowDown: getIcon('arrow-down.svg'),
  arrowDownGrey: getIcon('arrow-down-grey.svg'),
  search: getIcon('search.svg'),
  check: getIcon('check.svg'),
  checkGreen: getIcon('check-green.svg'),
  mail: getIcon('mail.svg'),
  closeModal: getIcon('close-modal.svg'),
  link: getIcon('link.svg'),
  playersPurple: getIcon('players-purple.svg'),
  suitcase: getIcon('suitcase.svg'),
  playersHistoryPurple: getIcon('players-history-purple.svg'),
  gameSettingsWhite: getIcon('game-settings-white.svg'),
  gameSettingsPurple: getIcon('game-settings-purple.svg'),
  share: getIcon('share.svg'),
  bin: getIcon('bin.svg'),
  refresh: getIcon('refresh.svg'),
  dragNDrop: getIcon('drag-n-drop.svg'),

  ...USER_ICONS,
};

export type TIconNames = keyof typeof TYPES;

export interface IIconProps extends IComponent {
  type: TIconNames;
  height?: string;
  width?: string;
  size?: string;
  isPointerEvents?: boolean;
  onClick?: () => void;
  onTransitionEnd?: () => void;
}

const IconImg = styled.img<{ isPointerEvents?: boolean }>`
  user-select: none;
  pointer-events: ${(p) => (p.isPointerEvents ? 'unset' : 'none')};
`;

export const Icon = (props: IIconProps) => {
  const {
    type,
    height,
    width,
    className,
    size,
    onClick,
    onTransitionEnd,
    isPointerEvents,
  } = props;

  const src = TYPES[type];

  return (
    <IconImg
      src={src}
      height={height ?? size}
      width={width ?? size}
      className={className}
      alt="Icon"
      isPointerEvents={isPointerEvents}
      onClick={onClick && onClick}
      onTransitionEnd={onTransitionEnd}
    />
  );
};
