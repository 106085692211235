import { TEducationLevels } from '@avid/common';

import { entriesObject } from '../object';

import { capitalize } from './strings';
import { formatDuration } from './time';

export const formatMinimalMoney = (value: string) => value.replace('-', '-$');

export const formatVersion = (value: string) => {
  if (value === 'us') {
    return 'US';
  }

  return capitalize(value);
};

export const formatRoundDuration = (value: string) =>
  formatDuration(Number(value));

export const getEducationSettings = (educationLevels: TEducationLevels) =>
  [{ label: 'Players start with no education', value: 'no' }].concat(
    entriesObject(educationLevels)
      // Sort by their order in DB
      .sort((first, second) => first[1].order - second[1].order)
      // Leave only not sector levels (schools)
      .filter(([, params]) => !params.isSector)
      // map to selector options
      .map(([name, params]) => ({
        value: name,
        label: `Players start with ${params.achievement}`,
      }))
  );
