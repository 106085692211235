import firebase from 'firebase/app';

import {
  IFetchLogsOptions,
  IFilterOptions,
  IInputFilterOption,
} from './logs.api.typing';

export const generateQuery = (
  query: firebase.firestore.Query<firebase.firestore.DocumentData>,
  options: IFetchLogsOptions
) => {
  const { sortBy, filters } = options;

  let isFilteredByInputs = false;

  if (filters) {
    const { inputs } = filters;
    if (inputs) {
      isFilteredByInputs = true;

      inputs.forEach((input) => {
        query = query.where(input.field, '==', input.value);
      });
    }
  }

  if (sortBy) {
    if (isFilteredByInputs) {
      const filterFieldIndex = ((filters as IFilterOptions)
        .inputs as IInputFilterOption[]).findIndex(
        (filter) => filter.field === sortBy.field
      );
      const isOrderByFilter = filterFieldIndex !== -1;

      if (isOrderByFilter) {
        return query;
      }
    }
    const order = sortBy.desc ? 'desc' : 'asc';
    query = query.orderBy(sortBy.field, order);
  }

  return query;
};
