import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { auth } from 'services/firebase';
import { ROUTES } from 'constants/routes';

const PLATFORM_LINK = process.env.REACT_APP_PLATFORM;

export const useAuth = () => {
  const [isError, setIsError] = useState(false);

  const { replace } = useHistory();

  useEffect(() => {
    if (!window.opener) {
      return;
    }

    const onMessage = async (event: MessageEvent) => {
      if (event.origin !== PLATFORM_LINK) {
        return;
      }

      setIsError(false);

      try {
        const { token, displayName }: IAuthParams = event.data;

        await auth.signInWithCustomToken(token);

        await auth.currentUser?.updateProfile({ displayName });

        window.opener.postMessage({ authorized: true }, PLATFORM_LINK);

        replace(ROUTES.START);
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        window.removeEventListener('message', onMessage);
      }
    };

    window.addEventListener('message', onMessage);

    window.opener.postMessage({ loaded: true }, PLATFORM_LINK);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return { isError };
};
