"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConstantsFirestore = void 0;
var ConstantsFirestore = /** @class */ (function () {
    function ConstantsFirestore(rawFirestore) {
        this.rawFirestore = rawFirestore;
        // construct
    }
    ConstantsFirestore.prototype.updateWorkConstants = function (data) {
        return this.rawFirestore.updateWorkConstants(data);
    };
    ConstantsFirestore.prototype.updateEducationConstants = function (data) {
        return this.rawFirestore.updateEducationConstants(data);
    };
    ConstantsFirestore.prototype.updateCreateCharacterConstants = function (data) {
        return this.rawFirestore.updateCreateCharacterConstants(data);
    };
    ConstantsFirestore.prototype.updateGameConstants = function (data) {
        return this.rawFirestore.updateGameConstants(data);
    };
    ConstantsFirestore.prototype.updateShopConstants = function (data) {
        return this.rawFirestore.updateShopConstants(data);
    };
    return ConstantsFirestore;
}());
exports.ConstantsFirestore = ConstantsFirestore;
