import { createGlobalStyle } from 'styled-components';

export function fontFace(name: string, src: string) {
  return `
      @font-face{
          font-family: "${name}";
          src: url(${require('../assets/fonts/' +
            src +
            '.ttf')}) format("truetype")
      }
  `;
}

export default createGlobalStyle`
${fontFace('Raleway-Bold', 'Raleway-Bold')}
  ${fontFace('Raleway-SemiBold', 'Raleway-SemiBold')}
  ${fontFace('Quicksand-Medium', 'Quicksand-Medium')}
  ${fontFace('Quicksand-Regular', 'Quicksand-Regular')}
  ${fontFace('Quicksand-SemiBold', 'Quicksand-SemiBold')}
`;

export const FONTS = {
  bold: 'Raleway-Bold',
  semiBold: 'Raleway-SemiBold',
  regular: 'Quicksand-Regular',
  medium: 'Quicksand-Medium',
  quicksandBold: 'Quicksand-SemiBold',
} as const;
