import { useEffect } from 'react';
import { ITrainerLimits } from '@avid/common';

import { useUpdateState } from 'services/hooks';
import { auth, db } from 'services/firebase';
import { checkLimits } from 'services/utils';

interface IState {
  isLoading: boolean;
  isAllowed: boolean;
  limits?: ITrainerLimits;
}

const INIT_STATE: IState = { isLoading: true, isAllowed: false };

export const useEmptyHome = () => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  const isPlatformUser = auth.currentUser
    ? auth.currentUser.uid.startsWith('wgf')
    : true;

  useEffect(() => {
    if (!isPlatformUser) {
      updateState({ isLoading: false, isAllowed: true });
      return;
    }

    const request = async () => {
      try {
        const limits = (
          await db.collection('admin-limits').doc(auth.currentUser?.uid).get()
        ).data() as ITrainerLimits | undefined;

        if (!limits) {
          updateState({ isLoading: false });
          return;
        }

        const isAllowed = checkLimits(limits);

        updateState({ limits, isLoading: false, isAllowed });
      } catch (error) {
        updateState({ isLoading: false });
        console.error(error);
      }
    };

    request();
  }, []);

  return { ...state, isPlatformUser };
};
