import {
  ICsvUploader,
  IConstantsValueCsv,
  TWorkConstant,
  TWorkConstantsConstantStored,
} from 'typings/constants-uploading.typing';

import { clientFirebase } from '../firebase';

export class WorkConstantsCsvUploader implements ICsvUploader {
  parseRows(csvRows: IConstantsValueCsv[]) {
    return csvRows.reduce((acc, current) => {
      acc[current.name as TWorkConstant] = +current.value;

      return acc;
    }, {} as TWorkConstantsConstantStored);
  }

  upload(workConstants: TWorkConstantsConstantStored) {
    return clientFirebase.firestore.constants.updateWorkConstants(workConstants);
  }
}
