
import styled from 'styled-components';
import { IGameConfig } from '@avid/common';

import {
  formatMinimalMoney,
  formatRoundDuration,
  formatVersion,
} from 'services/utils';
import { Theme } from 'theme';

import { GameSettingsInfoStyles as Styles } from './game-settings-info.styles';
import { GameSettingsItem } from '../game-settings-item';
import { GameSettingsEducation } from '../game-settings-education';
import { GameSettingsMods } from '../game-settings-mods';

interface IGameSettingsInfoProps {
  gameConfig: IGameConfig;
}

const Root = styled(Theme.BorderedContainer)<{
  width?: string;
  alignSelf?: string;
}>`
  flex-direction: column;
  align-self: ${(props) => props.alignSelf};
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const GamesSettingsInfo = (props: IGameSettingsInfoProps) => {
  const {
    gameCode,
    roundsNumber,
    version,
    energy,
    gameTitle,
    roundDuration,
    description,
    minimalMoney,
    mods,
    startEducationOrder,
  } = props.gameConfig;

  const RenderGameMods = mods && <GameSettingsMods gameMods={mods} />;

  return (
    <Root>
      <Styles.ItemsWrapper>
        <GameSettingsItem param="Game Code" value={gameCode} />
        <GameSettingsItem param="Total Number of Rounds" value={roundsNumber} />
        <GameSettingsItem
          param="Game Version"
          value={version || 'standard'}
          formatFn={formatVersion}
        />
        <GameSettingsItem param="Energy per Round" value={energy} />
        <GameSettingsItem param="Game Title" value={gameTitle} />
        <GameSettingsItem
          param="Duration per Round (dd:hh:mm)"
          value={String(roundDuration)}
          formatFn={formatRoundDuration}
        />
        <GameSettingsItem param="Description" value={description} />

        <GameSettingsEducation
          version={version || 'standard'}
          startEducationOrder={startEducationOrder}
        />

        <GameSettingsItem
          param="Max Loan Amount"
          value={minimalMoney}
          formatFn={formatMinimalMoney}
        />

        {RenderGameMods}
      </Styles.ItemsWrapper>
    </Root>
  );
};
