import styled from 'styled-components';

import { H3, Paragraph } from 'components/typography';
import { Grid } from 'components/UI';
import { TabLayout } from 'components/tab-layout';
import { Button } from 'components/button';
import { COLORS } from 'theme';

import { FilePicker, Sample } from './components';

import { TGamesUploadStatus, useGameUpload } from './games-upload.state';

const Styles = {
  Root: styled(TabLayout)`
    padding: 0 40px;
  `,

  MainWrapper: styled(Grid)`
    margin-top: 64px;
  `,
};

const STATUS_LABEL: Record<TGamesUploadStatus, string> = {
  init: 'Choose a file to upload',
  success: 'Uploaded!',
  error: 'Error',
  loading: 'Uploading...',
};

export const GamesUpload = () => {
  const { file, status, error, onChangeFile, onUpload, isUploadDisabled } =
    useGameUpload();

  const statusColor = status === 'error' ? COLORS.red : COLORS.black;
  const errorText = error ? `: ${error}` : '';

  return (
    <Styles.Root>
      <Styles.MainWrapper rowGap="80px">
        <Grid rowGap="32px">
          <H3 typography="RalewayBold24">Bulk Create Games</H3>

          <Sample />

          <FilePicker fileName={file?.name} onChangeFile={onChangeFile} />

          <Paragraph typography="QuicksandBold18" color={statusColor}>
            {STATUS_LABEL[status]}
            {errorText}
          </Paragraph>
        </Grid>

        <Button isDisabled={isUploadDisabled} onClick={onUpload}>
          Upload
        </Button>
      </Styles.MainWrapper>
    </Styles.Root>
  );
};
