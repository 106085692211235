import { memo } from 'react';

import { Paragraph } from 'components/typography';
import { TSectorSettings, TWorkLimitParam } from 'typings/sectors-settings';

import { SectorsSettingsGrid } from '../sectors-settings-grid';
import { SectorsSettingsApply } from '../sectors-settings-apply';
import { SectorsSettingsHeaders } from '../sectors-settings-headers';

import { SectorsSettingsStyles as Styles } from './sectors-settings.styles';
interface IProps {
  sectorSettings: TSectorSettings;
  selectedLimits: string[];
  selectedSalaries: string[];
  isAllLimitsSelected: boolean;
  isAllSalariesSelected: boolean;
  isDisabledSalariesApply: boolean;
  isDisabledLimitsApply: boolean;
  changeItemCallback: (
    param: TWorkLimitParam
  ) => (sector: string, value: number) => void;
  onSelectItem: (selection: TWorkLimitParam, sector: string) => void;
  onToggleAllItems: (selection: TWorkLimitParam, isSelected: boolean) => void;
  onApplyItems: (param: TWorkLimitParam) => void;
}

export const SectorsSettings = memo((props: IProps) => {
  const {
    sectorSettings,
    selectedLimits,
    selectedSalaries,
    isAllLimitsSelected,
    isAllSalariesSelected,
    isDisabledLimitsApply,
    isDisabledSalariesApply,
    changeItemCallback,
    onSelectItem,
    onToggleAllItems,
    onApplyItems,
  } = props;

  return (
    <div>
      <Paragraph typography="RalewaySemiBold20" textAlign="left">
        Sector Limits
      </Paragraph>

      <Styles.TableWrapper>
        <SectorsSettingsHeaders
          isAllSalariesSelected={isAllSalariesSelected}
          isAllLimitsSelected={isAllLimitsSelected}
          onToggleAllItems={onToggleAllItems}
        />

        <SectorsSettingsGrid
          sectorSettings={sectorSettings}
          selectedLimits={selectedLimits}
          selectedSalaries={selectedSalaries}
          onSelectItem={onSelectItem}
          changeItemCallback={changeItemCallback}
        />

        <SectorsSettingsApply
          isDisabledLimits={isDisabledLimitsApply}
          isDisabledSalaries={isDisabledSalariesApply}
          onApplyItems={onApplyItems}
        />
      </Styles.TableWrapper>
    </div>
  );
});
