import { useState, useEffect } from 'react';
import { useUpdateState } from 'services/hooks';

import { INIT_STATE } from './filters.constants';
import { IFiltersProps } from './filters.typing';

export const useFiltersState = (props: IFiltersProps) => {
  const { isShow, onApply } = props;

  const [isMounted, setIsMounted] = useState(false);
  const { state, updateState } = useUpdateState(INIT_STATE);

  const onChangeEmail: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    const value = ev.currentTarget.value;
    updateState({ email: value });
  };

  const onChangeRound: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    const newValue = Number(ev.target.value);
    if (isNaN(newValue)) {
      return;
    }

    updateState({ round: newValue });
  };

  const onClear = () => {
    updateState(INIT_STATE);
    onApply(INIT_STATE);
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (ev) => {
    ev.preventDefault();
    onApply(state);
  };

  useEffect(() => {
    setIsMounted(isShow);

    return () => setIsMounted(false);
  }, [isShow]);

  return {
    isMounted,
    ...state,
    onChangeEmail,
    onChangeRound,
    onClear,
    onSubmit,
  };
};
