import moment from 'moment';
import { IRound, IGame, TGameStatus } from '@avid/common';

import { getNowMoment } from 'services/utils';
import { TDBSectors, IJobSectorData } from 'typings/games';
import { STRINGS } from 'constants/string';

const { players } = STRINGS.ACTIVE_GAME;

export const transformJobLimits = (limits?: TDBSectors) => {
  if (!limits) {
    return;
  }

  return Object.entries(limits).reduce(
    (formattedLimits: TDBSectors, sector: [string, IJobSectorData]) => {
      if (sector[0] !== players) {
        formattedLimits[sector[0]].limit =
          sector[1].limit === '' ? 0 : +sector[1].limit;
      }
      return formattedLimits;
    },
    { ...limits }
  );
};

export const getPausedGameStatus = (
  rounds: IRound[],
  game: IGame
): TGameStatus =>
  rounds.length === +game.config.roundsNumber ? 'finished' : 'paused';

const newRoundProto: IRound = {
  startTime: '',
  status: 'in-progress',
  endTime: null,
  duration: null,
};

export const getNewRound = () => ({
  ...newRoundProto,
  startTime: getNowMoment().format(),
});

export const stopLastRoundInArray = (
  rounds: IRound[],
  roundDuration: number
) => {
  const roundsCopy = [...rounds];
  const lastRound = { ...roundsCopy[roundsCopy.length - 1] };

  const nowMoment = getNowMoment();

  const duration =
    nowMoment.unix() - moment(new Date(lastRound.startTime)).unix();
  const isTimeExceed = duration > roundDuration;

  lastRound.endTime = isTimeExceed
    ? lastRound.startTime + roundDuration
    : nowMoment.format();
  lastRound.duration = isTimeExceed ? roundDuration : duration;
  lastRound.status = 'ended';

  roundsCopy[roundsCopy.length - 1] = lastRound;

  return roundsCopy;
};
