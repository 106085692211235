import { useState } from 'react';

import { ISelectData } from 'screens/statistics/statistics.types';

import { INIT_STATE } from './players-history.constants';
import { useGameByGameCode } from 'services/hooks';

export const usePLayersHistoryState = (
  shareGameCode?: string,
  activeGameCode?: string
) => {
  const [state, setState] = useState(INIT_STATE);

  const selectedGameCode = state.gameCode || activeGameCode || shareGameCode;

  const { game, games } = useGameByGameCode(selectedGameCode);

  const onSwitch = () =>
    setState((current) => ({
      ...current,
      isDisplayTable: !current.isDisplayTable,
    }));

  const selectGame = (gameCode: ISelectData) =>
    setState((current) => ({ ...current, gameCode: gameCode.value }));

  const isActiveGameSelected = game?.status !== 'finished';

  return {
    ...state,
    selectedGameCode,
    isActiveGameSelected,
    isGamesExist: games.length > 0,
    onSwitch,
    selectGame,
  };
};
