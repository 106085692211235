import { IJobDescription, entriesObject } from '@avid/common';

import { clientFirebase } from '../firebase';

import { VersionCsvUploader } from './version-csv-uploader';

export class JobDescriptionsCsvUploader extends VersionCsvUploader {
  parseRows(csvRows: []) {
    return csvRows.reduce(
      (acc, row) => {
        const { sector, description, job, isFreelance, isIntern } = row;

        if (!acc[sector]) {
          acc[sector] = {};
        }

        acc[sector][job] = {
          description,
        };

        if (isFreelance === 'TRUE') {
          acc[sector][job].isFreelance = true;
        }

        if (isIntern === 'TRUE') {
          acc[sector][job].isIntern = true;
        }

        return acc;
      },
      {} as Record<string, Record<string, IJobDescription>> // sector -> job -> IJobDescription
    );
  }

  upload(
    sectorsJobDescriptions: Record<string, Record<string, IJobDescription>>
  ) {
    return Promise.all(
      entriesObject(sectorsJobDescriptions).map(([sector, jobDescriptions]) =>
        Promise.all(
          entriesObject(jobDescriptions).map(([job, data]) =>
            clientFirebase.firestore.versions.sectors.setWorkJobDescription(
              {
                version: this.version,
                sector,
                job,
              },
              data
            )
          )
        )
      )
    );
  }
}
