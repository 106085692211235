import { ReactNode } from 'react';
import styled from 'styled-components';

import { FONTS, FONT_SIZE } from 'theme';

interface IGameSettingsItemProps {
  param: string;
  value: string;
  formatFn?: (value: string) => string;
}

interface IProps {
  children?: ReactNode;
}

const Styles = {
  Root: styled.div`
    //@related
    padding-top: 32px;
    padding-left: 32px;

    font-size: ${FONT_SIZE.px16};

    & dd {
      margin: 0;
      padding-top: 16px;
      font-family: ${FONTS.semiBold};

      max-width: 100%;
      overflow-x: auto;
    }
  `,
};

export const GameSettingsItemWrapper = (props: IProps) => (
  <Styles.Root>{props.children}</Styles.Root>
);

export const GameSettingsItem = (props: IGameSettingsItemProps) => {
  const { param, value, formatFn } = props;

  const displayValue = formatFn ? formatFn(value) : value;

  return (
    <GameSettingsItemWrapper>
      <dt>{param}</dt>
      <dd>{displayValue}</dd>
    </GameSettingsItemWrapper>
  );
};
