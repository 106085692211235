"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheatCodesFirestore = void 0;
var CheatCodesFirestore = /** @class */ (function () {
    function CheatCodesFirestore(rawFirestore) {
        this.rawFirestore = rawFirestore;
        // construct
    }
    CheatCodesFirestore.prototype.clear = function () {
        return this.rawFirestore.clearCheatCodes();
    };
    CheatCodesFirestore.prototype.createMany = function (cheatCodes) {
        return this.rawFirestore.setCheatCodes(cheatCodes);
    };
    return CheatCodesFirestore;
}());
exports.CheatCodesFirestore = CheatCodesFirestore;
