import styled from 'styled-components';

import { Icon } from 'components/icons';
import { COLORS } from 'theme';

export const CheckBoxStyle = {
  Root: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background: ${COLORS.white};
    border: 1.8px solid ${COLORS.violet};
    cursor: pointer;
  `,

  Icon: styled(Icon)<{ isChecked: boolean }>`
    transition: opacity 150ms ease-in-out;
    opacity: ${({ isChecked }) => (isChecked ? '1' : '0')};
  `,
};
