

import { Theme } from 'theme';

import { GameSettingsPanel } from './game-settings-panel';

interface IProps {
  gameCode: string;
}

export const GameSettings = (props: IProps) => (
  <>
    <Theme.Title>Game Settings</Theme.Title>
    <GameSettingsPanel gameCode={props.gameCode} />
  </>
);
