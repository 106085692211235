

import { LogoWrapper } from 'components/logo-wrapper';
import { STRINGS } from 'constants/string';

import { SignInStyled as Styled } from './sign-in.styled';

export const SignIn = () => (
  <LogoWrapper>
    <Styled.Title>{STRINGS.AUTH.logIn.title}</Styled.Title>
    <Styled.Tip>
      Go to <a href={process.env.REACT_APP_PLATFORM}> WGF Platform</a> to Sign
      In
    </Styled.Tip>
  </LogoWrapper>
);
