import { useEffect } from 'react';
import { ITrainerLimits } from '@avid/common';

import { ROUTES } from 'constants/routes';
import { auth, db } from 'services/firebase';
import { moveToRouteCallback } from 'services/history';
import { useUpdateState } from 'services/hooks';
import { checkLimits } from 'services/utils';

interface IState {
  isLoading: boolean;
  isAllowed: boolean;
  limits?: ITrainerLimits;
}

const INIT_STATE: IState = {
  isAllowed: false,
  isLoading: true,
};

export const useCreateBlock = () => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  const isPlatformUser = auth.currentUser
    ? auth.currentUser.uid.startsWith('wgf')
    : true;

  useEffect(() => {
    if (!isPlatformUser) {
      updateState({ isLoading: false, isAllowed: true });
      return;
    }

    const request = async () => {
      updateState({ isLoading: true });

      try {
        const limits = (
          await db.collection('admin-limits').doc(auth.currentUser?.uid).get()
        ).data() as ITrainerLimits | undefined;

        if (!limits) {
          updateState({ isLoading: false });
          return;
        }

        const isAllowed = checkLimits(limits);

        updateState({ limits, isLoading: false, isAllowed });
      } catch (error) {
        updateState({ isLoading: false });
        console.error(error);
      }
    };

    request();
  }, []);

  const onClickCreate = moveToRouteCallback(ROUTES.NEW_GAME);

  return { ...state, isPlatformUser, onClickCreate };
};
