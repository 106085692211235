import {useMemo, useState } from 'react';
import styled from 'styled-components';

import { Selector } from 'components/selector';
import { useGameByGameCode } from 'services/hooks';

import { GamesSettingsInfo } from './game-settings-info';

const Root = styled.div`
  padding-top: 80px;
`;

const DEFAULT_OPTION = {
  label: 'Select Game',
  value: 'select',
};

interface IProps {
  gameCode: string;
}

export const GameSettingsPanel = (props: IProps) => {
  const { gameCode } = props;

  const [selectedGameCode, setSelectedGameCode] = useState(gameCode);

  const { game, games } = useGameByGameCode(selectedGameCode);

  const currentValue = useMemo(
    () =>
      game?.config
        ? {
            label: game.config.gameTitle,
            value: game.config.gameCode,
          }
        : undefined,
    [game]
  );

  const gameOptions = games.map((g) => ({
    label: g.config.gameTitle,
    value: g.config.gameCode,
  }));

  const onSelectGame = (option: any) => {
    setSelectedGameCode((option as IOption).value);
  };

  const RenderGameSettingsInfo = game?.config && (
    <GamesSettingsInfo gameConfig={game.config} />
  );

  return (
    <Root>
      <Selector
        value={currentValue ? currentValue : DEFAULT_OPTION}
        options={gameOptions}
        onChange={onSelectGame}
      />

      {RenderGameSettingsInfo}
    </Root>
  );
};
