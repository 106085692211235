import { memo } from 'react';
import styled from 'styled-components';

import { InfoBlock } from '../info-block';

import { useEmploymentInfo } from './employment-info.state';

const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
`;

interface IProps {
  gameCode: string;
}

export const EmploymentInfo = memo((props: IProps) => {
  const { gameCode } = props;

  const { playersCount, employeesCount, nonEmployeesCount } =
    useEmploymentInfo(gameCode);

  return (
    <Root>
      <InfoBlock title="Employee" value={employeesCount} />
      <InfoBlock title="Non-Employee" value={nonEmployeesCount} />
      <InfoBlock title="Total" value={playersCount} />
    </Root>
  );
});
