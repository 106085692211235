
import { Props } from 'react-select';

import { SelectStyles, SelectComponentStyles } from './selector.styles';

interface ISelectProps extends Props {
  containerWidth?: string;
}

export const Selector = (props: ISelectProps) => {
  const { containerWidth, placeholder } = props;

  const DropdownIndicator = () => <SelectStyles.ArrowImage type="arrowDown" />;

  return (
    <SelectStyles.Wrapper width={containerWidth}>
      <SelectStyles.Select
        components={{ DropdownIndicator }}
        styles={SelectComponentStyles}
        placeholder={<SelectStyles.Text>{placeholder}</SelectStyles.Text>}
        {...props}
      />
    </SelectStyles.Wrapper>
  );
};
