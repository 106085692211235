import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import deepEqual from 'deep-equal';
import { TGameVersion } from '@avid/common';

import { versionsAPI } from '../api';
import { careerTreeToSortedUniqueJobsMap } from '../utils';
import { useUpdateState } from './react';

interface IUseSectorJobsMapLoaderProps {
  version?: TGameVersion;
}

interface IPositionsLimitsLoaderState {
  isLoaded: boolean;
  sectorJobsMap?: Record<string, string[]>;
}

const INIT_POSITIONS_LIMIT_LOADER_STATE: IPositionsLimitsLoaderState = {
  isLoaded: false,
};

export const usePositionLimitsEditor = () => {
  const [items, setItems] = useState<IPositionLimit[]>([]);

  const initItems = useRef<IPositionLimit[] | null>(null);

  const setInitItems = useCallback((loadItems: IPositionLimit[]) => {
    setItems(loadItems);

    initItems.current = structuredClone(loadItems);
  }, []);

  const checkChanged = useCallback(
    () => !deepEqual(initItems.current, items),
    [items, initItems.current]
  );

  const updateInitItems = useCallback(() => {
    initItems.current = items;
  }, [items]);

  const onAddLimit = useCallback(() => {
    const isItemExist =
      items.findIndex((item) => item.sector === '' && item.job === '') !== -1;

    if (isItemExist) {
      return;
    }

    setItems((prev) => [...prev, { sector: '', job: '' }]);
  }, [items]);

  const removeLimitCb = useCallback(
    (index: number) => () => {
      setItems((prev) => {
        const prevCopy = [...prev];
        prevCopy.splice(index, 1);
        return prevCopy;
      });
    },
    []
  );

  const setSectorCb = useCallback(
    (index: number): ChangeEventHandler<HTMLSelectElement> =>
      (ev) => {
        const sector = ev.target.value;
        const selectedItem = items[index];

        const isItemExist =
          items.findIndex(
            (item, findIndex) =>
              item.sector === sector &&
              item.job === selectedItem.job &&
              index !== findIndex
          ) !== -1;

        if (isItemExist) {
          return;
        }

        setItems((prev) => {
          const prevCopy = [...prev];
          const prevItemCopy = { ...prevCopy[index] };

          prevItemCopy.sector = sector;
          prevItemCopy.job = '';
          prevItemCopy.limit = undefined;

          prevCopy.splice(index, 1, prevItemCopy);

          return prevCopy;
        });
      },
    [items]
  );

  const setJobCb = useCallback(
    (index: number): ChangeEventHandler<HTMLSelectElement> =>
      (ev) => {
        const job = ev.target.value;
        const selectedItem = items[index];

        const isItemExist =
          items.findIndex(
            (item, findIndex) =>
              item.sector === selectedItem.sector &&
              item.job === job &&
              index !== findIndex
          ) !== -1;

        if (isItemExist) {
          return;
        }

        setItems((prev) => {
          const prevCopy = [...prev];
          const prevItemCopy = { ...prevCopy[index] };

          prevItemCopy.job = job;
          prevCopy.splice(index, 1, prevItemCopy);

          return prevCopy;
        });
      },
    [items]
  );

  const setLimitCb = useCallback(
    (index: number) => (limit: number) =>
      setItems((prev) => {
        const prevCopy = [...prev];
        const prevItemCopy = { ...prevCopy[index] };

        prevItemCopy.limit = limit;
        prevCopy.splice(index, 1, prevItemCopy);

        return prevCopy;
      }),
    []
  );

  return {
    items,
    checkChanged,
    setInitItems,
    updateInitItems,
    onAddLimit,
    removeLimitCb,
    setSectorCb,
    setJobCb,
    setLimitCb,
  };
};

export const useSectorJobsMapLoader = (props: IUseSectorJobsMapLoaderProps) => {
  const { version } = props;

  const { state, updateState } = useUpdateState(
    INIT_POSITIONS_LIMIT_LOADER_STATE
  );

  useEffect(() => {
    if (!version) {
      updateState({ isLoaded: true });
      return;
    }

    const request = async () => {
      try {
        const sectorsRes = await versionsAPI.getSectors(version);

        if (!sectorsRes) {
          updateState({ isLoaded: true });
          return;
        }

        const sectorJobsMap: Record<string, string[]> = {};

        await Promise.all(
          sectorsRes.sectors.map(async (sector) => {
            const data = await versionsAPI.getCareerTree(version, sector.value);

            if (!data) {
              return;
            }

            const uniqueJobs = careerTreeToSortedUniqueJobsMap(data.careerTree);
            sectorJobsMap[sector.value] = uniqueJobs;
          })
        );

        updateState({ isLoaded: true, sectorJobsMap });
      } catch (err) {
        console.error(err);
        updateState({ isLoaded: true });
      }
    };

    request();
  }, []);

  return { ...state };
};
