import { ROUTES } from 'constants/routes';

import { NavigationLink } from './navigation-link';

interface IProps {
  gameCode: string;
}

export const GameLink = (props: IProps) => {
  const { gameCode } = props;

  return (
    <NavigationLink
      to={`${ROUTES.ACTIVE_GAME}/${gameCode}`}
      label={`Active Game (Game Code ${gameCode})`}
    />
  );
};
