import { Redirect, Route } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { auth } from 'services/firebase';

export const AdminRoute = (props: any) => {
  const isSuperAdmin = auth.currentUser?.email === 'superuser@mail.com';

  return isSuperAdmin ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: ROUTES.LOGIN }} />
  );
};
