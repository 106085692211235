
import styled from 'styled-components';
import { encrypt } from 'crypto-js/aes';

import { Button } from 'components/button';
import { Theme } from 'theme';
import { ROUTES } from 'constants/routes';

import { ShareLink } from '../../../share-link';

interface IProps {
  gameCode: string;
  onClose: () => void;
}

const Styles = {
  Tip: styled(Theme.Paragraph)`
    margin-top: 24px;
  `,
  CloseButton: styled(Button)`
    margin-top: 57px;
    width: 255px;
    height: 56px;
  `,
};
export const ShareGameModal = (props: IProps) => {
  const { gameCode, onClose } = props;

  const encryptedCode = encrypt(
    gameCode,
    process.env.REACT_APP_SHARE_GAME_SECRET as string
  ).toString();

  const url = `${window.location.origin}${ROUTES.SHARE}${ROUTES.SHARE_HOME}?k=${encryptedCode}`;

  return (
    <div>
      <Theme.Title>Share Game Admin View</Theme.Title>
      <Styles.Tip>Copy link below to share your view</Styles.Tip>
      <ShareLink url={url} />

      <Styles.CloseButton text="Close" onClick={onClose} />
    </div>
  );
};
