import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IGame, IRound } from '@avid/common';

import { IReduxState } from 'typings/redux';

import { IStatisticsStateParameters } from './statistic-menu';

export const useStatisticMenuState = (
  parameters: IStatisticsStateParameters
) => {
  const { currentGameCode, currentRound, onSelectSnapshot } = parameters;
  const [selectedRound, selectRound] = useState(currentRound);

  useEffect(() => {
    selectRound(currentRound);
  }, [currentRound]);

  const games = useSelector((state: IReduxState) => state.main.games);
  const gameOptions = games.map((game: IGame) => ({
    label: game.config.gameTitle,
    value: game.config.gameCode,
  }));
  const currentGame = games.find(
    (game: IGame) => game.config.gameCode === currentGameCode
  );

  const rounds: IRound[] =
    (currentGame &&
      currentGame.rounds &&
      currentGame.rounds.filter((round: IRound) => round.status === 'ended')) ||
    [];
  const currentGameTitle = (currentGame && currentGame.config.gameTitle) || '';

  const onClickRound = useCallback(
    (round: number) => () => onSelectSnapshot && onSelectSnapshot(round + 1),
    [onSelectSnapshot]
  );

  return {
    selectedRound: selectedRound ? selectedRound - 1 : -1,
    games,
    gameOptions,
    rounds,
    currentGameTitle,
    onClickRound,
  };
};
