import axios from 'axios';

import { auth } from 'services/firebase';

export const PLATFORM_AXIOS_PATHS = {
  SEND_GAME_REPORTS: '/send-game-reports',
} as const;

interface ISendGamePayload {
  gameCode: string;
}

interface IPlatformRequests {
  [PLATFORM_AXIOS_PATHS.SEND_GAME_REPORTS]: [ISendGamePayload];
}

const platformGameInstance = axios.create({
  baseURL: process.env.REACT_APP_PLATFORM_API_URL + '/game',
});

export const postPlatformAuth = async <
  T extends typeof PLATFORM_AXIOS_PATHS[keyof typeof PLATFORM_AXIOS_PATHS]
>(
  pathConst: T,
  payload: IPlatformRequests[T][0]
): Promise<T[1] | void> => {
  if (!auth.currentUser) {
    throw new Error('unauthorized');
  }

  const token = await auth.currentUser.getIdToken();

  try {
    const response = await platformGameInstance.post(pathConst, payload, {
      headers: { authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
