import { IGame } from '@avid/common';

import { IUser } from 'services/firebase.api';

import { createReducer } from '../../services/redux/utils';
import { MAIN_ACTION_TYPES } from './main.actions';

export interface IMainState {
  currentUser: IUser | null;
  games: IGame[];
  userLoaded: boolean;
  modal: string;
  isGamesFetching: boolean;
  lastActiveGameCode: string | null;
}

export interface IActionBase {
  type: string;
}

export interface ISetId {
  type: string;
  payload: IUser;
}

export interface ISetGames {
  type: string;
  payload: IGame[];
}

export interface ISetLastActiveGameCode {
  type: string;
  payload: string;
}

export interface ITimer {
  type: string;
  payload: boolean;
}
export interface IModal {
  type: string;
  payload: string;
}

interface IUpdateGameAction {
  type: typeof MAIN_ACTION_TYPES.UPDATE_GAME;
  payload: { gameCode: string; data: Partial<IGame> };
}

const INITIAL_STATE: IMainState = {
  userLoaded: false,
  currentUser: null,
  games: [],
  modal: '',
  isGamesFetching: true,
  lastActiveGameCode: null,
};

const handlers = {
  [MAIN_ACTION_TYPES.USER_ID]: (
    state: IMainState,
    action: ISetId
  ): IMainState => {
    return {
      ...state,
      currentUser: action.payload,
      userLoaded: true,
    };
  },

  [MAIN_ACTION_TYPES.SET_GAMES]: (
    state: IMainState,
    action: ISetGames
  ): IMainState => {
    return {
      ...state,
      games: action.payload,
      isGamesFetching: false,
    };
  },

  [MAIN_ACTION_TYPES.START_FETCH_GAMES]: (state: IMainState): IMainState => ({
    ...state,
    isGamesFetching: true,
  }),

  [MAIN_ACTION_TYPES.FAIL_FETCH_GAMES]: (state: IMainState): IMainState => ({
    ...state,
    isGamesFetching: false,
  }),

  [MAIN_ACTION_TYPES.SET_LAST_ACTIVE_GAME_CODE]: (
    state: IMainState,
    action: ISetLastActiveGameCode
  ): IMainState => ({
    ...state,
    lastActiveGameCode: action.payload,
  }),

  [MAIN_ACTION_TYPES.UPDATE_GAME]: (
    state: IMainState,
    action: IUpdateGameAction
  ): IMainState => {
    const { data, gameCode } = action.payload;

    const gamesClone = [...state.games];

    const gameIndex = gamesClone.findIndex(
      (game) => game.config.gameCode === gameCode
    );

    if (gameIndex === -1) {
      return state;
    }

    const game = gamesClone[gameIndex];

    const newGame = { ...game, ...data };

    gamesClone.splice(gameIndex, 1, newGame);

    return { ...state, games: gamesClone };
  },
};

export const mainReducer = createReducer(INITIAL_STATE, handlers);
