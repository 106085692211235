import styled from 'styled-components';

import { Theme } from 'theme';

import { TableButton } from './components';

export const PlayersHistoryStyles = {
  WidthContainer: styled.div`
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;
  `,
  ControlsContainer: styled.div`
    display: flex;
  `,
  HeadButton: styled(TableButton)<{ isMargin: boolean }>`
    height: 56px;
    ${({ isMargin }) => isMargin && 'margin-left: 32px;'}
    align-self: center;
    width: auto;
    transform: translateY(-5px);
  `,
  NoGames: styled(Theme.Title)`
    margin-top: 128px;
    width: 100%;
    text-align: center;
  `,
  TableWrapper: styled.div`
    width: 100%;
    overflow-x: auto;
  `,
};
