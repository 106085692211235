import { useMemo, useEffect } from 'react';
import { IGameMods } from '@avid/common';

import { gamesAPI } from '../api';
import { useUpdateState } from './react';

import { getColumns } from 'screens/players-history/players-history.utils';

interface IState {
  isLoading: boolean;
  gameMods?: IGameMods;
}

const INIT_STATE: IState = {
  isLoading: true,
};

export const useHistoryColumns = (gameCode: string) => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  const columns = useMemo(() => {
    if (!state.gameMods) {
      return [];
    }

    return getColumns(state.gameMods);
  }, [state.gameMods]);

  useEffect(() => {
    const request = async () => {
      updateState({ isLoading: true });

      const game = await gamesAPI.getGame(gameCode);

      updateState({
        isLoading: false,
        gameMods: game?.config.mods,
      });
    };

    request();
  }, [gameCode, updateState]);

  return { ...state, columns };
};
