import {ReactNode } from 'react';
import styled from 'styled-components';

import { Icon, TIconNames } from 'components/icons';
import { Paragraph } from 'components/typography';
import { COLORS } from 'theme';

interface IITem {
  title: string;
  value?: number;
}

interface IITemProps extends IITem {
  color: string;
}

interface IProps {
  title: string;
  color: string;
  icon: TIconNames;
  items: [IITem, IITem];
  iconWidth?: string;
  HeaderComponent?: ReactNode;
}

const ColorParagraph = styled(Paragraph)<{ color: string }>`
  color: ${({ color }) => color};
`;

const ItemWrapperStyles = {
  Wrapper: styled.div`
    width: 220px;
    box-sizing: border-box;
    border-radius: 10px;
    display: grid;
    row-gap: 4px;
    justify-items: center;
    padding: 8px 20px;
    background-color: ${COLORS.blue_gray};
  `,
};

const Styles = {
  Root: styled.div`
    display: grid;
    justify-items: start;
  `,
  Wrapper: styled.div`
    border-radius: 10px;
    background-color: ${COLORS.white};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
  `,
  Header: styled.div`
    position: relative;
  `,
  TitleWrapper: styled.div`
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
  `,
  ItemsWrapper: styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 18px;
  `,
  HeaderComponentWrapper: styled.div`
    position: absolute;
    top: -10px;
    right: 6px;
  `,
};

const ItemWrapper = (props: IITemProps) => {
  const { title, value, color } = props;

  return (
    <ItemWrapperStyles.Wrapper>
      <Paragraph typography="QuicksandBold16" textAlign="center">
        {title}
      </Paragraph>
      <ColorParagraph typography="QuicksandBold32" color={color}>
        {value ?? ' '}
      </ColorParagraph>
    </ItemWrapperStyles.Wrapper>
  );
};

export const InfoRectangle = (props: IProps) => {
  const { title, icon, color, iconWidth, items, HeaderComponent } = props;

  return (
    <Styles.Root>
      <Styles.Wrapper>
        <Styles.Header>
          <Styles.TitleWrapper>
            <Icon type={icon} width={iconWidth} />
            <ColorParagraph
              typography="QuicksandBold18"
              textAlign="center"
              color={color}
            >
              {title}
            </ColorParagraph>
          </Styles.TitleWrapper>

          <Styles.HeaderComponentWrapper>
            {HeaderComponent}
          </Styles.HeaderComponentWrapper>
        </Styles.Header>

        <Styles.ItemsWrapper>
          <ItemWrapper {...items[0]} color={color} />
          <ItemWrapper {...items[1]} color={color} />
        </Styles.ItemsWrapper>
      </Styles.Wrapper>
    </Styles.Root>
  );
};
