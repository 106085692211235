import { HTMLAttributes, useMemo } from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { getCssOptionalValue } from 'services/utils';

interface IGridLayoutProps {
  rowGap?: string;
  columnGap?: string;
  gap?: string;
  justifyContent?: string;
  justifyItems?: string;
  alignItems?: string;
  alignContent?: string;
  columns?: number;
  isFlexible?: boolean;
  border?: string;
  borderRadius?: string;
  backgroundColor?: string;
  boxShadow?: string;
  padding?: string;
  color?: string;
  textAlign?: string;
}

export type TGridProps = HTMLAttributes<HTMLDivElement> & IGridLayoutProps;

const GRID_CONSTANTS = {
  DEFAULT_GAP: '8px',
} as const;

const getGap = (props: IGridLayoutProps) => {
  const { rowGap, columnGap, gap } = props;

  if (gap) {
    return `gap: ${gap};`;
  }

  if (!rowGap && !columnGap) {
    return `gap: ${GRID_CONSTANTS.DEFAULT_GAP};`;
  }

  return '';
};

const getGridLayoutCssString = (
  props: IGridLayoutProps,
  isOverriding: boolean
) => css`
  ${isOverriding
    ? ''
    : `
    align-content: ${props.alignContent || 'start'};
    ${getGap(props)}
  `}
  ${getCssOptionalValue('row-gap', props.rowGap)}
  ${getCssOptionalValue('column-gap', props.columnGap)}
  ${getCssOptionalValue('justify-content', props.justifyContent)}
  ${getCssOptionalValue('justify-items', props.justifyItems)}
  ${getCssOptionalValue('align-items', props.alignItems)}
  ${getCssOptionalValue('border', props.border)}
  ${getCssOptionalValue('border-radius', props.borderRadius)}
  ${getCssOptionalValue('box-shadow', props.boxShadow)}
  ${getCssOptionalValue('background-color', props.backgroundColor)}
  ${getCssOptionalValue('padding', props.padding)}
  ${getCssOptionalValue('color', props.color)}
  ${getCssOptionalValue('text-align', props.textAlign)}
  ${props.columns ? 'grid-auto-flow: column;' : ''}
`;

const StyledGrid = styled.div<{ $cssRules?: FlattenSimpleInterpolation }>`
  display: grid;
  ${({ $cssRules }) => $cssRules}
`;

export const Grid = (props: TGridProps) => {
  const {
    rowGap,
    columnGap,
    gap,
    justifyContent,
    justifyItems,
    alignItems,
    alignContent,
    columns,
    backgroundColor,
    boxShadow,
    borderRadius,
    border,
    padding,
    isFlexible,

    color,
    textAlign,

    ...divProps
  } = props;

  const cssRules = useMemo(() => {
    const cssProps: TGridProps = {
      rowGap,
      columnGap,
      gap,
      justifyContent,
      justifyItems,
      alignItems,
      alignContent,
      columns,
      isFlexible,
      backgroundColor,
      boxShadow,
      borderRadius,
      border,
      padding,
      color,
      textAlign,
    };

    return css`
      ${getGridLayoutCssString(cssProps, false)};
    `;
  }, [
    alignContent,
    alignItems,
    backgroundColor,
    border,
    borderRadius,
    boxShadow,
    color,
    columnGap,
    columns,
    gap,
    isFlexible,
    justifyContent,
    justifyItems,
    padding,
    rowGap,
    textAlign,
  ]);

  return <StyledGrid $cssRules={cssRules} {...divProps} />;
};
