

import { InfoBlockStyles } from './info-block.styles';

interface IInfoBlockProps {
  title: string;
  value?: number;
}

export const InfoBlock = (props: IInfoBlockProps) => {
  const { title, value } = props;

  return (
    <InfoBlockStyles.Wrapper>
      <InfoBlockStyles.Title>{title}</InfoBlockStyles.Title>
      <InfoBlockStyles.Value>{value}</InfoBlockStyles.Value>
    </InfoBlockStyles.Wrapper>
  );
};
