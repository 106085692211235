import styled, { css } from 'styled-components';

import { Checkbox } from 'components/checkbox';
import { Span } from 'components/typography';
import { ISectorsSettingsValue } from 'typings/sectors-settings';
import { COLORS, typographyCss } from 'theme';

import { SectorsSettingsCounterControllers } from './sectors-settings-counter-controllers';

interface IProps extends ISectorsSettingsValue {
  isSelected: boolean;
  onChangeSalary: (salary: number) => void;
  onSelect: () => void;
}

const Styles = {
  Root: styled.div`
    display: grid;
    justify-content: start;
    align-items: center;
    grid-template-columns: auto 1fr;
    column-gap: 12px;
  `,

  Control: styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, auto);
    column-gap: 16px;
  `,
};

const Label = styled(Span)<{ isChanged: boolean }>`
  ${({ isChanged }) =>
    isChanged
      ? css`
          ${typographyCss.QuicksandBold18};
          color: ${COLORS.redSeconday};
        `
      : css`
          ${typographyCss.QuicksandRegular18};
          color: ${COLORS.black};
        `};
`;

const CHANGE_VALUE = 10;

export const SectorsSettingsCounter = (props: IProps) => {
  const { value, isChanged, isSelected, onSelect, onChangeSalary } = props;

  const increase = () => onChangeSalary(value + CHANGE_VALUE);

  const decrease = () => onChangeSalary(value - CHANGE_VALUE);

  return (
    <Styles.Root>
      <Checkbox isChecked={isSelected} onClick={onSelect} />

      <Styles.Control>
        <SectorsSettingsCounterControllers.Minus onClick={decrease} />
        <Label isChanged={isChanged}>{value}</Label>
        <SectorsSettingsCounterControllers.Plus onClick={increase} />
      </Styles.Control>
    </Styles.Root>
  );
};
